import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import NavigationBar from "../components/NavigationBar";
import ScreeningsTable from "../components/screenings/screeningsTable";
import AvatarDropdown from "../components/AvatarDropdown";
import {
  getQuarterlyScreenings,
  getYearlyScreenings,
  commitScreenings,
  getInfrequentScreenings,
} from "../components/axios/Screenings";
import { getAllConsortiumEmployees } from "../components/axios/Employees";
import { fetchCompaniesForScreenings } from "../components/axios/Companies";
import {
  mapCompanyScreenings,
  mapEmployeeScreenings,
  mapScreenings,
  aggregateScreeningsSummaryReportData,
  aggregateYearlyScreeningsData,
} from "../functions/HelperFunctions";
import AddScreeningModal from "../components/modals/AddScreeningModal";
import DeleteScreeningModal from "../components/modals/DeleteScreeningModal";
import {
  getScreeningsReport,
  getScreeningsSummaryReport,
} from "../components/axios/Reports";
import { isAdmin } from "../components/axios/UserSession";
import EmailCompanyScreeningsModal from "../components/modals/EmailCompanyScreeningsModal";
import AddInfrequentScreeningModal from "../components/modals/AddInfrequentScreeningModal";
import DeleteInfrequentScreeningModal from "../components/modals/DeleteInfrequentScreeningModal";

// Material Imports
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import timezone from "dayjs/plugin/timezone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { red } from "@mui/material/colors";
import { Alert, Snackbar } from "@mui/material";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);

const TableMenuButton = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  color: "white",
  margin: "10px",
  textTransform: "none",
  width: "110px",
  ":hover": {
    opacity: ".8",
    backgroundColor: "#21357B",
  },
});

const ClearMenuButton = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#cc0000",
  color: "white",
  margin: "10px",
  textTransform: "none",
  width: "110px",
  ":hover": {
    opacity: ".8",
    backgroundColor: "#cc0000",
  },
});

const textFieldStyle = {
  fontFamily: "Poppins",
  marginTop: ".4rem",
  marginBottom: ".4rem",
  width: "220px",
};

const selected = {
  color: "#FFFFFF",
  backgroundColor: "#21357B",
  borderColor: "#FFFFFF",
};

const unselected = {
  color: "#21357B",
  backgroundColor: "#FFFFFF",
  borderColor: "#21357B",
};

const currentYear = dayjs().tz("US/Central").format("YYYY");
const currentQuarter = dayjs().tz("US/Central").quarter();
let screeningReportData = [];

const Screenings = () => {
  const selectionColumns = [
    {
      field: "screeningDate",
      headerName: "Date Selected",
      minWidth: 120,
      sortable: true,
    },
    {
      field: "consortium",
      headerName: "Consortium",
      minWidth: 120,
      sortable: true,
    },
    {
      field: "screeningType",
      headerName: "Selection Type",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "screeningYear",
      headerName: "Year",
      minWidth: 120,
      sortable: true,
    },
    {
      field: "screeningQuarter",
      headerName: "Quarter",
      minWidth: 120,
      sortable: true,
    },
    {
      field: "totalMembers",
      headerName: "Members",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "requiredMembers",
      headerName: "Required",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "selectedMembers",
      headerName: "Selected",
      minWidth: 150,
      sortable: true,
    },
  ];

  const employeeColumns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 120,
      sortable: true,
      renderCell: (params) => {
        return <div>{params.value.slice(-6).toUpperCase()}</div>;
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "companyName",
      headerName: "Company",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "screen",
      headerName: "Screen",
      minWidth: 120,
      sortable: true,
    },
    {
      field: "result",
      headerName: "Status",
      minWidth: 120,
      sortable: true,
    },
    {
      field: "year",
      headerName: "Year",
      minWidth: 120,
      sortable: true,
    },
    {
      field: "quarterSelected",
      headerName: "Quarter",
      minWidth: 120,
      sortable: true,
    },
    {
      field: "consortium",
      headerName: "Consortium",
      minWidth: 120,
      sortable: true,
    },
  ];

  const companyColumns = [
    {
      field: "companyName",
      headerName: "Company Name",
      minWidth: 300,
      sortable: true,
    },
    {
      field: "totalEmployees",
      headerName: "Num Employees",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "totalDrivers",
      headerName: "Num Drivers",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "totalSelected",
      headerName: "Num Selected",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 60,
      renderCell: () => {
        return (
          <Tooltip title="Email">
            <IconButton
              disabled={!isAdmin}
              onClick={() => {
                setSelectedReport("Full");
                setEmailCompanyScreeningsModal(true);
              }}
            >
              <MailOutlineIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const infrequentColumns = [
    {
      field: "screeningType",
      headerName: "Type",
      minWidth: 175,
      sortable: true,
    },
    {
      field: "screeningReason",
      headerName: "Reason",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 175,
      sortable: true,
      renderCell: (params) => {
        return <div>{params.row.selectedEmployee.status || "pending"}</div>;
      },
    },
    {
      field: "screeningDate",
      headerName: "Date",
      minWidth: 175,
      sortable: true,
      renderCell: (params) => {
        return (
          <div>{dayjs(params.value).tz("US/Central").format("MM/DD/YYYY")}</div>
        );
      },
    },
    {
      field: "selectedEmployee",
      headerName: "Employee",
      minWidth: 200,
      sortable: true,
      renderCell: (params) => {
        return (
          <div>
            {params.value.lastName}, {params.value.firstName}
          </div>
        );
      },
    },
    {
      field: "companyName",
      headerName: "Company",
      minWidth: 300,
      sortable: true,
      renderCell: (params) => {
        return <div>{params.row.selectedEmployee.companyName}</div>;
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 60,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              disabled={!isAdmin}
              sx={{ color: red[500] }}
              onClick={() => {
                setDeleteInfrequentScreeningsModal(true);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const ColumnShown = {
    Selections: selectionColumns,
    Companies: companyColumns,
    Employees: employeeColumns,
    Infrequent: infrequentColumns,
  };
  const [data, setData] = useState([]);
  const [selectedConsortium, setSelectedConsortium] = useState("DOT");
  const [selectedColumns, setSelectedColumns] = useState(
    ColumnShown["Selections"]
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectionMade, setSelectionMade] = useState("Selections");
  const [selectedQuarter, setSelectedQuarter] = useState(currentQuarter);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedReport, setSelectedReport] = useState("Summary");
  const [selectedTab, setSelectedTab] = useState("Current");
  const [showNewSelectionModal, setShowNewSelectionModal] = useState(false);
  const [emailCompanyScreeningsModal, setEmailCompanyScreeningsModal] =
    useState(false);
  const [addInfrequentScreeningsModal, setAddInfrequentScreeningsModal] =
    useState(false);
  const [deleteInfrequentScreeningsModal, setDeleteInfrequentScreeningsModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [allEmployees, setAllEmployees] = useState(0);
  const [allDrivers, setAllDrivers] = useState(0);
  const isFirstRender = useRef(true);
  const [rowClicked, setRowClicked] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [asyncRefresh, setAsyncRefresh] = useState(null);
  const [showRefreshAlert, setShowRefreshAlert] = useState(false);

  const handleConsortiumChange = (event) => {
    setSelectedConsortium(event.target.value);
  };
  const handleColumnsChange = (event) => {
    setSelectionMade(event.target.value);
    setSelectedColumns(ColumnShown[event.target.value]);
  };
  const handleQuarterChange = (event) => {
    const newQuarter = event.target.value;
    setSelectedQuarter(newQuarter);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const handleReportChange = (event) => {
    setSelectedReport(event.target.value);
  };
  const handleAlert = (message, severity = "success") => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
    setShowRefreshAlert(true); // Set this to true when we want to show the refresh alert
  };

  const fetchScreenings = async () => {
    return selectedQuarter === ""
      ? await getYearlyScreenings({
          year: selectedYear,
          consortium: selectedConsortium,
        })
      : await getQuarterlyScreenings({
          year: selectedYear,
          quarter: selectedQuarter,
          consortium: selectedConsortium,
        });
  };

  const selectionsHandler = async (screenings) => {
    setSelectedRows(mapScreenings(screenings));
    const employees = await getAllConsortiumEmployees(selectedConsortium);
    const companies = await fetchCompaniesForScreenings();
    const data = await mapCompanyScreenings({
      employees,
      companies,
      screenings,
    });
    screeningReportData = data.finalArray;
    setAllDrivers(data.allDrivers);
    setAllEmployees(data.allEmployees);
  };

  const employeesHandler = async (screenings) => {
    setSelectedRows(mapEmployeeScreenings(screenings));
  };

  const companiesHandler = async (screenings) => {
    const employees = await getAllConsortiumEmployees(selectedConsortium);
    const companies = await fetchCompaniesForScreenings();
    const data = await mapCompanyScreenings({
      employees,
      companies,
      screenings,
    });
    setSelectedRows(data.finalArray);
  };

  const infrequentHandler = async () => {
    const allInfrequentScreenings = await getInfrequentScreenings();
    const screeningsWithId = allInfrequentScreenings.map((screening) => ({
      ...screening,
      id: screening._id, // Add unique id property
    }));
    setSelectedRows(screeningsWithId);
  };

  const handleClose = () => {
    setShowNewSelectionModal(false);
    setShowDeleteModal(false);
    setEmailCompanyScreeningsModal(false);
    setAddInfrequentScreeningsModal(false);
    setDeleteInfrequentScreeningsModal(false);
  };

  const selectionStrategies = {
    Selections: selectionsHandler,
    Employees: employeesHandler,
    Companies: companiesHandler,
    Infrequent: infrequentHandler,
  };

  const handleRowClick = (params) => {
    setRowClicked(Object.assign(rowClicked, params.row));
  };

  const fetchAndSaveScreenings = async () => {
    setIsLoading(true);
    // check the selected tab on the screenings page
    try {
      if (selectionMade === "Infrequent") {
        await infrequentHandler();
      } else {
        // IF the tab selected is "Current"
        // Keep track of current quarter and year, a list of the screenings, and if we have commited these screenings
        if (selectedTab === "Current") {
          let currentQuarter = selectedQuarter;
          let currentYear = selectedYear;
          let allScreenings = [];
          let allCommitted = true;

          // Loop until we find a quarter with no selection or an uncommitted selection by pinging the API and checking
          while (true) {
            ({ allScreenings } = await getQuarterlyScreenings({
              year: currentYear,
              quarter: currentQuarter,
              consortium: selectedConsortium,
            }));
            setData(allScreenings);

            // Check if all screenings in the current quarter are committed
            allCommitted =
              allScreenings.length > 0 &&
              allScreenings.every((s) => s.isCommitted);

            if (!allCommitted || allScreenings.length === 0) {
              // Break out of the loop if an uncommitted selection or no selection is found
              break;
            }

            // Move to the next quarter/year if the current quarter is fully committed
            const { nextQuarter, nextYear } = getNextQuarterYear(
              currentQuarter,
              currentYear
            );
            currentQuarter = nextQuarter;
            currentYear = nextYear;
          }

          // Update state to the found quarter/year
          setSelectedQuarter(currentQuarter);
          setSelectedYear(currentYear);

          // Determine if it's a new quarter with no screenings
          const isNewQuarter = allScreenings.length === 0;
          if (isNewQuarter && !isFirstRender.current) {
            setShowNewSelectionModal(true);
          } else {
            // Handle the screenings based on the current selection type
            const handler = selectionStrategies[selectionMade];
            if (handler) {
              await handler(allScreenings);
            } else {
              console.warn(
                "No handler defined for selectionMade:",
                selectionMade
              );
            }
          }
        } else {
          // IF the tab selected is "History"
          // Fetch the screenings based on selected quarter and year
          const { allScreenings } = await fetchScreenings();
          setData(allScreenings);
          const handler = selectionStrategies[selectionMade];
          if (handler) {
            await handler(allScreenings);
          } else {
            console.warn(
              "No handler defined for selectionMade:",
              selectionMade
            );
          }
        }
      }
    } catch (error) {
      console.error("Couldn't fetch and save screenings!", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to calculate the next quarter and year
  const getNextQuarterYear = (quarter, year) => {
    if (quarter === 4) {
      return { nextQuarter: 1, nextYear: Number(year) + 1 };
    } else {
      return { nextQuarter: Number(quarter) + 1, nextYear: year };
    }
  };

  // Function to find the next uncommitted quarter/year
  const findUncommittedQuarterYear = async (quarter, year, consortium) => {
    let nextQuarter = quarter;
    let nextYear = year;

    while (true) {
      // Move to the next quarter/year
      const { nextQuarter: updatedQuarter, nextYear: updatedYear } =
        getNextQuarterYear(nextQuarter, nextYear);

      const { allScreenings } = await getQuarterlyScreenings({
        year: updatedYear,
        quarter: updatedQuarter,
        consortium,
      });

      // Return this quarter/year if uncommitted
      if (!allScreenings.length || allScreenings.every((s) => !s.isCommitted)) {
        return { nextQuarter: updatedQuarter, nextYear: updatedYear };
      }

      // Continue to next quarter/year if already committed
      nextQuarter = updatedQuarter;
      nextYear = updatedYear;
    }
  };

  // Updated handleCommitClick function
  const handleCommitClick = async () => {
    setIsLoading(true);
    try {
      await commitScreenings({
        year: selectedYear,
        quarter: selectedQuarter,
        consortium: selectedConsortium,
      });

      // Clear rows in the table
      setSelectedRows([]);

      // Find the next uncommitted selection
      const { nextQuarter, nextYear } = await findUncommittedQuarterYear(
        selectedQuarter,
        selectedYear,
        selectedConsortium
      );

      // Update to the next uncommitted quarter/year
      setSelectedQuarter(nextQuarter);
      setSelectedYear(nextYear);

      // Fetch the screenings for the new quarter/year
      fetchAndSaveScreenings();
      handleAlert("Screenings committed successfully!");
    } catch (error) {
      console.error("Error committing screenings:", error.message);
      handleAlert("Error committing screenings. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchClick = () => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
    fetchAndSaveScreenings();
  };

  const handleClearClick = () => {
    setShowNewSelectionModal(false);
    setShowDeleteModal(true);
  };

  const handleDownloadReport = async (e) => {
    try {
      if (selectedReport === "Summary") {
        const unstructuredYearlyData = await getYearlyScreenings({
          year: selectedYear,
          consortium: selectedConsortium,
        });
        const structuredYearlyData = aggregateYearlyScreeningsData(
          unstructuredYearlyData?.allScreenings
        );
        const structuredQuarterlyData =
          aggregateScreeningsSummaryReportData(data);
        await getScreeningsSummaryReport({
          structuredQuarterlyData,
          structuredYearlyData,
        });
      } else if (selectedReport === "Full") {
        const filteredData = screeningReportData.filter(
          (entry) => entry.totalSelected > 0
        );
        if (e === "email") {
          const emailData = filteredData.filter(
            (entry) => entry.companyName === rowClicked.companyName
          );
          await getScreeningsReport(emailData);
        } else {
          await getScreeningsReport(filteredData);
        }
      }
      handleAlert("Report generated successfully!");
    } catch (error) {
      console.error("Error generating report:", error);
      handleAlert("Error generating report. Please try again.", "error");
    }
  };

  const refreshData = useCallback(async () => {
    setIsLoading(true);
    try {
      await fetchAndSaveScreenings();
      if (showRefreshAlert) {
        handleAlert("Data refreshed successfully!");
        setShowRefreshAlert(false);
      }
    } catch (error) {
      console.error("Error refreshing data:", error);
      handleAlert("Error refreshing data. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedTab,
    selectedQuarter,
    selectedYear,
    selectedConsortium,
    selectionMade,
    showRefreshAlert,
  ]);

  useEffect(() => {
    fetchAndSaveScreenings();
  }, [selectionMade]);

  useEffect(() => {
    if (selectedTab === "Infrequent") {
      setSelectionMade("Infrequent");
      setSelectedColumns(ColumnShown["Infrequent"]);
    } else {
      setSelectionMade("Selections");
      setSelectedColumns(ColumnShown["Selections"]);
    }
  }, [selectedTab]);

  const tabClicked = (tab) => {
    setSelectedTab(tab);
    if (selectedRows.length) {
      setSelectedRows([]);
    }
    if (tab === "Current") {
      setSelectedQuarter(currentQuarter);
      setSelectedYear(currentYear);
    } else {
      setSelectedQuarter("");
      setSelectedYear("");
    }
  };

  useEffect(() => {
    setAsyncRefresh(() => refreshData);
  }, [refreshData]);

  return (
    <div className="home">
      <div className="headerContainer">
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={() => {
            setAlertOpen(false);
            if (showRefreshAlert && typeof asyncRefresh === "function") {
              asyncRefresh();
            }
          }}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <Alert
            onClose={() => setAlertOpen(false)}
            severity={alertSeverity}
            sx={{
              width: "300px",
              height: "50px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <div id="linscot">
          <h2>Linscot Pulse</h2>
        </div>
        <div id="userInfo">
          <AvatarDropdown />
        </div>
      </div>
      <div className="menuContainer">
        <div id="navBar">
          <NavigationBar />
        </div>
      </div>
      <div className="tableContainer">
        <div id="operationsTable">
          <div id="tableMenuColumn">
            <div className="tableMenuStart">Screening Criteria</div>
            <div className="tableMenuRow">
              Consortium
              <Select
                size="small"
                fullWidth
                name="consortium"
                sx={textFieldStyle}
                value={selectedConsortium}
                onChange={handleConsortiumChange}
                disabled={selectedTab === "Infrequent" ? true : false}
              >
                <MenuItem value={"DOT"}>DOT</MenuItem>
                <MenuItem value={"NONDOT"}>NON-DOT</MenuItem>
              </Select>
            </div>
            <div className="tableMenuRow">
              Year
              <TextField
                disabled={
                  selectedTab === "Current" || selectedTab === "Infrequent"
                    ? true
                    : false
                }
                fullWidth
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                value={selectedYear}
                onChange={handleYearChange}
                name="lastName"
              />
            </div>
            <div className="tableMenuRow">
              Quarter
              <Select
                disabled={
                  selectedTab === "Current" || selectedTab === "Infrequent"
                    ? true
                    : false
                }
                size="small"
                fullWidth
                name="quarter"
                sx={textFieldStyle}
                value={selectedQuarter}
                onChange={handleQuarterChange}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={""}>Full Year</MenuItem>
              </Select>
            </div>
            <div className="tableMenuRow">
              Display
              <Select
                disabled={selectedTab === "Infrequent" ? true : false}
                size="small"
                fullWidth
                name="display"
                sx={textFieldStyle}
                value={selectionMade}
                onChange={handleColumnsChange}
              >
                <MenuItem value={"Selections"}>Selections</MenuItem>
                <MenuItem value={"Companies"}>Companies</MenuItem>
                <MenuItem value={"Employees"}>Employees</MenuItem>
                <MenuItem disabled value={"Infrequent"}>
                  Infrequent
                </MenuItem>
              </Select>
            </div>
            <div className="tableMenuButton">
              {selectedTab === "Current" && selectedRows.length ? (
                <ClearMenuButton onClick={handleClearClick}>
                  Clear
                </ClearMenuButton>
              ) : (
                <TableMenuButton
                  disabled={selectedTab === "Infrequent" ? true : false}
                  sx={selectedTab === "Infrequent" ? unselected : null}
                  onClick={handleSearchClick}
                >
                  Search
                </TableMenuButton>
              )}
            </div>
            {selectedTab === "Current" && selectedRows.length ? (
              <div className="tableMenuButton">
                <TableMenuButton onClick={handleCommitClick}>
                  Commit
                </TableMenuButton>
              </div>
            ) : (
              <Fragment></Fragment>
            )}
            <div className="tableMenuRow">
              Report Type
              <Select
                disabled={selectedTab === "Infrequent" ? true : false}
                size="small"
                fullWidth
                name="reportType"
                sx={textFieldStyle}
                value={selectedReport}
                onChange={handleReportChange}
              >
                <MenuItem value={"Summary"}>Summary</MenuItem>
                <MenuItem value={"Full"}>Full</MenuItem>
              </Select>
            </div>
            <div className="tableMenuButton">
              <TableMenuButton
                disabled={selectedQuarter === ""}
                sx={selectedQuarter === "" ? unselected : selected}
                onClick={handleDownloadReport}
              >
                Show Report
              </TableMenuButton>
            </div>
          </div>
          <ScreeningsTable
            columns={selectedColumns}
            rows={selectedRows}
            tabClicked={tabClicked}
            tab={selectedTab}
            allDrivers={allDrivers}
            allEmployees={allEmployees}
            handleRowClick={handleRowClick}
            setAddInfrequentScreeningsModal={setAddInfrequentScreeningsModal}
            isLoading={isLoading}
            handleAlert={handleAlert}
            asyncRefresh={asyncRefresh}
          />
        </div>
      </div>
      <AddScreeningModal
        isOpen={showNewSelectionModal}
        handleClose={handleClose}
        consortium={selectedConsortium}
        year={selectedYear}
        quarter={selectedQuarter}
        handleAlert={handleAlert}
      />
      <DeleteScreeningModal
        isOpen={showDeleteModal}
        handleClose={handleClose}
        data={data}
        handleAlert={handleAlert}
      />
      <EmailCompanyScreeningsModal
        isOpen={emailCompanyScreeningsModal}
        handleClose={handleClose}
        handleDownloadReport={handleDownloadReport}
        rowClicked={rowClicked}
      />
      <AddInfrequentScreeningModal
        isOpen={addInfrequentScreeningsModal}
        handleClose={handleClose}
        handleAlert={handleAlert}
      />
      <DeleteInfrequentScreeningModal
        isOpen={deleteInfrequentScreeningsModal}
        handleClose={handleClose}
        rowClicked={rowClicked}
        handleAlert={handleAlert}
      />
    </div>
  );
};

export default Screenings;
