import React, { useRef } from "react";
import NavigationBar from "../components/NavigationBar";
import TrainingSearchBar from "../components/TrainingSearchBar";
import TrainingsTable from "../components/trainings/trainingsTable";
import AvatarDropdown from "../components/AvatarDropdown";
import { emptyUser, emptyTraining } from "../components/BoilerplateObjects";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

// Modal Imports
import AddTrainingModal from "../components/modals/AddTrainingModal";
import DeleteTrainingModal from "../components/modals/DeleteTrainingModal";
import DeleteUserModal from "../components/modals/DeleteUserModal";

const Training = () => {
  const navigate = useNavigate();

  // State for AddTrainingModal
  const [isAddTrainingOpen, setIsAddTrainingOpen] = React.useState(false);
  const handleOpen = () => setIsAddTrainingOpen(true);
  const handleClose = () => setIsAddTrainingOpen(false);

  // State for DeleteTrainingModal
  const [isDeleteTrainingOpen, setIsDeleteTrainingOpen] = React.useState(false);
  // State for Open/Closing DeleteUserModal
  const [isDeleteUserOpen, setIsDeleteUserOpen] = React.useState(false);

  // State for Training Search Bar (TrainingSearchBar.js)
  const [allTrainings, setAllTrainings] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null);

  // State to manage alert visibility
  const [alertOpen, setAlertOpen] = React.useState(false);

  const handleAlertSuccess = () => {
    setAlertOpen(true);
  };

  const handleDeleteButtonOpen = (e, params) => {
    if (e === "Training") {
      setIsDeleteTrainingOpen(true);
      trainingRef.current = params.row;
    } else if (e === "Instructor") {
      setIsDeleteUserOpen(true);
      userRef.current = params.row;
    }
  };

  const handleDeleteButtonClose = (e) => {
    setIsDeleteTrainingOpen(false);
    setIsDeleteUserOpen(false);
    userRef.current = emptyUser;
    trainingRef.current = emptyTraining;
  };

  const trainingRef = useRef(emptyTraining);
  const userRef = useRef(emptyUser);

  return (
    <div className="home">
      <div className="headerContainer">
        <div id="linscot">
          <h2
            onClick={() => {
              navigate("/home");
            }}
          >
            Linscot Pulse
          </h2>
        </div>
        <div id="userInfo">
          <AvatarDropdown handleOpen={handleOpen} handleClose={handleClose} />
        </div>
      </div>
      <div className="menuContainer">
        <div id="navBar">
          <NavigationBar />
        </div>
        <div id="search">
          <TrainingSearchBar
            allTrainings={allTrainings}
            setSelectedValue={setSelectedValue}
          />
        </div>
      </div>
      <div className="tableContainer">
        <div id="table">
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={() => setAlertOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "left" }} // Position at top left
          >
            <Alert
              onClose={() => setAlertOpen(false)}
              severity="success"
              sx={{
                width: "300px",
                height: "50px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Success - Please refresh for updated data!
            </Alert>
          </Snackbar>
          <TrainingsTable
            handleAddButtonOpen={handleOpen}
            handleDeleteButtonOpen={handleDeleteButtonOpen}
            selectedValue={selectedValue}
            setAllTrainings={setAllTrainings}
            handleAlertSuccess={handleAlertSuccess}
          />
        </div>
      </div>
      <AddTrainingModal
        isAddTrainingOpen={isAddTrainingOpen}
        handleAddButtonClose={handleClose}
        showCompanySelector={true}
        handleAlertSuccess={handleAlertSuccess}
      />
      <DeleteTrainingModal
        isOpen={isDeleteTrainingOpen}
        handleClose={handleDeleteButtonClose}
        trainingRef={trainingRef}
      />
      <DeleteUserModal
        isOpen={isDeleteUserOpen}
        handleClose={handleDeleteButtonClose}
        userRef={userRef}
      />
    </div>
  );
};

export default Training;
