import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const mapAllTrainings = (trainings) => {
  let trainingRows = [];
  if (trainings && trainings.length >= 1) {
    for (let i = 0; i < trainings.length; i++) {
      let currentTraining = trainings[i];
      trainingRows.push({
        title: currentTraining.courseTitle,
        companyName: currentTraining.companyName,
        attendees: currentTraining.attendees,
        date: dayjs
          .tz(currentTraining.trainingDate, "US/Central")
          .format("MM/DD/YYYY"),
        instructor: currentTraining.instructor,
        isHidden: currentTraining.isHidden,
        nextDate: `${dayjs
          .tz(currentTraining.trainingDate, "US/Central")
          .add(1, "year")
          .diff(dayjs(), "day")} Days`,
        id: currentTraining._id,
        trainingId: currentTraining._id,
        courses: currentTraining.courses,
      });
    }
  }
  return trainingRows;
};

const booleanToYesNo = (boolean) => {
  if (boolean === true) {
    return "Yes";
  } else {
    return "No";
  }
};

export const mapAllInstructors = (instructors) => {
  let instructorRows = [];
  if (instructors && instructors.length >= 1) {
    for (let i = 0; i < instructors.length; i++) {
      let currentTraining = instructors[i];

      instructorRows.push({
        firstName: currentTraining.firstName,
        lastName: currentTraining.lastName,
        admin: booleanToYesNo(currentTraining.isAdmin),
        instructor: booleanToYesNo(currentTraining.isInstructor),
        id: currentTraining._id,
      });
    }
  }
  return instructorRows;
};
