import React, { useEffect, useRef, useState } from "react";
import "./AddTrainingModal.css";

// Axios Imports
import { getCourses, createCourse } from "../axios/Courses";
import { getInstructors } from "../axios/Users";
import { getCompanyEmployees } from "../axios/Employees";
import { createTraining } from "../axios/Trainings";
import { fetchCompanies } from "../axios/Companies";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
  overflow: "scroll",
};

const textFieldStyle = {
  marginTop: ".4rem",
  marginBottom: ".4rem",
  width: "100%",
};

const NewButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#2C9637",
  textTransform: "none",
  minWidth: "8rem",
});

const SaveOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

let allInstructors = [];

const training = {
  attendees: [],
  companyId: "",
  companyName: "",
  courses: [],
  courseTitle: "",
  instructor: "",
  trainingDate: "",
};

const course = {
  courseName: "",
  courseDescription: "",
};

export function ChildModal({ setAllCourses }) {
  const [open, setOpen] = useState(false);
  const newCourse = useRef(course);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCourseChange = (e) => {
    if (e.target.name === "courseName") {
      newCourse.current.courseName = e.target.value;
    } else if (e.target.name === "courseDescription") {
      newCourse.current.courseDescription = e.target.value;
    }
  };

  const handleSave = async () => {
    try {
      const newCourses = await createCourse(newCourse.current);
      setAllCourses(newCourses);
    } catch (error) {
      console.error("error saving and fetching courses: ", error);
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <NewButton
        onClick={handleOpen}
        startIcon={<AddBoxIcon />}
        name="addCourse"
        className="addCourseButton"
      >
        Add Course
      </NewButton>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 1550 }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 600, height: 300 }}>
          <h3>Add a New Course</h3>
          <Divider></Divider>
          <div className="courseInputContainer">
            <div className="formRow">
              <div className="formCell">Course Name</div>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                onChange={handleCourseChange}
                name="courseName"
              />
            </div>

            <div className="formRow">
              <div className="formCell">Course Description</div>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                onChange={handleCourseChange}
                name="courseDescription"
              />
            </div>
          </div>
          <br />
          <br />

          <div id="saveOrCancel">
            <SaveOrCancel
              onClick={handleClose}
              name="cancelModal"
              variant="contained"
            >
              Cancel
            </SaveOrCancel>
            <SaveOrCancel
              onClick={handleSave}
              name="saveModal"
              variant="contained"
            >
              Save Course
            </SaveOrCancel>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function AddTrainingModal({
  isAddTrainingOpen,
  handleAddButtonClose,
  companyId,
  companyName,
  showCompanySelector,
  handleAlertSuccess,
}) {
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const newTraining = useRef(training);

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  // New state to track if the modal has been opened
  const [hasOpened, setHasOpened] = useState(false);

  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");

  // Reset state when modal closes
  useEffect(() => {
    if (!isAddTrainingOpen) {
      setSelectedCourses([]);
      setChecked([]);
      setLeft([]);
      setRight([]);
      setSelectedCompanyId("");
      newTraining.current = { ...training };
      setHasOpened(false);
    }
  }, [isAddTrainingOpen]);

  // Fetch employees when modal opens or company changes
  useEffect(() => {
    if (isAddTrainingOpen && !hasOpened) {
      fetchEmployeesAndSave();
      setHasOpened(true);
    }
  }, [isAddTrainingOpen, companyId, selectedCompanyId, hasOpened]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 300,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value, index) => {
          const labelId = `transfer-list-all-item-${
            value.employeeId || index
          }-label`;

          return (
            <ListItem
              key={value.employeeId || index}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.lastName}, ${value.firstName}`}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const handleInstructorChange = (e) => {
    newTraining.current.instructor = e.target.value;
  };

  const handleCourseChange = (e) => {
    setSelectedCourses(e.target.value);
  };

  const handleDateChange = (e) => {
    newTraining.current.trainingDate = dayjs(e.$d)
      .hour(12) // Set to noon
      .minute(0)
      .second(0)
      .millisecond(0)
      .tz("US/Central");
  };

  const handleNameChange = (e) => {
    newTraining.current.courseTitle = e.target.value;
  };

  const handleCancelOrSave = async (e) => {
    if (e.target.name === "cancelModal") {
      handleAddButtonClose();
    } else {
      buildAndSetAttendees();
      setCompanyDetails();
      try {
        createTraining(newTraining.current);
      } catch (error) {
        console.error("Error creating new training: ", error);
      } finally {
        handleAddButtonClose();
        handleAlertSuccess();
      }
    }
    setSelectedCourses([]);
    newTraining.current.instructor = "";
    newTraining.current.trainingDate = "";
    newTraining.current.courseTitle = "";
  };

  const buildAndSetAttendees = () => {
    let attendees = [];
    for (let i = 0; i < right.length; i++) {
      let curAttendee = right[i];
      let attendeeObj = {
        firstName: curAttendee.firstName,
        lastName: curAttendee.lastName,
        driverId: curAttendee._id,
      };
      attendees.push(attendeeObj);
    }
    newTraining.current.attendees = attendees;
  };

  const setCompanyDetails = () => {
    if (showCompanySelector === true) {
      newTraining.current.courses = selectedCourses;
    } else {
      newTraining.current.companyId = companyId;
      newTraining.current.companyName = companyName;
      newTraining.current.courses = selectedCourses;
    }
  };

  const fetchCoursesAndSave = async () => {
    try {
      const courses = await getCourses();
      setAllCourses(courses);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInstructorsAndSave = async () => {
    try {
      const instructors = await getInstructors();
      allInstructors = instructors;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEmployeesAndSave = async () => {
    const id = showCompanySelector ? selectedCompanyId : companyId;
    if (id) {
      try {
        const employees = await getCompanyEmployees(id);
        const activeEmployees = employees.filter(
          (employee) =>
            employee.isHidden !== true && employee.isHidden !== "true"
        );
        const sortedEmployees = activeEmployees.sort((a, b) =>
          a.lastName.localeCompare(b.lastName)
        );
        setLeft(sortedEmployees);
      } catch (error) {
        console.error("Error fetching employees: ", error);
      }
    }
  };

  const handleChange = (e) => {
    const companyId = e.target.value;
    setSelectedCompanyId(companyId);
    newTraining.current.companyId = companyId;
    const selectedCompany = allCompanies.find(
      (company) => company._id === companyId
    );
    newTraining.current.companyName = selectedCompany?.companyName || "";
    fetchEmployeesAndSave();
  };

  const fetchCompaniesAndSave = async () => {
    try {
      const companies = await fetchCompanies();
      setAllCompanies(companies);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCoursesAndSave();
    fetchInstructorsAndSave();

    if (isAddTrainingOpen) {
      fetchEmployeesAndSave();
    }

    if (showCompanySelector) {
      fetchCompaniesAndSave();
    }
  }, [isAddTrainingOpen, showCompanySelector]);

  useEffect(() => {
    if (showCompanySelector === true && selectedCompanyId !== "") {
      fetchEmployeesAndSave(selectedCompanyId);
    }
  }, [selectedCompanyId]);

  return (
    <Modal
      open={isAddTrainingOpen}
      onClose={handleAddButtonClose}
      key={`${companyId}-${selectedCompanyId}`}
    >
      <Box sx={style}>
        <h3>
          Add a New Training
          {showCompanySelector ? "" : ` for ${companyName}`}
        </h3>
        <Divider></Divider>

        <div className="inputContainer">
          {showCompanySelector && (
            <div className="formRow">
              <div className="formCell">Company Name</div>
              <Select
                size="small"
                fullWidth
                name="companyName"
                sx={textFieldStyle}
                defaultValue={""}
                onChange={handleChange}
              >
                {allCompanies.map(({ companyName, _id }) => (
                  <MenuItem key={_id} value={_id} name={companyName}>
                    {companyName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}

          <div className="formRow">
            <div className="formCell">Training Name</div>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              sx={textFieldStyle}
              onChange={handleNameChange}
              name="trainingName"
            />
          </div>

          <div className="formRow">
            <div className="trainingCell">Training Courses</div>
            <Autocomplete
              multiple
              size="small"
              options={allCourses}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                return option && option.courseName ? option.courseName : "";
              }}
              isOptionEqualToValue={(option, value) =>
                option.courseName === value
              }
              renderOption={(props, option) =>
                !option.isHidden ? (
                  <li {...props} key={`${option.courseName}-${option._id}`}>
                    {option.courseName}
                  </li>
                ) : null
              }
              value={selectedCourses}
              onChange={(event, newValue) => {
                // Store only the courseName in the selectedCourses state
                const courseNames = newValue.map((course) =>
                  typeof course === "string" ? course : course.courseName
                );
                setSelectedCourses(courseNames);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select courses"
                  placeholder="Courses"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={`${option}-${index}`}
                    variant="outlined"
                    label={option} // Just display the course name
                    {...getTagProps({ index })}
                  />
                ))
              }
              fullWidth
            />
            <ChildModal setAllCourses={setAllCourses} />
          </div>

          <div className="formRow">
            <div className="formCell">Instructor</div>
            <Select
              size="small"
              fullWidth
              name="companyName"
              sx={textFieldStyle}
              defaultValue={""}
              onChange={handleInstructorChange}
            >
              {allInstructors.map(({ firstName, lastName }, index) => (
                <MenuItem
                  key={`${firstName}-${lastName}-${index}`} // Ensure a unique key
                  value={`${firstName} ${lastName}`}
                >
                  {`${firstName} ${lastName}`}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="dateContainer">
            <div className="dateCell">Training Date</div>
            <LocalizationProvider
              className="localProvider"
              dateAdapter={AdapterDayjs}
            >
              <div className="customDatePickerWidth">
                <DatePicker
                  textField={(params) => <TextField {...params} fullWidth />}
                  sx={{ width: "fullWidth" }}
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                  onChange={handleDateChange}
                />
              </div>
            </LocalizationProvider>
          </div>

          <br></br>

          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>{customList("All Employees", left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList("Attendees", right)}</Grid>
          </Grid>
        </div>

        <div id="saveOrCancel">
          <SaveOrCancel
            className="DontClose"
            onClick={handleCancelOrSave}
            name="cancelModal"
            variant="contained"
          >
            Cancel
          </SaveOrCancel>
          <SaveOrCancel
            className=""
            onClick={handleCancelOrSave}
            name="save"
            variant="contained"
          >
            Save
          </SaveOrCancel>
        </div>
      </Box>
    </Modal>
  );
}
