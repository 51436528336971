export const emptyCompany = {
  companyId: "",
  dotNumber: "",
  companyName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  fax: "",
  email: "",
  contact: "",
  alcoholAndDrug: false,
  training: false,
  reminders: false,
  clearinghouse: false,
  isHidden: false,
  companyNotes: "",
  uCR: "",
  rIN: "",
  usDotHazmatRegistration: "",
  cabCard: "",
  twentyTwoNinety: "",
};

export const emptyEmployee = {
  birthday: "",
  id: "",
  employeeId: "",
  lastName: "",
  firstName: "",
  state: "",
  consortium: "NONDOT",
  isDriver: false,
  isHidden: false,
  status: false,
  companyName: "",
  employeeNotes: "",
  hireDate: "",
  terminationDate: "",
};

export const emptyUser = {
  _id: "",
  email: "",
  firstName: "",
  lastName: "",
  isAdmin: false,
  password: "",
  companyName: "",
  __v: 0,
};

export const emptyTraining = {
  _id: "",
  attendees: [],
  companyId: "",
  companyName: "",
  courses: [],
  courseTitle: "",
  instructor: "",
  trainingDate: "",
  "": 0,
};

export const emptyScreeningPercentages = {
  alcoholPercentage: 0,
  drugPercentage: 0,
  updatedBy: "",
  id: "",
};

export const emptyRemindersArray = [
  {
    reminderType: "USDOT",
    completedDate: null,
    dueDate: null,
    _id: null,
    companyName: "",
    companyId: "",
    isHidden: true,
    isLinscotManaged: false,
  },
  {
    reminderType: "UCR",
    completedDate: null,
    dueDate: null,
    _id: null,
    companyName: "",
    companyId: "",
    isHidden: true,
    isLinscotManaged: false,
  },
  {
    reminderType: "RIN",
    completedDate: null,
    dueDate: null,
    _id: null,
    companyName: "",
    companyId: "",
    isHidden: true,
    isLinscotManaged: false,
  },
  {
    reminderType: "CAB",
    completedDate: null,
    dueDate: null,
    _id: null,
    companyName: "",
    companyId: "",
    isHidden: true,
    isLinscotManaged: false,
  },
  {
    reminderType: "2290",
    completedDate: null,
    dueDate: null,
    _id: null,
    companyName: "",
    companyId: "",
    isHidden: true,
    isLinscotManaged: false,
  },
  {
    reminderType: "USHAZ",
    completedDate: null,
    dueDate: null,
    _id: null,
    companyName: "",
    companyId: "",
    isHidden: true,
    isLinscotManaged: false,
    registrationInterval: 1,
  },
];
