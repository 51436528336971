import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./SearchBar.css";

const TrainingSearchBar = ({ allTrainings = [], setSelectedValue }) => {
  const [inputValue, setInputValue] = useState("");

  const handleSelectionChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      id="search-autocomplete"
      size="small"
      freeSolo
      options={allTrainings}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option && option.courseTitle && option.companyName
          ? `${option.courseTitle} - ${option.companyName}`
          : inputValue;
      }}
      renderOption={(props, option) =>
        !option.isHidden ? (
          <li
            {...props}
            key={`${option.courseTitle}-${option.companyName}-${option.trainingDate}`}
          >
            {`${option.courseTitle} - ${option.companyName}`}
          </li>
        ) : null
      }
      inputValue={inputValue}
      renderInput={(params) => <TextField {...params} label="Search" />}
      onChange={handleSelectionChange}
      onInputChange={handleInputChange}
    />
  );
};

export default TrainingSearchBar;
