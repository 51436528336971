import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ReportsToolbar from "./reportsToolbar";
import PrintAnnualCompaniesModal from "../modals/PrintCompaniesAddedReport";
import PrintAnnualScreeningsModal from "../modals/PrintAnnualScreeningsReport";
import PrintCompanyEmployeesModal from "../modals/PrintCompanyEmployeesReport";
import { isAdmin } from "../axios/UserSession";

import { styled } from "@mui/material/styles";
import PrintIcon from "@mui/icons-material/Print";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const NewTable = styled(DataGrid)({
  fontFamily: "Poppins",
  textTransform: "none",
  backgroundColor: "white",
});

function ReportsTable({ handleAddButtonOpen }) {
  const [reportInfo, setReportInfo] = useState({});
  const [printAnnualCompaniesModal, setPrintAnnualCompaniesModal] =
    useState(false);
  const [printAnnualScreeningsModal, setPrintAnnualScreeningsModal] =
    useState(false);
  const [printCompanyEmployeesModal, setPrintCompanyEmployeesModal] =
    useState(false);

  const handleClose = () => {
    setReportInfo({});
    setPrintAnnualCompaniesModal(false);
    setPrintAnnualScreeningsModal(false);
    setPrintCompanyEmployeesModal(false);
  };

  const rows = [
    {
      id: `${new Date()}AnnualCompaniesAdded`,
      reportName: "Annual Companies Added",
      print: setPrintAnnualCompaniesModal,
    },
    {
      id: `${new Date()}AnnualScreenings`,
      reportName: "Annual Screenings Summary",
      print: setPrintAnnualScreeningsModal,
    },
    {
      id: `${new Date()}CompanyEmployees`,
      reportName: "Company Employees Report",
      print: setPrintCompanyEmployeesModal,
    },
  ];
  const columns = [
    {
      field: "reportName",
      headerName: "Report Name",
      minWidth: 300,
      sortable: true,
    },
    {
      field: "print",
      headerName: "Print",
      width: 60,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Print">
            <IconButton
              disabled={!isAdmin}
              onClick={() => {
                params.value(true);
              }}
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <NewTable
          disableRowSelectionOnClick
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          slots={{
            toolbar: ReportsToolbar,
          }}
          slotProps={{
            toolbar: {
              printOptions: {
                hideFooter: true,
                hideToolbar: true,
              },
              handleAddButtonOpen,
            },
          }}
        />
        <PrintAnnualCompaniesModal
          isOpen={printAnnualCompaniesModal}
          handleClose={handleClose}
          reportInfo={reportInfo}
          setReportInfo={setReportInfo}
        />
        <PrintAnnualScreeningsModal
          isOpen={printAnnualScreeningsModal}
          handleClose={handleClose}
        />
        <PrintCompanyEmployeesModal
          isOpen={printCompanyEmployeesModal}
          handleClose={handleClose}
          reportInfo={reportInfo}
          setReportInfo={setReportInfo}
        />
      </div>
    </div>
  );
}

export default ReportsTable;
