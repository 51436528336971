import api from "./BaseUrl";
import { updatedByInitials } from "./UserSession";

export const getEmployeeFiles = async (employeeId) => {
  try {
    const allEmployeeFiles = await api.get(`/employeefiles/${employeeId}`);
    return allEmployeeFiles.data;
  } catch (error) {
    console.error("Error fetching infrequent screenings: ", error);
  }
};

// this function isn't working as formData loses reference
// when passing it from employeeTooltip. Moving this function there.

/*
export const uploadEmployeeFile = async (formData) => {
  try {
    formData.updatedBy = updatedByInitials;
    const updatedEmployeeFile = await api.post(
      `/employeefiles/upload/${formData.employeeId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (updatedEmployeeFile.status === 200) {
    }
  } catch (error) {
    console.error("Error updating employee file: ", error);
  }
};
*/

export const deleteEmployeeFile = async (data) => {
  const { fileType, employeeId } = data;
  try {
    const deletedEmployeeFile = await api.post(
      `/employeefiles/delete/${employeeId}`,
      {
        fileType,
        employeeId,
        updatedBy: updatedByInitials,
      }
    );
    if (deletedEmployeeFile.status === 200) {
      window.location.reload();
    }
  } catch (error) {
    console.error("Error deleting employee file: ", error);
  }
};
