import api from "./BaseUrl";
import { updatedByInitials } from "./UserSession";

export const getCourses = async () => {
  try {
    const courses = await api.get(`/courses/`);
    return courses.data;
  } catch (error) {
    console.error("Error fetching courses: ", error);
  }
};

export const createCourse = async (newCourse) => {
  try {
    newCourse.createdBy = updatedByInitials;
    const course = await api.post(`/courses/`, newCourse);
    if (course) {
      const courses = await getCourses();
      return courses;
    }
  } catch (error) {
    console.error("Error creating new course: ", error);
  }
};
