import axios from "axios";

export const baseUrl = "https://pulse.linscot.com";

const api = axios.create({
  baseURL: baseUrl,
  withCredentials: true, // if you need to send cookies or auth tokens
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

export default api;
