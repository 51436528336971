import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { addEmployee } from "../axios/Employees";
import { fetchCompanies } from "../axios/Companies";
import "./AddEmployeeModal.css";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { createReminders } from "../axios/Reminders";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "50%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
  overflow: "scroll",
};

const textFieldStyle = {
  marginTop: ".4rem",
  marginBottom: ".4rem",
};

const SaveOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

const employee = {
  birthday: "",
  companyId: "",
  companyName: "",
  consortium: "NONDOT",
  employeeId: null,
  firstName: "",
  lastName: "",
  isDriver: false,
  isHidden: false,
  hireDate: "",
  state: "",
  terminationDate: "",
  employeeNotes: "",
  cdl: "",
};

let allCompanies = [];

const REMINDER_TYPES = {
  DMV: { label: "DMV", intervalYears: 1 },
  CDL: { label: "CDL", intervalYears: 5 },
  HAZMAT: { label: "HAZMAT", intervalYears: 3 },
  PHYS: { label: "PHYS", intervalYears: 2 },
  HME: { label: "HME", intervalYears: 5 },
  CLRHSE: { label: "CLRHSE", intervalYears: 1 },
};

const defaultReminders = {
  DMV: { completedDate: null, dueDate: null, isHidden: true },
  CDL: { completedDate: null, dueDate: null, isHidden: true },
  HAZMAT: { completedDate: null, dueDate: null, isHidden: true },
  PHYS: { completedDate: null, dueDate: null, isHidden: true },
  HME: { completedDate: null, dueDate: null, isHidden: true },
  CLRHSE: { completedDate: null, dueDate: null, isHidden: true },
};

export default function AddEmployeeModal({
  isAddEmployeeOpen,
  handleAddButtonClose,
  companyId,
  companyName,
  handleAlertSuccess,
}) {
  const location = useLocation();
  const newEmployeeDetails = useRef(employee);
  const [activeTab, setActiveTab] = useState("details");
  const [employeeReminders, setEmployeeReminders] = useState(defaultReminders);
  const [currentCompanyId, setCurrentCompanyId] = useState(companyId);
  const [currentCompanyName, setCurrentCompanyName] = useState(companyName);

  useEffect(() => {
    setCurrentCompanyId(companyId);
    setCurrentCompanyName(companyName);
  }, [companyId, companyName]);

  const handleReminderChange = (reminderType, field, value) => {
    setEmployeeReminders((prev) => ({
      ...prev,
      [reminderType]: {
        ...prev[reminderType],
        [field]: value,
      },
    }));
  };

  const calculateDueDate = (startDate, intervalYears) =>
    dayjs(startDate).add(intervalYears, "year");

  const handleCompletedDateChange = (date, reminderType) => {
    if (!date || !date.$d) return;

    const timestamp = dayjs(date.$d)
      .hour(12)
      .minute(0)
      .second(0)
      .millisecond(0)
      .tz("US/Central");

    const newDueDate = calculateDueDate(
      timestamp,
      REMINDER_TYPES[reminderType].intervalYears
    )
      .hour(12)
      .minute(0)
      .second(0)
      .millisecond(0);

    setEmployeeReminders((prev) => ({
      ...prev,
      [reminderType]: {
        ...prev[reminderType],
        completedDate: timestamp,
        dueDate: newDueDate,
      },
    }));
  };

  const handleDueDateChange = (date, reminderType) => {
    if (!date || !date.$d) return;

    const timestamp = dayjs(date.$d)
      .hour(12)
      .minute(0)
      .second(0)
      .millisecond(0)
      .tz("US/Central");

    setEmployeeReminders((prev) => ({
      ...prev,
      [reminderType]: {
        ...prev[reminderType],
        dueDate: timestamp,
      },
    }));
  };

  // Add this function to your component
  const handleDateBlur = (event, handleChangeFunction) => {
    const inputValue = event.target.value;
    const date = dayjs(inputValue, "MM/DD/YYYY"); // Adjust the format as needed

    if (date.isValid()) {
      handleChangeFunction(date);
    }
  };

  const handleCancelOrSave = async (e) => {
    if (e.target.name === "cancel") {
      setEmployeeReminders(defaultReminders);
      handleAddButtonClose();
      return;
    }

    if (!currentCompanyId || !currentCompanyName) {
      if (
        newEmployeeDetails.current.companyId &&
        newEmployeeDetails.current.companyName
      ) {
        setCurrentCompanyId(newEmployeeDetails.current.companyId);
        setCurrentCompanyName(newEmployeeDetails.current.companyName);
      } else {
        console.error("Company Name or ID is missing");
        return;
      }
    }

    createEmployeeId();
    newEmployeeDetails.current.companyId = currentCompanyId;
    newEmployeeDetails.current.companyName = currentCompanyName;

    try {
      const newEmployeeData = await addEmployee(newEmployeeDetails.current);

      if (!newEmployeeData) {
        console.error("Failed to create employee");
      }

      if (location.pathname === "/home") {
        await handleRemindersSave(e, newEmployeeData);
      }

      handleAlertSuccess();
    } catch (error) {
      console.error("Error creating new employee or reminders: ", error);
      // You might want to show an error message to the user here
    } finally {
      handleAddButtonClose();
      setEmployeeReminders(defaultReminders);
      newEmployeeDetails.current = { ...employee };
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const createEmployeeId = () => {
    newEmployeeDetails.current.employeeId = Math.floor(Math.random() * 1000000);
  };

  const handleChange = (e) => {
    newEmployeeDetails.current[e.target.name] = e.target.value;
  };

  const handleConsortiumChange = (event, newValue) => {
    if (newValue) {
      newEmployeeDetails.current = {
        ...newEmployeeDetails.current,
        consortium: newValue,
      };
    } else {
      newEmployeeDetails.current = {
        ...newEmployeeDetails.current,
        consortium: "NONDOT", // or whatever your default value should be
      };
    }
  };

  const handleBoxChange = (e) => {
    newEmployeeDetails.current.isDriver = e.target.checked;
  };

  const handleCompanyChange = (event, newValue) => {
    if (newValue) {
      newEmployeeDetails.current = {
        ...newEmployeeDetails.current,
        companyName: newValue.companyName,
        companyId: newValue._id,
      };
      setCurrentCompanyId(newValue._id);
      setCurrentCompanyName(newValue.companyName);
    } else {
      newEmployeeDetails.current = {
        ...newEmployeeDetails.current,
        companyName: "",
        companyId: "",
      };
      setCurrentCompanyId("");
      setCurrentCompanyName("");
    }
  };

  const handleDateChange = (e, dateType) => {
    if (dateType === "birthday") {
      newEmployeeDetails.current.birthday = e.$d;
    } else if (dateType === "hireDate") {
      newEmployeeDetails.current.hireDate = e.$d;
    }
  };

  const fetchCompaniesAndSave = async () => {
    try {
      const companies = await fetchCompanies();
      allCompanies = companies;
    } catch (error) {
      console.error(error);
    }
  };

  const validateReminders = () => {
    return Object.values(employeeReminders).every(
      (reminder) =>
        reminder.isHidden || (reminder.completedDate && reminder.dueDate)
    );
  };

  const handleRemindersSave = async (event, newEmployeeData) => {
    event.preventDefault();

    if (!validateReminders()) {
      alert("Please select a completed date for all checked reminders.");
      return;
    }

    try {
      const updatedReminders = Object.entries(employeeReminders)
        .filter(([_, reminder]) => !reminder.isHidden)
        .map(([type, reminder]) => ({
          ...reminder,
          reminderType: type,
          employeeId: newEmployeeData._id,
          firstName: newEmployeeData.firstName,
          lastName: newEmployeeData.lastName,
          companyName: newEmployeeData.companyName,
          companyId: newEmployeeData.companyId,
          isLinscotManaged: false,
        }));

      for (const reminder of updatedReminders) {
        await createReminders({ reminderData: reminder });
      }
    } catch (error) {
      console.error("Error saving reminders:", error);
    }
  };

  useEffect(() => {
    fetchCompaniesAndSave();
  }, []);

  return (
    <Modal open={isAddEmployeeOpen} onClose={handleAddButtonClose}>
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleAddButtonClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ClearIcon />
        </IconButton>
        <h3>Add a New Employee</h3>
        <Divider />
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            ".MuiTab-root": {
              fontFamily: "Poppins, sans-serif",
              textTransform: "none",
            },
          }}
        >
          <Tab label="Details" value="details" />
          <Tab
            label="Reminders"
            value="reminders"
            disabled={location.pathname === "/employees"}
          />
        </Tabs>
        {activeTab === "details" && (
          <div className="inputContainer">
            <div className="inputContainer">
              <div className="flexContainer">
                <div className="box">
                  <div className="boxOne">
                    <div className="formUserCell">First Name</div>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={textFieldStyle}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="firstName"
                    />
                  </div>
                </div>

                <div className="box">
                  <div className="boxTwo">
                    <div className="formUserCell">Last Name</div>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={textFieldStyle}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="lastName"
                    />
                  </div>
                </div>
              </div>

              {location.pathname === "/employees" && (
                <div className="formRow">
                  <div className="formCell">Company</div>
                  <Autocomplete
                    options={allCompanies}
                    getOptionLabel={(option) => option.companyName}
                    onChange={handleCompanyChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        sx={textFieldStyle}
                      />
                    )}
                    disableClearable
                    fullWidth
                    slotProps={{
                      popper: {
                        className: "DontClose",
                        placement: "bottom",
                        sx: { zIndex: "1500" },
                      },
                    }}
                  />
                </div>
              )}

              <div className="dateContainer">
                <div className="dateCell">Date of Hire</div>
                <LocalizationProvider
                  className="localProvider"
                  dateAdapter={AdapterDayjs}
                >
                  <div className="customDatePickerWidth">
                    <DatePicker
                      textField={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                      sx={{ width: "fullWidth" }}
                      slotProps={{
                        textField: { size: "small", fullWidth: true },
                      }}
                      onChange={(e) => {
                        handleDateChange(e, "hireDate");
                      }}
                    />
                  </div>
                </LocalizationProvider>
              </div>

              <div className="dateContainer">
                <div className="dateCell">Date of Birth</div>
                <LocalizationProvider
                  className="localProvider"
                  dateAdapter={AdapterDayjs}
                >
                  <div className="customDatePickerWidth">
                    <DatePicker
                      textField={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                      sx={{ width: "fullWidth" }}
                      slotProps={{
                        textField: { size: "small", fullWidth: true },
                      }}
                      onChange={(e) => {
                        handleDateChange(e, "birthday");
                      }}
                    />
                  </div>
                </LocalizationProvider>
              </div>

              <div className="formRow">
                <div className="formCell">Consortium</div>
                <Autocomplete
                  options={["DOT", "NONDOT"]}
                  defaultValue="NONDOT"
                  onChange={handleConsortiumChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      sx={textFieldStyle}
                    />
                  )}
                  disableClearable
                  fullWidth
                  slotProps={{
                    popper: {
                      className: "DontClose",
                      placement: "bottom",
                      sx: { zIndex: "1500" },
                    },
                  }}
                />
              </div>

              <div className="formRow">
                <div className="formUserCell">State</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={textFieldStyle}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="state"
                />
              </div>

              <div className="formRow">
                <div className="formUserCell">CDL</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={textFieldStyle}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="cdl"
                />
              </div>

              <div className="formRow">
                <div className="formDriverCell">Is A Driver?</div>
                <div className="checkboxContainer">
                  <Checkbox
                    name="yesCheckbox"
                    value={true}
                    onClick={(e) => {
                      handleBoxChange(e);
                    }}
                  />
                  <div className="yes">Yes</div>
                </div>
              </div>

              <div className="formRow">
                <div className="formUserCell">Notes</div>
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  sx={textFieldStyle}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="employeeNotes"
                />
              </div>
            </div>
          </div>
        )}
        {activeTab === "reminders" && (
          <div className="inputContainer">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TableContainer component={Paper}>
                <Table aria-label="reminder table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">Type</TableCell>
                      <TableCell align="center">Completed</TableCell>
                      <TableCell align="center">Due</TableCell>
                      <TableCell align="center">Applicable</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(REMINDER_TYPES).map((type) => {
                      const reminderType = REMINDER_TYPES[type];
                      const reminder = employeeReminders[type];
                      const completedDate = reminder.completedDate
                        ? dayjs(reminder.completedDate)
                        : null;
                      const dueDate = reminder.dueDate
                        ? dayjs(reminder.dueDate)
                        : null;
                      const isApplicable = !reminder.isHidden;

                      return (
                        <TableRow key={reminderType.label}>
                          <TableCell
                            align="center"
                            sx={{ width: "20%" }}
                          ></TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            {reminderType.label}
                          </TableCell>
                          <TableCell align="center" sx={{ width: "20%" }}>
                            <DatePicker
                              name="completedDate"
                              className="DontClose"
                              value={completedDate}
                              disabled={!isApplicable}
                              onChange={(date) =>
                                handleCompletedDateChange(date, type)
                              }
                              onBlur={(event) =>
                                handleDateBlur(event, (date) =>
                                  handleCompletedDateChange(date, type)
                                )
                              }
                              slotProps={{
                                popper: {
                                  className: "DontClose",
                                  placement: "bottom",
                                  sx: { zIndex: "1500" },
                                },
                                textField: { size: "small" },
                              }}
                              textField={(params) => (
                                <TextField
                                  {...params}
                                  disabled={!isApplicable}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell align="center" sx={{ width: "20%" }}>
                            <DatePicker
                              name="dueDate"
                              value={dueDate}
                              disabled={!completedDate || !isApplicable}
                              onChange={(date) =>
                                handleDueDateChange(date, type)
                              }
                              onBlur={(event) =>
                                handleDateBlur(event, (date) =>
                                  handleDueDateChange(date, type)
                                )
                              }
                              slotProps={{
                                popper: {
                                  className: "DontClose",
                                  placement: "bottom",
                                  sx: { zIndex: "1500" },
                                },
                                textField: { size: "small" },
                              }}
                              textField={(params) => <TextField {...params} />}
                            />
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            <Checkbox
                              name={reminderType.label}
                              checked={isApplicable}
                              onChange={(e) =>
                                handleReminderChange(
                                  type,
                                  "isHidden",
                                  !e.target.checked
                                )
                              }
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "20%" }}
                          ></TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </LocalizationProvider>
          </div>
        )}
        <div id="saveOrCancel">
          <SaveOrCancel
            onClick={(e) => {
              handleCancelOrSave(e);
            }}
            name="cancel"
            variant="contained"
          >
            Cancel
          </SaveOrCancel>
          <SaveOrCancel
            onClick={(e) => {
              handleCancelOrSave(e);
            }}
            name="save"
            variant="contained"
          >
            Save
          </SaveOrCancel>
        </div>
      </Box>
    </Modal>
  );
}
