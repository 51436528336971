/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React from "react";

// Material Imports
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

import { EmployeesTooltip } from "./employeesTooltip";
import { isAdmin } from "../axios/UserSession";
import "../TableToolbar.css";

const ToolbarButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const RefreshButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const ToolBarContainer = styled(GridToolbarContainer)({
  display: "flex",
  flexDirection: "row",
  width: "99%",
  justifyContent: "space-between",
});

const NewButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#2C9637",
  textTransform: "none",
});

function EmployeeToolbar({
  actionCategory,
  tabClicked,
  tab,
  rowClicked,
  isDetailsOpen,
  detailsOpen,
  handleDetailsClick,
  checked,
  handleChange,
  handleCancelOrSave,
  handleAlertSuccess,
  values,
  setChecked,
  handleButtonOpen,
  refreshData,
}) {
  return (
    <ToolBarContainer>
      <div className="employeeToolbarContainer">
        <ToolbarButton
          variant={tab === "employees" ? "outlined" : "text"}
          onClick={() => {
            tabClicked("employees");
          }}
        >
          List
        </ToolbarButton>
        <EmployeesTooltip
          props={{
            actionCategory,
            formInfo: rowClicked,
            values,
            detailsOpen,
            setChecked,
            checked,
            isDetailsOpen,
            handleChange,
            handleCancelOrSave,
            handleAlertSuccess,
          }}
        >
          <ToolbarButton
            variant={tab === "details" ? "outlined" : "text"}
            onClick={() => {
              handleDetailsClick({
                actionCategory,
                isDetailsOpen,
                rowClicked,
                setChecked,
                values,
              })();
              tabClicked("details");
            }}
          >
            Details
          </ToolbarButton>
        </EmployeesTooltip>
      </div>
      <div>
        <NewButton
          variant="text"
          name="add"
          onClick={() => {
            handleButtonOpen("add");
          }}
          startIcon={<AddBoxIcon />}
          disabled={!isAdmin}
        >
          Add Employee
        </NewButton>
        <RefreshButton
          variant="text"
          name="refresh"
          onClick={() => {
            refreshData();
          }}
          startIcon={<AutorenewIcon />}
          disabled={!isAdmin}
        >
          Refresh
        </RefreshButton>
      </div>
    </ToolBarContainer>
  );
}

export default EmployeeToolbar;
