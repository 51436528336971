/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useEffect, forwardRef, useRef, useState } from "react";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { DetailsTooltip } from "../CustomTooltips";
// Session Imports
import { isAdmin } from "../axios/UserSession";

import "../TableToolbar.css";

const ToolbarButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const RefreshButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const ToolBarContainer = styled(GridToolbarContainer)({
  display: "flex",
  flexDirection: "row",
  width: "99%",
  justifyContent: "space-between",
});

const NewButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#2C9637",
  textTransform: "none",
});

function CustomToolbar({
  attendees,
  tabClicked,
  tab,
  actionCategory,
  rowClicked,
  allCompanyEmployees,
  updateAttendees,
  isDetailsOpen,
  newOpen,
  detailsOpen,
  handleDetailsClick,
  checked,
  handleChange,
  handleCancelOrSave,
  values,
  setChecked,
  handleAddButtonOpen,
  setSelectedCourses,
  selectedCourses,
  allCourses,
  setAllCourses,
  allInstructors,
  companyReminders,
  handleAlertSuccess,
  handleDataRefresh,
}) {
  return (
    <ToolBarContainer>
      <div>
        <ToolbarButton
          variant={tab === "Company" ? "outlined" : "text"}
          onClick={() => {
            tabClicked("Company");
          }}
        >
          List
        </ToolbarButton>
        <ToolbarButton
          variant={tab === "employees" ? "outlined" : "text"}
          disabled={rowClicked.id === undefined ? true : false}
          onClick={() => {
            tabClicked("employees");
          }}
        >
          Employees
        </ToolbarButton>
        <ToolbarButton
          variant={tab === "training" ? "outlined" : "text"}
          disabled={rowClicked.id === undefined ? true : false}
          onClick={() => {
            tabClicked("training");
          }}
        >
          Training
        </ToolbarButton>
        <DetailsTooltip
          props={{
            actionCategory,
            totalAttendees: attendees,
            formInfo: rowClicked,
            allEmployees: allCompanyEmployees,
            updateAttendees,
            values,
            newOpen,
            detailsOpen,
            setChecked,
            checked,
            isDetailsOpen,
            handleChange,
            handleCancelOrSave,
            setSelectedCourses,
            selectedCourses,
            allCourses,
            setAllCourses,
            allInstructors,
            companyReminders,
            handleAlertSuccess,
          }}
        >
          <ToolbarButton
            variant={tab === "details" ? "outlined" : "text"}
            disabled={rowClicked.id === undefined ? true : false}
            onClick={() => {
              handleDetailsClick({
                isDetailsOpen,
                actionCategory,
                rowClicked,
                setChecked,
                values,
              })();
              tabClicked("details");
            }}
          >
            Details
          </ToolbarButton>
        </DetailsTooltip>
      </div>
      <div>
        <NewButton
          variant="text"
          onClick={(e) => {
            tabClicked(actionCategory);
            handleAddButtonOpen(e);
          }}
          startIcon={<AddBoxIcon />}
          name={actionCategory}
          disabled={!isAdmin}
        >
          Add {actionCategory}
        </NewButton>
        <RefreshButton
          variant="text"
          name="refresh"
          onClick={handleDataRefresh}
          startIcon={<AutorenewIcon />}
          disabled={!isAdmin}
        >
          Refresh
        </RefreshButton>
      </div>
    </ToolBarContainer>
  );
}

export default CustomToolbar;
