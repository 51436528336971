import React from "react";
import NavigationBar from "../components/NavigationBar";

import ReportsTable from "../components/reports/reportsTable";
import AvatarDropdown from "../components/AvatarDropdown";
import { useNavigate } from "react-router-dom";

// Modal Imports
import AddUserModal from "../components/modals/AddUserModal";

import "./home.css";

const Reports = () => {
  const navigate = useNavigate();
  // State for AddUserModal
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <div className="home">
      <div className="headerContainer">
        <div id="linscot">
          <h2
            onClick={() => {
              navigate("/home");
            }}
          >
            Linscot Pulse
          </h2>
        </div>
        <div id="userInfo">
          <AvatarDropdown handleOpen={handleOpen} handleClose={handleClose} />
        </div>
      </div>
      <div className="menuContainer">
        <div id="navBar">
          <NavigationBar />
        </div>
      </div>
      <div className="tableContainer">
        <div id="table">
          <ReportsTable />
        </div>
      </div>
      <AddUserModal isOpen={isOpen} handleClose={handleClose} />
    </div>
  );
};

export default Reports;
