import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import ClearIcon from "@mui/icons-material/Clear";
import { red } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import SettingsToolbar from "./settingsToolbar";

import { emptyUser } from "../BoilerplateObjects";
import { getAllUsers } from "../axios/Users";
import { getScreeningPercentages } from "../axios/Screenings";
import {
  handleDetailsClick,
  handleChange,
  handleCancelOrSave,
  mapAllUsers,
  mapPercentage,
} from "../../functions/HelperFunctions";

const NewTable = styled(DataGrid)({
  fontFamily: "Poppins",
  textTransform: "none",
  backgroundColor: "white",
});

function SettingsTable({ handleButtonOpen, handleAlertSuccess }) {
  const [tab, setTab] = useState("Users");
  const [rows, setRows] = useState([]);
  const [rowClicked, setRowClicked] = useState({});
  const [checked, setChecked] = useState({
    isHidden: false,
    isDriver: false,
    clearinghouse: false,
  });

  const values = useRef(emptyUser);

  const [detailsOpen, isDetailsOpen] = useState(false);
  const actionCategory = "Users";

  const fetchUsersAndSave = async () => {
    try {
      const users = await getAllUsers();
      setRows(mapAllUsers(users));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPercentagesAndSave = async () => {
    try {
      const percentages = await getScreeningPercentages();
      setRows(mapPercentage(percentages));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUsersAndSave();
  }, []);

  const handleRowClick = (params) => {
    setRowClicked(Object.assign(rowClicked, params.row));
  };

  const userColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "isAdmin",
      headerName: "Admin",
      minWidth: 150,
      sortable: true,
      renderCell: (params) => {
        return <div>{params.value ? "Y" : "N"}</div>;
      },
    },
    {
      field: "company",
      headerName: "Company",
      width: 350,
      sortable: true,
      renderCell: (params) => {
        return <div>{params.value ? `${params.value}` : "Linscot Admin"}</div>;
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 60,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              sx={{ color: red[500] }}
              onClick={() => {
                handleButtonOpen("delete", params);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const quotasColumns = [
    {
      field: "alcoholPercentage",
      headerName: "Alcohol Percentage",
      minWidth: 200,
      renderCell: (params) => {
        return <div>{Math.trunc(params.value * 100)} %</div>;
      },
    },
    {
      field: "drugPercentage",
      headerName: "Drug Percentage",
      minWidth: 200,
      renderCell: (params) => {
        return <div>{Math.trunc(params.value * 100)} %</div>;
      },
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      width: 120,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "update",
      headerName: "Update",
      width: 100,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Update">
            <IconButton
              sx={{ color: red[500] }}
              onClick={() => {
                handleButtonOpen("update", params);
              }}
            >
              <CachedIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState(userColumns);

  const tabClicked = (tab) => {
    setTab(tab);
    if (tab === "Users") {
      setSelectedColumns(userColumns);
      fetchUsersAndSave();
    } else if (tab === "Quotas") {
      setSelectedColumns(quotasColumns);
      fetchPercentagesAndSave();
    }
  };

  const refreshData = async () => {
    if (tab === "Users") {
      await fetchUsersAndSave();
    } else if (tab === "Quotas") {
      await fetchPercentagesAndSave();
    }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <NewTable
          disableRowSelectionOnClick
          rows={rows}
          columns={selectedColumns}
          onRowClick={handleRowClick}
          onRowDoubleClick={handleDetailsClick({
            actionCategory,
            isDetailsOpen,
            rowClicked,
            setChecked,
            values,
          })}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          slots={{
            toolbar: SettingsToolbar,
          }}
          slotProps={{
            toolbar: {
              actionCategory,
              tabClicked,
              checked,
              setChecked,
              rowClicked,
              tab,
              handleDetailsClick,
              isDetailsOpen,
              handleChange,
              handleCancelOrSave,
              detailsOpen,
              values,
              printOptions: {
                hideFooter: true,
                hideToolbar: true,
              },
              handleButtonOpen,
              refreshData,
              handleAlertSuccess,
            },
          }}
        />
      </div>
    </div>
  );
}

export default SettingsTable;
