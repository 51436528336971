import React, { useEffect, useRef } from "react";
import { fetchCompanies } from "../axios/Companies";
import { createUser } from "../axios/Users";
import "./AddUserModal.css";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "60%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
};

const SaveOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

const textFieldStyle = {
  marginTop: ".4rem",
  marginBottom: ".4rem",
};

const user = {
  companyName: "",
  companyId: "",
  email: "",
  firstName: "",
  password: "",
  lastName: "",
  isAdmin: false,
  isInstructor: false,
  updatedBy: "",
};

let allCompanies = [];

export default function AddUserModal({
  isOpen,
  handleClose,
  handleAlertSuccess,
}) {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isInstructor, setIsInstructor] = React.useState(false);
  const newUserValues = useRef(user);

  const handleAdminChange = (event) => {
    setIsAdmin(event.target.value);
  };

  const handleInstructorChange = (event) => {
    setIsInstructor(event.target.value);
  };

  const handleChange = (e) => {
    newUserValues.current[e.target.name] = e.target.value;
  };

  const handleCancelOrSave = (e) => {
    if (e.target.name === "cancel") {
      handleClose();
    } else {
      newUserValues.current.isAdmin = isAdmin;
      newUserValues.current.isInstructor = isInstructor;
      createUser(newUserValues.current);
      handleAlertSuccess();
      handleClose();
    }
  };

  const fetchCompaniesAndSave = async () => {
    try {
      const companies = await fetchCompanies();
      allCompanies = companies;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCompaniesAndSave();
  }, []);

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <h3>Add A New User</h3>
          <div className="inputContainer">
            <div className="formRow">
              <div className="formCell">Is Linscot Admin?</div>
              <Select
                size="small"
                fullWidth
                name="isAdmin"
                sx={textFieldStyle}
                defaultValue={isAdmin}
                onChange={handleAdminChange}
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </div>

            {isAdmin && (
              <div className="formRow">
                <div className="formCell">Is Linscot Instructor?</div>
                <Select
                  size="small"
                  fullWidth
                  name="isInstructor"
                  sx={textFieldStyle}
                  defaultValue={isInstructor}
                  onChange={handleInstructorChange}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </div>
            )}

            {isAdmin === false && (
              <div className="formRow">
                <div className="formCell">Company Name</div>
                <Select
                  size="small"
                  fullWidth
                  name="companyName"
                  sx={textFieldStyle}
                  defaultValue={""}
                  onChange={(e) => {
                    const selectedCompany = e.target.value;
                    handleChange({
                      target: {
                        name: "companyName",
                        value: selectedCompany.companyName,
                      },
                    });
                    handleChange({
                      target: {
                        name: "companyId",
                        value: selectedCompany._id,
                      },
                    });
                  }}
                  renderValue={(selected) => selected?.companyName || ""}
                >
                  {allCompanies.map(({ _id, companyName }, index) => (
                    <MenuItem key={index} value={{ _id, companyName }}>
                      {companyName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}

            <div className="formRow">
              <div className="formCell">First Name</div>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="firstName"
              />
            </div>

            <div className="formRow">
              <div className="formCell">Last Name</div>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="lastName"
              />
            </div>

            <div className="formRow">
              <div className="formCell">Email</div>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="email"
              />
            </div>

            <div className="formRow">
              <div className="formCell">Password</div>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="password"
              />
            </div>
          </div>

          <div id="saveOrCancel">
            <SaveOrCancel
              onClick={(e) => {
                handleCancelOrSave(e);
              }}
              name="cancel"
              variant="contained"
            >
              Cancel
            </SaveOrCancel>
            <SaveOrCancel
              onClick={(e) => {
                handleCancelOrSave(e);
              }}
              name="save"
              variant="contained"
            >
              Save
            </SaveOrCancel>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
