/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import "../TableToolbar.css";

const ToolbarButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const RefreshButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const ToolBarContainer = styled(GridToolbarContainer)({
  display: "flex",
  flexDirection: "row",
  width: "99%",
  justifyContent: "space-between",
});

const NewButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#2C9637",
  textTransform: "none",
});

function ScreeningsToolbar({
  tabClicked,
  tab,
  setAddInfrequentScreeningsModal,
  asyncRefresh,
}) {
  return (
    <ToolBarContainer>
      <div>
        <ToolbarButton
          variant={tab === "Current" ? "outlined" : "text"}
          onClick={() => {
            tabClicked("Current");
          }}
        >
          Current
        </ToolbarButton>
        <ToolbarButton
          variant={tab === "History" ? "outlined" : "text"}
          onClick={() => {
            tabClicked("History");
          }}
        >
          History
        </ToolbarButton>
        <ToolbarButton
          variant={tab === "Infrequent" ? "outlined" : "text"}
          onClick={() => {
            tabClicked("Infrequent");
          }}
        >
          Infrequent
        </ToolbarButton>
      </div>
      <div>
        {tab === "Infrequent" ? (
          <NewButton
            variant="text"
            onClick={() => {
              setAddInfrequentScreeningsModal(true);
            }}
            startIcon={<AddBoxIcon />}
          >
            Add Screening
          </NewButton>
        ) : (
          <Fragment></Fragment>
        )}

        <RefreshButton
          variant="text"
          name="refresh"
          onClick={() => {
            if (typeof asyncRefresh === "function") {
              asyncRefresh(); // This will refresh the data without showing an alert
            }
          }}
          startIcon={<AutorenewIcon />}
        >
          Refresh
        </RefreshButton>
      </div>
    </ToolBarContainer>
  );
}

export default ScreeningsToolbar;
