import React from "react";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import RemindersToolbar from "./remindersToolbar";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const NewTable = styled(DataGrid)({
  fontFamily: "Poppins",
  textTransform: "none",
  backgroundColor: "white",
});

const SecondaryButton = styled(Button)({
  fontFamily: "Poppins",
  textTransform: "none",
  color: "#21357B",
  margin: "10px",
  width: "110px",
});

const employeeColumns = [
  {
    field: "_id",
    headerName: "ID",
    minWidth: 150,
    sortable: true,
    renderCell: (params) => {
      return <div>{params.value.slice(-6).toUpperCase()}</div>;
    },
  },
  {
    field: "lastName",
    headerName: "Last Name",
    minWidth: 200,
    sortable: true,
  },
  {
    field: "firstName",
    headerName: "First Name",
    minWidth: 200,
    sortable: true,
  },
  {
    field: "companyName",
    headerName: "Company",
    minWidth: 200,
    sortable: true,
  },
  {
    field: "dueDate",
    headerName: "Due",
    minWidth: 150,
    sortable: true,
    renderCell: (params) => {
      return (
        <div>{dayjs(params.value).tz("US/Central").format("MM/DD/YYYY")}</div>
      );
    },
  },
  {
    field: "reminderType",
    headerName: "Type",
    minWidth: 150,
    sortable: true,
  },
];

const companyColumns = [
  {
    field: "_id",
    headerName: "ID",
    minWidth: 150,
    sortable: true,
    renderCell: (params) => {
      return <div>{params.value.slice(-6).toUpperCase()}</div>;
    },
  },
  {
    field: "companyName",
    headerName: "Company",
    minWidth: 150,
    sortable: true,
  },
  {
    field: "dueDate",
    headerName: "Due",
    minWidth: 150,
    sortable: true,
    renderCell: (params) => {
      return (
        <div>{dayjs(params.value).tz("US/Central").format("MM/DD/YYYY")}</div>
      );
    },
  },
  {
    field: "reminderType",
    headerName: "Type",
    minWidth: 150,
    sortable: true,
  },
];

function RemindersTable({
  rows = [],
  tabClicked,
  tab,
  isCommitting,
  handleCommitClick,
  asyncRefresh,
}) {
  if (isCommitting) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <NewTable
          disableRowSelectionOnClick
          rows={rows} // Default to an empty array if rows is undefined
          columns={tab === "Employee" ? employeeColumns : companyColumns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          slots={{
            toolbar: RemindersToolbar,
            footer: () => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
                  <span style={{ fontWeight: "bold", marginRight: "16px" }}>
                    Total Rows: {rows.length}
                  </span>
                  {rows !== undefined && rows.length > 0 && (
                    <SecondaryButton
                      variant="outlined"
                      onClick={handleCommitClick}
                    >
                      Commit
                    </SecondaryButton>
                  )}
                </Box>
                <GridPagination />
              </div>
            ),
          }}
          slotProps={{
            toolbar: {
              tab,
              tabClicked,
              printOptions: {
                hideFooter: true,
                hideToolbar: true,
              },
              asyncRefresh,
            },
          }}
        />
      </div>
    </div>
  );
}

export default RemindersTable;
