import React from "react";
import dayjs from "dayjs";
import "./AddUserModal.css";
import "./DeleteEmployeeModal.css";
import { fetchCompaniesFromTimeFrame } from "../axios/Companies";
import { getAnnualCompaniesAddedReport } from "../axios/Reports";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "25%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
};

const textFieldStyle = {
  marginTop: ".4rem",
  marginBottom: ".4rem",
  width: "100%",
};

const PrintOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

export default function PrintAnnualCompaniesModal({
  isOpen,
  handleClose,
  reportInfo,
  setReportInfo,
}) {
  const handleCancelOrPrint = (e) => {
    if (e.target.name === "print") {
      getReport();
    }
    handleClose();
    setReportInfo({});
  };

  const getReport = async () => {
    const { year } = reportInfo;
    const data = await fetchCompaniesFromTimeFrame({
      startDate: dayjs(`${year}-01-01T00:00:00`).unix(),
      endDate: dayjs(`${year}-12-31T23:59:59`).unix(),
    });
    getAnnualCompaniesAddedReport({ date: year, companies: data });
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <div className="inputContainer">
            <div className="formRow">
              <div className="formUserCell">Enter Year</div>
              <TextField
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                onChange={(e) => {
                  setReportInfo({ year: e.target.value });
                }}
                name="companyName"
              />
            </div>
          </div>

          <div id="deleteOrCancel">
            <div>
              <PrintOrCancel
                onClick={(e) => {
                  handleCancelOrPrint(e);
                }}
                name="cancel"
                variant="contained"
              >
                Cancel
              </PrintOrCancel>
              <PrintOrCancel
                onClick={(e) => {
                  handleCancelOrPrint(e);
                }}
                name="print"
                variant="contained"
              >
                Print
              </PrintOrCancel>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
