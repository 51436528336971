/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React from "react";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { SettingsTooltip } from "./settingsTooltip";
import "../TableToolbar.css";

const ToolbarButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const RefreshButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const ToolBarContainer = styled(GridToolbarContainer)({
  display: "flex",
  flexDirection: "row",
  width: "99%",
  justifyContent: "space-between",
});

const NewButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#2C9637",
  textTransform: "none",
});

function SettingsToolbar({
  actionCategory,
  tabClicked,
  tab,
  rowClicked,
  isDetailsOpen,
  detailsOpen,
  handleDetailsClick,
  checked,
  handleChange,
  handleCancelOrSave,
  values,
  setChecked,
  handleButtonOpen,
  refreshData,
  handleAlertSuccess,
}) {
  return (
    <ToolBarContainer>
      <div>
        <ToolbarButton
          variant={tab === "Users" ? "outlined" : "text"}
          onClick={() => {
            tabClicked("Users");
          }}
        >
          Users
        </ToolbarButton>
        <ToolbarButton
          variant={tab === "reminders" ? "outlined" : "text"}
          onClick={() => {
            tabClicked("reminders");
          }}
        >
          Reminders
        </ToolbarButton>
        <ToolbarButton
          variant={tab === "Quotas" ? "outlined" : "text"}
          onClick={() => {
            tabClicked("Quotas");
          }}
        >
          Quotas
        </ToolbarButton>
        <SettingsTooltip
          props={{
            actionCategory,
            formInfo: rowClicked,
            values,
            detailsOpen,
            setChecked,
            checked,
            isDetailsOpen,
            handleChange,
            handleCancelOrSave,
            handleAlertSuccess,
          }}
        >
          <ToolbarButton
            variant={tab === "details" ? "outlined" : "text"}
            onClick={() => {
              handleDetailsClick({
                actionCategory,
                isDetailsOpen,
                rowClicked,
                setChecked,
                values,
              })();
              tabClicked("details");
            }}
          >
            Details
          </ToolbarButton>
        </SettingsTooltip>
      </div>
      <div>
        <NewButton
          variant="text"
          onClick={() => {
            handleButtonOpen("add");
          }}
          startIcon={<AddBoxIcon />}
        >
          Add User
        </NewButton>
        <RefreshButton
          variant="text"
          name="refresh"
          onClick={() => {
            refreshData();
          }}
          startIcon={<AutorenewIcon />}
        >
          Refresh
        </RefreshButton>
      </div>
    </ToolBarContainer>
  );
}

export default SettingsToolbar;
