import React, { useRef } from "react";
import NavigationBar from "../components/NavigationBar";
import SettingsTable from "../components/settings/settingsTable";
import AvatarDropdown from "../components/AvatarDropdown";
import {
  emptyUser,
  emptyScreeningPercentages,
} from "../components/BoilerplateObjects";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

// Modal Imports
import AddUserModal from "../components/modals/AddUserModal";
import DeleteUserModal from "../components/modals/DeleteUserModal";
import UpdateScreeningPercentagesModal from "../components/modals/UpdateScreeningPercentagesModal";

import "./home.css";

const Settings = () => {
  const navigate = useNavigate();

  // State for AddUserModal
  const [isOpen, setIsOpen] = React.useState(false);

  // State for Open/Closing DeleteUserModal
  const [isDeleteUserOpen, setIsDeleteUserOpen] = React.useState(false);

  // State for Open/Closing UpdateScreeningPercentagesModal
  const [isScreeningPercentagesOpen, setIsScreeningPercentagesOpen] =
    React.useState(false);

  // State to manage alert visibility
  const [alertOpen, setAlertOpen] = React.useState(false);

  const userRef = useRef(emptyUser);
  const screeningRef = useRef(emptyScreeningPercentages);

  const handleButtonOpen = (e, params) => {
    if (e === "add") {
      setIsOpen(true);
    } else if (e === "delete") {
      setIsDeleteUserOpen(true);
      userRef.current = params.row;
    } else if ("update") {
      setIsScreeningPercentagesOpen(true);
      screeningRef.current = params.row;
    }
  };

  const handleButtonClose = () => {
    setIsOpen(false);
    setIsDeleteUserOpen(false);
    setIsScreeningPercentagesOpen(false);
    userRef.current = emptyUser;
  };

  const handleSuccess = () => {
    setAlertOpen(true);
  };

  return (
    <div className="home">
      <div className="headerContainer">
        <div id="linscot">
          <h2
            onClick={() => {
              navigate("/home");
            }}
          >
            Linscot Pulse
          </h2>
        </div>
        <div id="userInfo">
          <AvatarDropdown
            handleOpen={handleButtonOpen}
            handleClose={handleButtonClose}
          />
        </div>
      </div>
      <div className="menuContainer">
        <div id="navBar">
          <NavigationBar />
        </div>
      </div>
      <div className="tableContainer">
        <div id="table">
          <SettingsTable
            handleButtonOpen={handleButtonOpen}
            handleAlertSuccess={handleSuccess}
          />
        </div>
      </div>
      <AddUserModal
        handleAlertSuccess={handleSuccess}
        isOpen={isOpen}
        handleClose={handleButtonClose}
      />
      <DeleteUserModal
        isOpen={isDeleteUserOpen}
        handleClose={handleButtonClose}
        userRef={userRef}
        onSuccess={handleSuccess}
      />
      <UpdateScreeningPercentagesModal
        isOpen={isScreeningPercentagesOpen}
        handleClose={handleButtonClose}
        screeningRef={screeningRef}
        onSuccess={handleSuccess} // Pass onSuccess as a prop to the DeleteUserModal
      />
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }} // Position at top left
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="success"
          sx={{
            width: "300px",
            height: "50px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Success - Please refresh for updated data!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Settings;
