import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Pages & Components
import Home from "./pages/home";
import Login from "./pages/login";
import Reset from "./pages/reset";
import Employees from "./pages/employees";
import Settings from "./pages/settings";
import Training from "./pages/training";
import Reports from "./pages/reports";
import Screenings from "./pages/screenings";
import Reminders from "./pages/reminders";
import {
  PasswordRoute,
  PrivateRoutes,
  AdminRoutes,
} from "./components/PrivateRoute";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<AdminRoutes />}>
            <Route path="/settings" element={<Settings />} />
            <Route path="/reports" element={<Reports />}></Route>
            <Route path="/reminders" element={<Reminders />}></Route>
            <Route path="/screenings" element={<Screenings />}></Route>
            <Route path="/training" element={<Training />} />
          </Route>
          <Route path="/home" element={<Home />} />
          <Route path="/employees" element={<Employees />} />
        </Route>
        <Route element={<PasswordRoute />}>
          <Route path="/reset" element={<Reset />} />
        </Route>
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
