import api from "./BaseUrl";
import { updatedByInitials } from "./UserSession";

export const createCompany = async (company) => {
  try {
    company.createdBy = updatedByInitials;
    company.isHidden = false;
    const response = await api.post(`/companies/`, company);
    const companyId = response.data._id;
    return companyId;
  } catch (error) {
    console.error("Error creating new company: ", error);
  }
};

export const getMappedCompanies = async (props) => {
  try {
    const response = await api.get(`/companies`);
    props.setData(response.data);
    props.mapCompanies(response.data, props.setRows);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Getting single Company for "non-admin" user
export const getMappedCompany = async (companyName, props) => {
  try {
    const response = await api.get(`/companies/${companyName}`);
    const companyArray = [response.data];
    props?.setData(companyArray);
    props?.mapCompanies(companyArray, props.setRows);
  } catch (error) {
    console.error("Error fetching company:", error);
  }
};

export const updateCompany = async ({ company }) => {
  try {
    const response = await api.post(`/companies/${company.id}`, {
      city: company.city,
      contact: company.contact,
      companyName: company.companyName,
      email: company.email,
      fax: company.fax,
      isHidden: company.isHidden,
      phone: company.phone,
      programs: {
        alcoholAndDrug: company.alcoholAndDrug,
        training: company.training,
        reminders: company.reminders,
        clearinghouse: company.clearinghouse,
      },
      companyNotes: company.companyNotes,
      state: company.state,
      street: company.address,
      usDotNumber: company.dotNumber,
      zip: company.zip,
      uCR: company.uCR,
      rIN: company.rIN,
      usDotHazmatRegistration: company.usDotHazmatRegistration,
      cabCard: company.cabCard,
      twentyTwoNinety: company.twentyTwoNinety,
      updatedBy: updatedByInitials,
    });

    if (response.status === 200) {
      if (company.isDetailsOpen !== undefined) {
        company.isDetailsOpen(false);
      }
      if (company.isHidden === true || company.isHidden === "true") {
        return null;
      }
      return response.data;
    }
  } catch (error) {
    console.error("Error updating company:", error);
  }
};

export const fetchCompanies = async () => {
  try {
    const companies = await api.get(`/companies`);
    return companies.data;
  } catch (error) {
    console.error("Error fetching companies: ", error);
  }
};

export const fetchCompaniesForScreenings = async () => {
  try {
    const companies = await api.get(`/companies/screened`);
    return companies.data;
  } catch (error) {
    console.error("Error fetching companies: ", error);
  }
};

export const fetchCompaniesFromTimeFrame = async ({ startDate, endDate }) => {
  try {
    const companiesFromTimeFrame = await api.get(
      `/companies/findCompanies/${startDate}/${endDate}`
    );
    return companiesFromTimeFrame.data;
  } catch (error) {
    console.error("Error fetching companies: ", error);
  }
};
