import React, { useEffect, useRef, forwardRef, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { sendPWResetLink, updateUserName } from "../axios/Users";
import Alert from "@mui/material/Alert";

import "../TableToolbar.css";

const RefTooltip = forwardRef((props, ref) => <Tooltip ref={ref} {...props} />);

const StyledDetailsTooltip = styled(({ className, ...props }) => (
  <RefTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    color: "black",
    backgroundColor: "white",
    fontSize: "14px",
    fontFamily: "Poppins",
    padding: "0px 0px 0px 20px",
    margin: "0px 0px 0px 25px",
    position: "relative",
  },
});

const SaveOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

const ResetPasswordButton = styled(Button)({
  fontFamily: "Poppins",
  height: "40px", // Adjust this value to match your TextField height
  textTransform: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "140px",
  color: "white",
  backgroundColor: "#21357B",
  "&:hover": {
    backgroundColor: "white",
    color: "#21357B",
  },
});

export function SettingsTooltip({ props, children }) {
  let {
    actionCategory,
    formInfo,
    isDetailsOpen,
    detailsOpen,
    handleAlertSuccess,
  } = props;

  const tooltipRef = useRef(null);
  const [resetAlert, setResetAlert] = useState(false);
  const [nameUpdateAlert, setNameUpdateAlert] = useState(false);
  const [values, setValues] = useState({
    firstName: formInfo.firstName || "",
    lastName: formInfo.lastName || "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handlePasswordReset = () => {
    sendPWResetLink({ user: formInfo.email, setAlert: setResetAlert });
  };

  const handleNameUpdate = async () => {
    try {
      await updateUserName(formInfo.email, values.firstName, values.lastName);
      setNameUpdateAlert(true);
      setTimeout(() => setNameUpdateAlert(false), 3000); // Hide alert after 3 seconds
    } catch (error) {
      console.error("Error updating name:", error);
    }
  };

  const handleCancelOrSave = (event) => {
    const { name } = event.target;
    if (name === "cancel") {
      isDetailsOpen(false);
    } else if (name === "save") {
      handleNameUpdate();
      handleAlertSuccess();
      isDetailsOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      const DontCloseElement =
        event.target.classList.contains("MuiModal-backdrop") ||
        event.target.classList.contains("MuiPickersDay-root") ||
        event.target.classList.contains("DontClose") ||
        event.target.classList.contains("MuiPickersCalendarHeader-label") ||
        event.target.classList.contains(
          "MuiPickersCalendarHeader-switchViewIcon"
        );
      if (!DontCloseElement) {
        isDetailsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (
    Object.keys(formInfo).length <= 0 ||
    Object.keys(formInfo).length === undefined ||
    Object.keys(formInfo).length === null
  ) {
    return <React.Fragment>{children}</React.Fragment>;
  } else if (actionCategory === "Users" && Object.keys(formInfo).length > 0) {
    let { firstName, lastName, company, email, isAdmin } = formInfo;
    return (
      <StyledDetailsTooltip
        title={
          <div ref={tooltipRef} className="tooltipFormBox">
            <h3>{actionCategory} Details</h3>
            <div className="tooltipFormRow">
              <div className="tooltipNames">
                <div className="tooltipNamesCell">First Name</div>
                <TextField
                  name="firstName"
                  onChange={handleChange}
                  fullWidth
                  defaultValue={firstName}
                  size="small"
                  variant="outlined"
                />
              </div>
              <div className="tooltipNames">
                <div className="tooltipNamesCell">Last Name</div>
                <TextField
                  name="lastName"
                  onChange={handleChange}
                  fullWidth
                  defaultValue={lastName}
                  size="small"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="employeeRow">
              <div className="tooltipFormCell">Password</div>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <TextField
                  name="password"
                  fullWidth
                  size="small"
                  variant="outlined"
                  type="password"
                  defaultValue="••••••••"
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ marginRight: "8px" }}
                />
                <ResetPasswordButton
                  onClick={handlePasswordReset}
                  variant="outlined"
                  size="small"
                  title="Send password reset link"
                >
                  Reset Password
                </ResetPasswordButton>
              </div>
            </div>
            {resetAlert && (
              <Alert
                severity="info"
                onClose={() => setResetAlert(false)}
                style={{ marginTop: "10px" }}
              >
                Password reset link sent to {email}.
              </Alert>
            )}
            <div className="employeeRow">
              <div className="tooltipFormCell">Username</div>
              <TextField
                name="email"
                fullWidth
                disabled
                size="small"
                variant="outlined"
                defaultValue={email}
              />
            </div>
            <div className="employeeRow">
              <div className="tooltipFormCell">Company</div>
              <TextField
                name="company"
                fullWidth
                disabled
                size="small"
                variant="outlined"
                defaultValue={isAdmin ? "Linscot Admin" : company}
              />
            </div>
            {nameUpdateAlert && (
              <Alert
                severity="success"
                onClose={() => setNameUpdateAlert(false)}
                style={{ marginTop: "10px" }}
              >
                Name updated successfully.
              </Alert>
            )}
            <div id="saveOrCancel">
              <SaveOrCancel
                onClick={handleCancelOrSave}
                name="cancel"
                variant="contained"
              >
                Cancel
              </SaveOrCancel>
              <SaveOrCancel
                onClick={handleCancelOrSave}
                name="save"
                variant="contained"
              >
                Save
              </SaveOrCancel>
            </div>
          </div>
        }
        open={detailsOpen}
      >
        {children}
      </StyledDetailsTooltip>
    );
  }
}
