import React from "react";
import "./AddUserModal.css";
import "./DeleteEmployeeModal.css";
import { updateScreeningPercentages } from "../axios/Screenings";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "25%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
};

const SaveOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

export default function UpdateScreeningPercentagesModal({
  isOpen,
  handleClose,
  screeningRef,
  onSuccess,
}) {
  const handleCancelOrSave = (e) => {
    if (e.target.name === "cancel") {
      handleClose();
    } else {
      // Call deleteUser and pass onSuccess to handle successful deletion
      updateScreeningPercentages({
        screeningPercentages: screeningRef.current,
        onSuccess: () => {
          onSuccess(); // Call onSuccess from props
          handleClose(); // Close the modal after deletion
        },
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "drug") {
      screeningRef.current.drugPercentage = e.target.value;
    } else if (e.target.name === "alcohol") {
      screeningRef.current.alcoholPercentage = e.target.value;
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <div className="inputContainer">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "24px",
              }}
            >
              Set the percentages for Random Screenings
            </div>
            <div className="formRow">
              <div className="formUserCell">Drug</div>
              <Slider
                defaultValue={Math.trunc(
                  screeningRef.current.drugPercentage * 100
                )}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={handleChange}
                name="drug"
              />
            </div>
            <div className="formRow">
              <div className="formUserCell">Alcohol</div>
              <Slider
                defaultValue={Math.trunc(
                  screeningRef.current.alcoholPercentage * 100
                )}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={handleChange}
                name="alcohol"
              />
            </div>
          </div>

          <div id="saveOrCancel">
            <div>
              <SaveOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e);
                }}
                name="cancel"
                variant="contained"
              >
                Cancel
              </SaveOrCancel>
              <SaveOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e);
                }}
                name="save"
                variant="contained"
              >
                Save
              </SaveOrCancel>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
