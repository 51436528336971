import api from "./BaseUrl";
import { updatedByInitials } from "./UserSession";

export const createUser = async (body) => {
  try {
    body.createdBy = updatedByInitials;
    const newUser = await api.post(`/user`, body);
    if (newUser.status === 200) {
    }
  } catch (error) {
    console.error("Error adding user: ", error);
  }
};

export const getInstructors = async () => {
  try {
    const instructors = await api.get(`/user/instructors`);
    return instructors.data;
  } catch (error) {
    console.error("Error getting instructors: ", error);
  }
};

export const fetchUser = async (params) => {
  try {
    const response = await api.post(`/user/auth`, {
      email: params.user,
      password: params.pw,
    });
    if (response.status === 200) {
      sessionStorage.setItem("authenticated", true);
      sessionStorage.setItem("user", JSON.stringify(response.data));
      params.navigate("/home");
      params.setError(false);
      window.location.reload();
    }
  } catch (error) {
    if (error.response && error.response.data.type === "email") {
      params.setError("username");
    } else if (error.response && error.response.data.type === "password") {
      params.setError("password");
    } else {
      console.error("Error fetching data:", error);
      params.setError("both");
    }
  }
};

export const sendPWResetLink = async (params) => {
  try {
    await api
      .post(`/user/reset/password`, {
        email: params.user,
      })
      .then(function (response) {
        if (response.status === 200) {
          params.setAlert(true);
        }
      });
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const updateUserPassword = async (params) => {
  try {
    params.updatedBy = updatedByInitials;
    await api
      .post(`/user/update/password`, {
        email: params.user,
        password: params.pw1,
      })
      .then(function (response) {
        if (response.status === 200) {
          params.navigate("/");
        }
      });
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const updateUserName = async (email, firstName, lastName) => {
  try {
    const response = await api.post(`/user/update/name`, {
      email,
      firstName,
      lastName,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating user name:", error);
    throw error;
  }
};

export const getAllUsers = async (params) => {
  try {
    const users = await api.get(`/user`);
    return users.data;
  } catch (error) {
    console.error("Error getting all users: ", error);
  }
};

export const deleteUser = async ({ user, onSuccess }) => {
  try {
    const response = await api.post(`/user/delete`, {
      email: user.email,
    });
    if (response.status === 200) {
      onSuccess(); // Call the onSuccess callback immediately
      window.location.reload();
    }
  } catch (error) {
    console.error("Error deleting user:", error);
  }
};
