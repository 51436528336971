/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React from "react";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { DetailsTooltip } from "../CustomTooltips";

const ToolbarButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const RefreshButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const ToolBarContainer = styled(GridToolbarContainer)({
  display: "flex",
  flexDirection: "row",
  width: "99%",
  justifyContent: "space-between",
});

const NewButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#2C9637",
  textTransform: "none",
});

function TrainingsToolbar({
  tabClicked,
  tab,
  rowClicked,
  isDetailsOpen,
  detailsOpen,
  handleDetailsClick,
  checked,
  handleChange,
  handleCancelOrSave,
  values,
  setChecked,
  handleAddButtonOpen,
  refreshData,
  handleAlertSuccess,
}) {
  return (
    <ToolBarContainer>
      <div>
        <ToolbarButton
          variant={tab === "list" ? "outlined" : "text"}
          onClick={() => {
            tabClicked("list");
          }}
        >
          List
        </ToolbarButton>
        <ToolbarButton
          variant={tab === "instructors" ? "outlined" : "text"}
          onClick={() => {
            handleDetailsClick({
              isDetailsOpen,
              rowClicked,
              setChecked,
              values,
            })();
            tabClicked("instructors");
          }}
        >
          Instructors
        </ToolbarButton>
        <DetailsTooltip
          props={{
            formInfo: rowClicked,
            values,
            detailsOpen,
            setChecked,
            checked,
            isDetailsOpen,
            handleChange,
            handleCancelOrSave,
            handleAlertSuccess,
          }}
        ></DetailsTooltip>
      </div>
      <div>
        <NewButton
          variant="text"
          onClick={() => {
            handleAddButtonOpen();
          }}
          startIcon={<AddBoxIcon />}
        >
          Add Training
        </NewButton>
        <RefreshButton
          variant="text"
          name="refresh"
          onClick={() => {
            refreshData();
          }}
          startIcon={<AutorenewIcon />}
        >
          Refresh
        </RefreshButton>
      </div>
    </ToolBarContainer>
  );
}

export default TrainingsToolbar;
