import { updateCompany } from "../components/axios/Companies";
import { updateEmployee } from "../components/axios/Employees";
import { updateTraining } from "../components/axios/Trainings";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);
// Close tooltip if cancel is clicked
// Close tooltip and make the correct axios request if save is clicked
export const handleCancelOrSave = (e, props) => {
  let { isDetailsOpen, detailsOpen, values, actionCategory } = props;
  if (e.target.name === "cancel") {
    isDetailsOpen(false);
  } else if (detailsOpen && actionCategory === "Company") {
    updateCompany({ company: { ...values.current, isDetailsOpen } });
    isDetailsOpen(false);
  } else if (detailsOpen && actionCategory === "Employee") {
    updateEmployee({ employee: { ...values.current, isDetailsOpen } });
    isDetailsOpen(false);
  } else if (detailsOpen && actionCategory === "Training") {
    updateTraining({ training: { ...values.current, isDetailsOpen } });
    isDetailsOpen(false);
  }
};

// open details tooltip
// and propagate tooltip based on what category is currently selected
export const handleDetailsClick = (props) => {
  return () => {
    props.isDetailsOpen(true);
    if (props.actionCategory === "Company") {
      let {
        id,
        dotNumber,
        companyName,
        address,
        city,
        state,
        zip,
        phone,
        fax,
        email,
        contact,
        alcoholAndDrug,
        training,
        reminders,
        clearinghouse,
        companyNotes,
        uCR,
        rIN,
        usDotHazmatRegistration,
        cabCard,
        twentyTwoNinety,
      } = props.rowClicked;
      props.values.current = Object.assign(
        {
          id,
          dotNumber,
          companyName,
          address,
          city,
          state,
          zip,
          phone,
          fax,
          email,
          contact,
          alcoholAndDrug,
          training,
          reminders,
          clearinghouse,
          companyNotes,
          uCR,
          rIN,
          usDotHazmatRegistration,
          cabCard,
          twentyTwoNinety,
        },
        props.rowClicked
      );
      props.setChecked({
        alcoholAndDrug: props.values.current.alcoholAndDrug,
        training: props.values.current.training,
        reminders: props.values.current.reminders,
        clearinghouse: props.values.current.clearinghouse,
      });
    } else if (props.actionCategory === "Employee") {
      let {
        id,
        lastName,
        firstName,
        state,
        consortium,
        isDriver,
        cdl,
        employeeNotes,
      } = props.rowClicked;
      props.values.current = Object.assign(
        {
          id,
          lastName,
          firstName,
          state,
          consortium,
          isDriver,
          cdl,
          employeeNotes,
        },
        props.rowClicked
      );
      props.setChecked({
        isDriver: props.values.current.isDriver,
      });
    } else if (props.actionCategory === "Training") {
      let { date, instructor, title, attendees, courses } = props.rowClicked;
      props.values.current = Object.assign(
        {
          date,
          instructor,
          title,
          attendees,
          courses,
        },
        props.rowClicked
      );
    }
  };
};

// Gets the info from a row that is clicked for propagating the tooltip
export const handleChange = (e, props, s) => {
  // check if this is change has to do with a date
  if (e.$d !== undefined && e.$d !== null) {
    if (s === "hireDate") {
      props.values.current.hireDate = e.$d;
    } else if (s === "terminationDate") {
      props.values.current.terminationDate = e.$d;
    } else if (s === "birthday") {
      props.values.current.birthday = e.$d;
    } else {
      props.values.current.date = e.$d;
    }
  } else if (
    // check if this change has to do with a checkbox category
    e.target.name === "alcoholAndDrug" ||
    e.target.name === "reminders" ||
    e.target.name === "training" ||
    e.target.name === "isDriver" ||
    e.target.name === "clearinghouse" ||
    e.target.name === "DMV" ||
    e.target.name === "CDL" ||
    e.target.name === "HAZMAT" ||
    e.target.name === "PHYS" ||
    e.target.name === "HME"
  ) {
    if (props.values.current[e.target.name]) {
      props.values.current[e.target.name] = false;
      props.setChecked({ ...props.checked, [e.target.name]: false });
    } else {
      props.values.current[e.target.name] = true;
      props.setChecked({ ...props.checked, [e.target.name]: true });
    }
  } else {
    // update the new key value pair
    props.values.current[e.target.name] = e.target.value;
  }
};

// Getting the total number of drivers
export const countDrivers = (data) => {
  return data.reduce((total, item) => {
    if (item.isDriver === true) {
      total++;
    }
    return total;
  }, 0);
};

// Maps the datagrid (table) with  companies
export const mapCompanies = (data, callback) => {
  let companyRows = [];
  if (data && data.length >= 1) {
    for (let i = 0; i < data.length; i++) {
      let currentCompany = data[i];
      if (
        currentCompany.isHidden !== true &&
        currentCompany.isHidden !== "true"
      ) {
        companyRows.push({
          id: currentCompany._id || "",
          companyId: currentCompany._id || "",
          dotNumber: currentCompany?.companyDetails?.usDotNumber || "",
          companyName: currentCompany?.companyName || "",
          address: currentCompany?.companyDetails?.address?.street || "",
          city: currentCompany?.companyDetails?.address?.city || "",
          state: currentCompany?.companyDetails?.address?.state || "",
          zip: currentCompany?.companyDetails?.address?.zip || "",
          phone: currentCompany?.companyDetails?.phone || "",
          fax: currentCompany?.companyDetails?.fax || "",
          email: currentCompany?.companyDetails?.email || "",
          contact: currentCompany?.companyDetails?.contact || "",
          alcoholAndDrug:
            currentCompany?.companyDetails?.programs?.alcoholAndDrug || false,
          training: currentCompany?.companyDetails?.programs?.training || false,
          reminders:
            currentCompany?.companyDetails?.programs?.reminders || false,
          clearinghouse:
            currentCompany?.companyDetails?.programs?.clearinghouse || false,
          companyNotes: currentCompany?.companyDetails?.companyNotes || "",
          isHidden: currentCompany?.isHidden || false,
          updatedAt: dayjs(currentCompany?.updatedAt)
            .tz("US/Central")
            .format("MM/DD/YYYY h:mma"),
          updatedBy: currentCompany?.updatedBy || "",
          uCR: currentCompany?.companyDetails?.uCR || "",
          rIN: currentCompany?.companyDetails?.rIN || "",
          usDotHazmatRegistration:
            currentCompany?.companyDetails?.usDotHazmatRegistration || "",
          cabCard: currentCompany?.companyDetails?.cabCard || "",
          twentyTwoNinety:
            currentCompany?.companyDetails?.twentyTwoNinety || "",
        });
      }
    }
  }
  callback(companyRows);
};

// Maps the datagrid (table) with employees
export const mapEmployees = (employees, callback) => {
  let employeeRows = [];
  if (employees && employees.length >= 1) {
    for (let i = 0; i < employees.length; i++) {
      let currentEmployee = employees[i];
      if (currentEmployee.isHidden === false) {
        const hireDate = currentEmployee.hireDate
          ? dayjs
              .tz(currentEmployee.hireDate, "US/Central")
              .format("MM/DD/YYYY")
          : null;
        const birthday = currentEmployee.birthday
          ? dayjs
              .tz(currentEmployee.birthday, "US/Central")
              .format("MM/DD/YYYY")
          : null;
        const terminationDate = currentEmployee.terminationDate
          ? dayjs
              .tz(currentEmployee.terminationDate, "US/Central")
              .format("MM/DD/YYYY")
          : null;
        employeeRows.push({
          id: currentEmployee._id,
          cdl: currentEmployee.cdl,
          employeeId: currentEmployee._id,
          lastName: currentEmployee.lastName,
          firstName: currentEmployee.firstName,
          state: currentEmployee.state,
          consortium: currentEmployee.consortium,
          isDriver: currentEmployee.isDriver,
          isHidden: currentEmployee.isHidden,
          employeeNotes: currentEmployee.employeeNotes,
          updatedAt: dayjs(currentEmployee.updatedAt)
            .tz("US/Central")
            .format("MM/DD/YYYY h:mma"),
          updatedBy: currentEmployee.updatedBy,
          hireDate,
          birthday,
          terminationDate,
        });
      }
    }
  }
  callback(employeeRows);
};

// Maps the datagrid (table) with trainings
export const mapTrainings = (trainings, callback) => {
  let trainingRows = [];
  if (trainings && trainings.length >= 1) {
    for (let i = 0; i < trainings.length; i++) {
      let currentTraining = trainings[i];
      trainingRows.push({
        title: currentTraining.courseTitle,
        attendees: currentTraining.attendees,
        date: dayjs
          .tz(currentTraining.trainingDate, "US/Central")
          .format("MM/DD/YYYY"),
        instructor: currentTraining.instructor,
        isHidden: currentTraining.isHidden,
        nextDate: `${dayjs
          .tz(currentTraining.trainingDate, "US/Central")
          .add(1, "year")
          .diff(dayjs(), "day")} Days`,
        id: currentTraining._id,
        trainingId: currentTraining._id,
        courses: currentTraining.courses,
        updatedAt: dayjs(currentTraining.updatedAt)
          .tz("US/Central")
          .format("MM/DD/YYYY h:mma"),
        updatedBy: currentTraining.updatedBy,
      });
    }
  }
  callback(trainingRows);
};

export const mapTrainingsByAttendee = (
  trainingSessions,
  companyName,
  attendeeName
) => {
  // Filter training sessions by companyName
  const filteredSessions = trainingSessions.filter(
    (session) => session.companyName === companyName
  );

  // Map through the filtered sessions and filter attendees by firstName and lastName
  const mappedSessions = filteredSessions
    .map((session) => ({
      ...session,
      attendees: session.attendees.filter((attendee) =>
        `${attendee.firstName} ${attendee.lastName}`
          .toLowerCase()
          .includes(attendeeName.toLowerCase())
      ),
    }))
    .filter((session) => session.attendees.length > 0); // Filter out sessions with no matching attendees

  return mappedSessions;
};

export const mapAllEmployees = (employees) => {
  let employeeRows = [];
  if (employees && employees.length >= 1) {
    for (let i = 0; i < employees.length; i++) {
      let currentEmployee = employees[i];
      const hireDate = currentEmployee.hireDate
        ? dayjs.tz(currentEmployee.hireDate, "US/Central").format("MM/DD/YYYY")
        : null;
      const birthday = currentEmployee.birthday
        ? dayjs.tz(currentEmployee.birthday, "US/Central").format("MM/DD/YYYY")
        : null;
      const terminationDate = currentEmployee.terminationDate
        ? dayjs
            .tz(currentEmployee.terminationDate, "US/Central")
            .format("MM/DD/YYYY")
        : null;
      employeeRows.push({
        id: currentEmployee._id,
        cdl: currentEmployee.cdl,
        employeeId: currentEmployee._id,
        lastName: currentEmployee.lastName,
        firstName: currentEmployee.firstName,
        state: currentEmployee.state,
        consortium: currentEmployee.consortium,
        isDriver: currentEmployee.isDriver,
        isHidden: currentEmployee.isHidden,
        companyName: currentEmployee.companyName,
        companyId: currentEmployee.companyId,
        employeeNotes: currentEmployee.employeeNotes,
        hireDate,
        birthday,
        terminationDate,
      });
    }
  }
  return employeeRows;
};

export const mapAllUsers = (users) => {
  let userRows = [];
  if (users && users.length >= 1) {
    for (let i = 0; i < users.length; i++) {
      let currentUser = users[i];
      userRows.push({
        id: currentUser._id,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        password: currentUser.password,
        isAdmin: currentUser.isAdmin,
        company: currentUser.companyName,
        email: currentUser.email,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        __v: 0,
      });
    }
  }
  return userRows;
};

export const mapPercentage = (percentage) => {
  let percentageRow = [];
  if (percentage && percentage.length >= 1) {
    for (let i = 0; i < percentage.length; i++) {
      let currentPercentage = percentage[i];
      percentageRow.push({
        id: currentPercentage._id,
        alcoholPercentage: currentPercentage.alcoholPercentage,
        drugPercentage: currentPercentage.drugPercentage,
        updatedBy: currentPercentage.updatedBy,
        updatedAt: dayjs(currentPercentage.updatedAt)
          .tz("US/Central")
          .format("MM/DD/YYYY h:mma"),
        __v: 0,
      });
    }
  }
  return percentageRow;
};

export const mapEmployeeScreenings = (screenings) => {
  let screeningRows = [];
  if (screenings && screenings.length >= 1) {
    for (let i = 0; i < screenings.length; i++) {
      let currentScreening = screenings[i];
      if (
        currentScreening?.selectedEmployees &&
        currentScreening?.selectedEmployees.length >= 1
      ) {
        for (let x = 0; x < currentScreening?.selectedEmployees.length; x++) {
          let currentEmployee = currentScreening?.selectedEmployees[x];
          screeningRows.push({
            id: currentEmployee._id + currentScreening._id,
            companyName: currentEmployee.companyName,
            companyId: currentEmployee.companyId,
            firstName: currentEmployee.firstName,
            lastName: currentEmployee.lastName,
            result: currentEmployee.status,
            screen: currentScreening.screeningType,
            quarterSelected: currentScreening.screeningQuarter,
            year: currentScreening.screeningYear,
            consortium: currentScreening.consortium,
          });
        }
      }
    }
  }
  return screeningRows;
};

export const mapSingleEmployeeScreenings = (screenings) => {
  let screeningRows = screenings.map((screening) => {
    const employeeStatus =
      screening.employeeStatus || screening.selectedEmployee;
    const baseScreening = {
      id: screening._id,
      screeningId: screening._id,
      employeeId: employeeStatus._id || employeeStatus.employeeId,
      companyName: employeeStatus.companyName,
      companyId: employeeStatus.companyId,
      firstName: employeeStatus.firstName,
      lastName: employeeStatus.lastName,
      result: employeeStatus.status,
      screen: screening.screeningType,
      date: dayjs(screening.screeningDate)
        .tz("US/Central")
        .format("MM/DD/YYYY"),
      year: screening.screeningYear,
      consortium: screening.consortium,
      failureReason: employeeStatus.failureReason || "",
      screeningReason: screening.screeningReason || "",
    };

    // Add quarter for drug and alcohol screenings
    if (
      screening.screeningType === "Drug" ||
      screening.screeningType === "Alcohol"
    ) {
      baseScreening.quarterSelected = screening.screeningQuarter;
    }

    return baseScreening;
  });

  return screeningRows;
};

export const mapScreenings = (screenings) => {
  let screeningRows = [];
  if (screenings && screenings.length >= 1) {
    for (let i = 0; i < screenings.length; i++) {
      let currentScreenings = screenings[i];
      screeningRows.push({
        id: currentScreenings._id,
        isCommitted: currentScreenings.isCommitted,
        screeningDate: dayjs(currentScreenings.screeningDate)
          .tz("US/Central")
          .format("MM/DD/YYYY"),
        consortium: currentScreenings.consortium,
        screeningType: currentScreenings.screeningType,
        screeningYear: currentScreenings.screeningYear,
        screeningQuarter: currentScreenings.screeningQuarter,
        totalMembers: currentScreenings.totalMembers,
        requiredMembers: currentScreenings.requiredMembers,
        selectedMembers: currentScreenings.selectedMembers,
      });
    }
  }
  return screeningRows;
};

export const mapCompanyScreenings = ({ employees, companies, screenings }) => {
  // Initialize mappings
  const companyMapping = {};
  const selectedEmployeesMapping = {};
  let allEmployees = 0;
  let allDrivers = 0;

  // Step 1: Iterate through the employees array to count employees and drivers
  employees.forEach((employee) => {
    const { companyId, isDriver } = employee;

    if (!companyMapping[companyId]) {
      companyMapping[companyId] = {
        totalEmployees: 0,
        totalDrivers: 0,
        totalSelected: 0,
        screeningYear: null,
        screeningQuarter: null,
        screeningType: "",
      };
      // Initialize selected employees array for the company
      selectedEmployeesMapping[companyId] = [];
    }

    companyMapping[companyId].totalEmployees += 1;
    allEmployees += 1;

    if (isDriver) {
      companyMapping[companyId].totalDrivers += 1;
      allDrivers += 1;
    }
  });

  // Step 2: Iterate through the screenings array to count selected and record year and quarter
  screenings.forEach((screening) => {
    screening.selectedEmployees.forEach((employee) => {
      const { companyId } = employee;

      if (companyMapping[companyId]) {
        companyMapping[companyId].totalSelected += 1;
        companyMapping[companyId].screeningYear = screening.screeningYear;
        companyMapping[companyId].screeningQuarter = screening.screeningQuarter;
        companyMapping[companyId].screeningType = screening.screeningType;
        // Collect selected employees
        employee.screeningType = screening.screeningType;
        selectedEmployeesMapping[companyId].push(employee);
      }
    });
  });

  // Step 3: Generate the final array with company details and selected employees
  const finalArray = companies.map((company) => {
    const companyId = company._id;
    const mapping = companyMapping[companyId] || {
      totalEmployees: 0,
      totalDrivers: 0,
      totalSelected: 0,
      screeningYear: null,
      screeningQuarter: null,
    };

    return {
      id: companyId,
      address: company.companyDetails.address,
      companyName: company.companyName,
      email: company.companyDetails.email,
      contact: company.companyDetails.contact,
      totalEmployees: mapping.totalEmployees,
      totalDrivers: mapping.totalDrivers,
      totalSelected: mapping.totalSelected,
      screeningYear: mapping.screeningYear,
      screeningQuarter: mapping.screeningQuarter,
      employees: selectedEmployeesMapping[companyId] || [],
    };
  });

  return { finalArray, allEmployees, allDrivers };
};

export function groupEmployeesByCompany(reminders, companies) {
  const groupedData = {};
  const companyMap = {};

  // First, map companies by their ID for quick access
  companies.forEach((company) => {
    companyMap[company._id] = {
      address: company.companyDetails.address,
    };
  });

  reminders.forEach((entry) => {
    const companyKey = entry.companyId;

    // Initialize the company in the grouped data if not already present
    if (!groupedData[companyKey]) {
      groupedData[companyKey] = {
        companyName: entry.companyName,
        companyId: entry.companyId,
        address: companyMap[companyKey]
          ? companyMap[companyKey].address
          : "No address found",
        employees: [], // Directly store employees here
        reminderType: entry.reminderType, // Set the reminderType to the string from the entry
      };
    }

    // Add the employee along with their reminder details directly to the company's employee list
    groupedData[companyKey].employees.push({
      firstName: entry.firstName,
      lastName: entry.lastName,
      employeeId: entry.employeeId,
      reminderType: entry.reminderType, // Reminder type for the employee (same as company reminderType)
      dueDate: entry.dueDate,
      message: entry.message,
    });
  });

  // Convert the map object into an array of companies
  return Object.values(groupedData);
}

export function aggregateScreeningsSummaryReportData(screenings) {
  // Reduce the array to a single object
  return screenings.reduce((acc, item) => {
    // Set common properties if not already set
    if (!acc.consortium) {
      acc.consortium = item.consortium;
      acc.screeningDate = item.screeningDate;
      acc.screeningYear = item.screeningYear;
      acc.screeningQuarter = item.screeningQuarter;
      acc.totalMembers = item.totalMembers;
      acc.screenings = [];
    }

    // Calculate percentage selected
    let percentSelected =
      ((item.selectedMembers / item.totalMembers) * 100).toFixed(2) + "%";

    // Add each screening to the screenings array
    acc.screenings.push({
      screeningType: item.screeningType,
      selectedMembers: item.selectedMembers,
      percentSelected: percentSelected,
    });

    return acc;
  }, {});
}

export function aggregateYearlyScreeningsData(screenings) {
  const result = screenings.reduce((acc, item) => {
    // Set common properties if not already set
    if (!acc.screeningYear) {
      acc.screeningYear = item.screeningYear;
      acc.totalMembers = 0; // Initialize to 0
      acc.drugSelected = 0;
      acc.alcoholSelected = 0;
      acc.screenings = [];
      acc.selectedEmployeeResults = {
        drug: {
          selected: 0,
          passed: 0,
          failed: 0,
          wrongTest: 0,
          refused: 0,
          notAtCompany: 0,
          sick: 0,
        },
        alcohol: {
          selected: 0,
          passed: 0,
          failed: 0,
          wrongTest: 0,
          refused: 0,
          notAtCompany: 0,
          sick: 0,
        },
      };
    }

    // Update totalMembers to the largest value
    acc.totalMembers = Math.max(acc.totalMembers, item.totalMembers);

    // Calculate total selected for drug and alcohol separately
    if (item.screeningType.toLowerCase() === "drug") {
      acc.drugSelected += item.selectedMembers;
    } else if (item.screeningType.toLowerCase() === "alcohol") {
      acc.alcoholSelected += item.selectedMembers;
    }

    // Add screening info
    acc.screenings.push({
      screeningType: item.screeningType,
      selectedMembers: item.selectedMembers,
      percentSelected:
        ((item.selectedMembers / item.totalMembers) * 100).toFixed(2) + "%",
    });

    // Count employee results
    item.selectedEmployees.forEach((employee) => {
      let status = employee.status.toLowerCase();
      const screeningType = item.screeningType.toLowerCase();

      // Convert status to camelCase if needed
      if (status === "wrong test") status = "wrongTest";
      if (status === "not at company") status = "notAtCompany";

      if (acc.selectedEmployeeResults[screeningType].hasOwnProperty(status)) {
        acc.selectedEmployeeResults[screeningType][status]++;
      } else {
        console.warn(`Unknown status: ${status}`);
      }
    });

    return acc;
  }, {});

  // Calculate overall percentage selected for drug and alcohol
  result.drugPercentSelected =
    ((result.drugSelected / result.totalMembers) * 100).toFixed(2) + "%";
  result.alcoholPercentSelected =
    ((result.alcoholSelected / result.totalMembers) * 100).toFixed(2) + "%";

  return result;
}

export const randomSelections = ({
  data,
  consortium,
  year,
  quarter,
  percentages,
}) => {
  const { alcoholPercentage, drugPercentage } = percentages[0];
  const filterEligibleEmployees = (data) =>
    data.filter(
      (emp) =>
        emp.consortium === consortium &&
        emp.isDriver === true &&
        emp.isHidden === false
    );

  const randomSelection = (n, eligibleEmployees, alreadySelected = []) => {
    let selectedEmployees = [];
    let tempEmployees = eligibleEmployees.filter(
      (emp) => !alreadySelected.includes(emp._id)
    );

    for (let i = 0; i < n && tempEmployees.length > 0; i++) {
      const index = Math.floor(Math.random() * tempEmployees.length);
      const selected = tempEmployees.splice(index, 1)[0]; // Remove selected employee from the temp list

      // Construct a simplified employee object containing only the required fields
      const simplifiedEmployee = {
        employeeId: selected._id, // Assuming the id field in your data matches the _id required by your schema
        firstName: selected.firstName,
        lastName: selected.lastName,
        companyName: selected.companyName,
        companyId: selected.companyId,
        status: "selected", // Make sure the status is provided in your data or define a default
      };

      selectedEmployees.push(simplifiedEmployee);
    }

    // Construct a screening object
    return {
      consortium,
      screeningDate: dayjs().tz("US/Central").format("MM/DD/YYYY"), // Use the current date or another logic if needed
      screeningQuarter: quarter,
      screeningYear: year,
      selectedEmployees,
      totalMembers: eligibleEmployees.length,
      selectedMembers: selectedEmployees.length,
      requiredMembers: n, // Example calculation, adjust as needed
    };
  };

  const eligibleEmployees = filterEligibleEmployees(data);

  // Random selection for Drug/Alcohol
  /* The formula for calculating the number required per quarter for drugs is as follows:
  Number of eligible X drugPercentage divided by number of quarters (4) = number of tests required.
  The formula for calculating the number required per quarter for alcohol is as follows:
  Number of eligible X alcoholPercentage divided by number of quarters (4) = number of tests required.*/

  let numberOfDrugSelections = Math.ceil(
    (eligibleEmployees.length * drugPercentage) / 4
  );
  let numberOfAlcoholSelections = Math.ceil(
    (eligibleEmployees.length * alcoholPercentage) / 4
  );

  let drugScreening = randomSelection(
    numberOfDrugSelections,
    eligibleEmployees
  );

  // Pass the IDs of employees already selected for drug screening
  let alreadySelectedIds = drugScreening.selectedEmployees.map(
    (emp) => emp.employeeId
  );
  let alcoholScreening = randomSelection(
    numberOfAlcoholSelections,
    eligibleEmployees,
    alreadySelectedIds
  );

  return { drug: drugScreening, alcohol: alcoholScreening };
};
