/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React from "react";
import Button from "@mui/material/Button";
import { GridToolbarContainer } from "@mui/x-data-grid";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { styled } from "@mui/material/styles";
import "../TableToolbar.css";

const ToolbarButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const RefreshButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#21357B",
  textTransform: "none",
});

const ToolBarContainer = styled(GridToolbarContainer)({
  display: "flex",
  flexDirection: "row",
  width: "99%",
  justifyContent: "space-between",
});

function RemindersToolbar({ tabClicked, tab, asyncRefresh }) {
  return (
    <ToolBarContainer>
      <div>
        <ToolbarButton
          variant={tab === "Company" ? "outlined" : "text"}
          onClick={() => {
            tabClicked("Company");
          }}
        >
          Company
        </ToolbarButton>
        <ToolbarButton
          variant={tab === "Employee" ? "outlined" : "text"}
          onClick={() => {
            tabClicked("Employee");
          }}
        >
          Employee
        </ToolbarButton>
      </div>
      <div>
        <RefreshButton
          variant="text"
          name="refresh"
          onClick={() => {
            if (typeof asyncRefresh === "function") {
              asyncRefresh(); // This will refresh the data without showing an alert
            }
          }}
          startIcon={<AutorenewIcon />}
        >
          Refresh
        </RefreshButton>
      </div>
    </ToolBarContainer>
  );
}

export default RemindersToolbar;
