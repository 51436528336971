import React, { useState } from "react";
import dayjs from "dayjs";
import "./AddUserModal.css";
import "./DeleteEmployeeModal.css";
import {
  fetchScreeningsFromTimeFrame,
  getYearlyScreenings,
  getScreeningPercentages,
} from "../axios/Screenings";
import { getAnnualScreeningsReport } from "../axios/Reports";
import { getAllEmployees } from "../axios/Employees";
import { countDrivers } from "../../functions/HelperFunctions";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "25%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
  overflow: "scroll",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
};

const textFieldStyle = {
  marginLeft: "auto",
  width: "50%",
};

const PrintOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

export default function PrintAnnualScreeningsModal({ isOpen, handleClose }) {
  const [year, setYear] = useState("");

  const handleChange = (e) => {
    setYear(e.target.value);
  };

  const handleCancelOrPrint = async (e) => {
    if (e.target.name === "print") {
      await getReport();
    }
    handleClose();
    setYear("");
  };
  /*
  Why variables being used here?
  We are doing calculations within this function, not in the report
  For EACH type of drug screening we need to know: 
  - how many required (random only) 
  - how many given
  - how many passed
  - how many failed
  - why did they fail
  For EACH type of alcohol screening we need to know: 
  - how many required (random only) 
  - how many given
  - how many passed
  - how many failed
  */
  const getReport = async () => {
    try {
      // Fetch all necessary data
      const [
        infrequentScreeningsResponse,
        yearlyScreeningsResponse,
        allEmployeesResponse,
        percentagesResponse,
      ] = await Promise.all([
        fetchScreeningsFromTimeFrame({
          startDate: dayjs(`${year}-01-01T00:00:00`).unix(),
          endDate: dayjs(`${year}-12-31T23:59:59`).unix(),
        }).catch((error) => {
          if (error.response && error.response.status === 404) {
            return []; // Return an empty array if 404
          }
          console.error("Error fetching infrequent screenings:", error);
        }),
        getYearlyScreenings({
          year,
          consortium: "DOT",
          report: true,
        }).catch((error) => {
          if (error.response && error.response.status === 404) {
            return { drugScreenings: [], alcoholScreenings: [] }; // Return empty arrays if 404
          }
          console.error("Error fetching yearly screenings:", error);
        }),
        getAllEmployees(),
        getScreeningPercentages(),
      ]);

      const infrequentScreenings = infrequentScreeningsResponse;
      const { drugScreenings = [], alcoholScreenings = [] } =
        yearlyScreeningsResponse;
      const allEmployees = allEmployeesResponse;
      const percentages = percentagesResponse;

      // Now you have all the data, you can process it or pass it to another function
      const reportData = processReportData(
        year,
        infrequentScreenings,
        drugScreenings,
        alcoholScreenings,
        allEmployees,
        percentages
      );

      getAnnualScreeningsReport(reportData);
    } catch (error) {
      console.error("Error fetching report data:", error);
      // Handle the error appropriately
    }
  };

  const processReportData = (
    year,
    infrequentScreenings,
    drugScreenings,
    alcoholScreenings,
    allEmployees,
    percentages
  ) => {
    // Filter and count employees
    const activeEmployees = allEmployees.filter(
      (employee) => employee.isHidden !== true && employee.isHidden !== "true"
    );
    const totalDrivers = countDrivers(activeEmployees);

    // Calculate required tests
    const randomDrugTestsRequired = Math.ceil(
      totalDrivers * percentages[0].drugPercentage
    );
    const randomAlcoholTestsRequired = Math.ceil(
      totalDrivers * percentages[0].alcoholPercentage
    );

    // Process infrequent screenings
    const infrequentScreeningsReport =
      processInfrequentScreenings(infrequentScreenings);

    // Process drug and alcohol screenings
    const drugScreeningsReport = processDrugScreenings(
      drugScreenings,
      randomDrugTestsRequired
    );
    const alcoholScreeningsReport = processAlcoholScreenings(
      alcoholScreenings,
      randomAlcoholTestsRequired
    );

    // Combine all reports
    const finalReport = {
      year,
      totalDrivers,
      infrequentScreenings: infrequentScreeningsReport,
      drugScreenings: drugScreeningsReport,
      alcoholScreenings: alcoholScreeningsReport,
    };
    return finalReport;
  };

  const processInfrequentScreenings = (screenings) => {
    const createDefaultScreeningReport = () => ({
      drug: {
        totalTests: 0,
        totalPassed: 0,
        totalFailed: 0,
        totalReasonsFailed: {},
      },
      alcohol: { totalTests: 0, totalPassed: 0, totalFailed: 0 },
    });

    const report = {
      preEmployment: createDefaultScreeningReport(),
      postAccident: createDefaultScreeningReport(),
      reasonableSuspicion: createDefaultScreeningReport(),
      returnToDuty: createDefaultScreeningReport(),
      followUp: createDefaultScreeningReport(),
    };

    if (!screenings || screenings.length === 0) {
      return report;
    }

    screenings.forEach((screening) => {
      const reason = screening.screeningReason
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/-./g, (match) => match[1].toUpperCase());
      const type = screening.screeningType.toLowerCase();

      if (report[reason]) {
        report[reason][type].totalTests++;

        if (screening.selectedEmployee.status === "passed") {
          report[reason][type].totalPassed++;
        } else if (screening.selectedEmployee.status === "failed") {
          report[reason][type].totalFailed++;
          if (type === "drug" && screening.selectedEmployee.failureReason) {
            const failureReason = screening.selectedEmployee.failureReason;
            report[reason][type].totalReasonsFailed[failureReason] =
              (report[reason][type].totalReasonsFailed[failureReason] || 0) + 1;
          }
        }
      }
    });

    // Convert totalReasonsFailed to string format
    Object.keys(report).forEach((reason) => {
      const drugReport = report[reason].drug;
      drugReport.totalReasonsFailed = Object.entries(
        drugReport.totalReasonsFailed
      )
        .map(([reason, count]) => `${reason} - ${count}`)
        .join(", ");
    });

    return report;
  };

  const processDrugScreenings = (screenings, requiredTests) => {
    const report = {
      yearlyTestsRequired: requiredTests,
      yearlyTestsPerformed: 0,
      totalPassed: 0,
      totalFailed: 0,
      totalRefused: 0,
      totalReasonsFailed: "",
    };

    const failureReasons = {
      Amphetamine: 0,
      Barbiturate: 0,
      Benzodiazepine: 0,
      Cannabis: 0,
      Cocaine: 0,
      Opioid: 0,
    };

    if (!screenings || screenings.length === 0) {
      return report;
    }

    screenings.forEach((screening) => {
      screening.selectedEmployees.forEach((employee) => {
        const status = employee.status.toLowerCase();
        if (
          [
            "selected",
            "passed",
            "failed",
            "wrong test",
            "refused",
            "not at company",
            "sick",
          ].includes(status)
        ) {
          report.yearlyTestsPerformed++;
        }
        if (status === "passed") {
          report.totalPassed++;
        } else if (status === "failed") {
          report.totalFailed++;
          if (employee.failureReason) {
            failureReasons[employee.failureReason]++;
          }
        } else if (status === "refused") {
          report.totalRefused++;
        }
      });
    });

    report.totalReasonsFailed = Object.entries(failureReasons)
      .filter(([_, count]) => count > 0)
      .map(([reason, count]) => `${reason} - ${count}`)
      .join(", ");

    return report;
  };

  const processAlcoholScreenings = (screenings, requiredTests) => {
    const report = {
      yearlyTestsRequired: requiredTests,
      yearlyTestsPerformed: 0,
      totalPassed: 0,
      totalFailed: 0,
      totalRefused: 0,
    };

    if (!screenings || screenings.length === 0) {
      return report;
    }

    screenings.forEach((screening) => {
      screening.selectedEmployees.forEach((employee) => {
        const status = employee.status.toLowerCase();
        if (
          [
            "selected",
            "passed",
            "failed",
            "wrong test",
            "refused",
            "not at company",
            "sick",
          ].includes(status)
        ) {
          report.yearlyTestsPerformed++;
        }
        if (status === "passed") {
          report.totalPassed++;
        } else if (status === "failed") {
          report.totalFailed++;
        } else if (status === "refused") {
          report.totalRefused++;
        }
      });
    });

    return report;
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <ClearIcon />
          </IconButton>
          <div className="inputContainer">
            <h2 style={{ textAlign: "center" }}>Annual Screenings Report</h2>
            <div className="formScreenRow">
              <div className="formScreenCell">Year</div>
              <TextField
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                onChange={handleChange}
                name="year"
                value={year}
              />
            </div>
          </div>

          <div id="deleteOrCancel">
            <div>
              <PrintOrCancel
                onClick={handleCancelOrPrint}
                name="cancel"
                variant="contained"
              >
                Cancel
              </PrintOrCancel>
              <PrintOrCancel
                onClick={handleCancelOrPrint}
                name="print"
                variant="contained"
              >
                Print
              </PrintOrCancel>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
