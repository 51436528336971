import React, { useRef } from "react";
import NavigationBar from "../components/NavigationBar";
import SearchBar from "../components/EmployeeSearchBar";
import EmployeesTable from "../components/employees/employeesTable";
import AvatarDropdown from "../components/AvatarDropdown";
import { emptyEmployee } from "../components/BoilerplateObjects";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

// Modal Imports
import AddUserModal from "../components/modals/AddUserModal";
import AddEmployeeModal from "../components/modals/AddEmployeeModal";
import ReassignEmployeeModal from "../components/modals/ReassignEmployeeModal";
import DeleteEmployeeModal from "../components/modals/DeleteEmployeeModal";

import "./home.css";

const Employees = () => {
  const navigate = useNavigate();

  // State for AddUserModal
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  // State for Open/Closing AddEmployeeModal
  const [isAddEmployeeOpen, setIsAddEmployeeOpen] = React.useState(false);
  // State for Open/Closing ReassignEmployeeModal
  const [isReassignEmployeeOpen, setIsReassignEmployeeOpen] =
    React.useState(false);
  // State for Open/Closing DeleteEmployeeModal
  const [isDeleteEmployeeOpen, setIsDeleteEmployeeOpen] = React.useState(false);

  // State for Employee Search Bar (SearchBar.js)
  const [allEmployees, setAllEmployees] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null);

  // State to manage alert visibility
  const [alertOpen, setAlertOpen] = React.useState(false);

  const handleAlertSuccess = () => {
    setAlertOpen(true);
  };

  const AssignOrDelete = useRef(emptyEmployee);

  const handleButtonOpen = (e, params) => {
    if (e === "add") {
      setIsAddEmployeeOpen(true);
    } else if (e === "reassign") {
      setIsReassignEmployeeOpen(true);
      AssignOrDelete.current = params.row;
    } else if (e === "delete") {
      setIsDeleteEmployeeOpen(true);
      AssignOrDelete.current = params.row;
    }
  };

  const handleButtonClose = () => {
    setIsAddEmployeeOpen(false);
    setIsReassignEmployeeOpen(false);
    setIsDeleteEmployeeOpen(false);
    AssignOrDelete.current = emptyEmployee;
  };

  return (
    <div className="home">
      <div className="headerContainer">
        <div id="linscot">
          <h2
            onClick={() => {
              navigate("/home");
            }}
          >
            Linscot Pulse
          </h2>
        </div>
        <div id="userInfo">
          <AvatarDropdown handleOpen={handleOpen} handleClose={handleClose} />
        </div>
      </div>
      <div className="menuContainer">
        <div id="navBar">
          <NavigationBar />
        </div>
        <div id="search">
          <SearchBar
            allEmployees={allEmployees}
            setSelectedValue={setSelectedValue}
          />
        </div>
      </div>
      <div className="tableContainer">
        <div id="table">
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={() => setAlertOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "left" }} // Position at top left
          >
            <Alert
              onClose={() => setAlertOpen(false)}
              severity="success"
              sx={{
                width: "300px",
                height: "50px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Success - Please refresh for updated data!
            </Alert>
          </Snackbar>

          <EmployeesTable
            handleButtonOpen={handleButtonOpen}
            setAllEmployees={setAllEmployees}
            selectedValue={selectedValue}
            handleAlertSuccess={handleAlertSuccess}
          />
        </div>
      </div>
      <AddUserModal
        isOpen={isOpen}
        handleClose={handleClose}
        handleAlertSuccess={handleAlertSuccess}
      />
      <AddEmployeeModal
        isAddEmployeeOpen={isAddEmployeeOpen}
        handleAddButtonClose={handleButtonClose}
        handleAlertSuccess={handleAlertSuccess}
      />
      <ReassignEmployeeModal
        isOpen={isReassignEmployeeOpen}
        handleClose={handleButtonClose}
        employeeRef={AssignOrDelete}
      />
      <DeleteEmployeeModal
        isOpen={isDeleteEmployeeOpen}
        handleClose={handleButtonClose}
        employeeRef={AssignOrDelete}
        handleAlertSuccess={handleAlertSuccess}
      />
    </div>
  );
};

export default Employees;
