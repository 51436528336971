import React from "react";
import { randomSelections } from "../../functions/HelperFunctions";
import { getAllEmployees } from "../axios/Employees";
import { getScreeningPercentages } from "../axios/Screenings";
import "./AddUserModal.css";
import "./DeleteEmployeeModal.css";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { saveScreenings } from "../axios/Screenings";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "25%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
};

const DeleteOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

export default function AddScreeningModal({
  isOpen,
  handleClose,
  consortium,
  year,
  quarter,
  handleAlert,
}) {
  const handleCancelOrSave = async (e) => {
    if (e.target.name === "cancel") {
      handleClose();
    } else {
      try {
        const data = await getAllEmployees();
        const percentages = await getScreeningPercentages();
        const selections = randomSelections({
          data,
          consortium,
          year,
          quarter,
          percentages,
        });

        const savedScreenings = await saveScreenings(selections);
        handleClose();

        if (Array.isArray(savedScreenings) && savedScreenings.length > 0) {
          handleAlert(
            `Successfully added ${savedScreenings.length} screening(s).`
          );
        } else {
          handleAlert("No new screenings were added.");
        }
      } catch (error) {
        console.error("Error adding screenings:", error);
        handleAlert("Error adding screenings. Please try again.", "error");
      }
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <ClearIcon />
          </IconButton>
          <div className="inputContainer">
            <div className="formRow">
              <div className="deleteCell">
                <h1>
                  No selection has been made for this quarter. Would you like to
                  make a new selection?
                </h1>
              </div>
            </div>
          </div>

          <div id="deleteOrCancel">
            <div>
              <DeleteOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e);
                }}
                name="cancel"
                variant="contained"
              >
                Cancel
              </DeleteOrCancel>
              <DeleteOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e);
                }}
                name="generate"
                variant="contained"
              >
                Generate
              </DeleteOrCancel>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
