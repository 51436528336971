import api from "./BaseUrl";
import { updatedByInitials } from "./UserSession";

// Rewrite to exclude mapping within the request
export const getCompanyTrainings = async ({
  companyId,
  setTrainings,
  mapTrainings,
  setRows,
}) => {
  try {
    const response = await api.get(`/trainings/${companyId}`);
    // Check if the data is not null or undefined before using it
    if (response.data) {
      setTrainings(response.data);
      mapTrainings(response.data, setRows);
    } else {
      // If no data is returned, handle it gracefully
      console.warn("No trainings returned for the specified company.");
      setTrainings([]);
      mapTrainings([], setRows);
    }
  } catch (error) {
    // Handle the 400 status code and any other errors by returning empty data
    console.error("Error getting training for the company:", error.message);
    setTrainings([]);
    mapTrainings([], setRows);
  }
};

export const updateTraining = async ({ training }) => {
  try {
    await api
      .post(`/trainings/update/${training.trainingId}`, {
        attendees: training.attendees,
        companyName: training.companyName,
        courses: training.courses,
        courseTitle: training.title,
        instructor: training.instructor,
        isHidden: training.isHidden,
        trainingDate: training.date,
        updatedBy: updatedByInitials,
      })
      .then(function (response) {
        if (response.status === 200) {
          if (training.isDetailsOpen !== undefined) {
            training.isDetailsOpen(false);
          }
          if (training.isHidden === true || training.isHidden === "true") {
            return null;
          }
          return response.data;
        }
      });
  } catch (error) {
    console.error("Error updating trainings:", error);
  }
};

export const createTraining = async (training) => {
  training.createdBy = updatedByInitials;
  try {
    await api.post(`/trainings/`, training);
  } catch (error) {
    console.error("Error creating new training: ", error);
  }
};

export const getAllTrainings = async () => {
  try {
    const allTrainings = await api.get(`/trainings/`);
    return allTrainings.data;
  } catch (error) {
    console.error("Error getting all trainings: ", error);
  }
};
