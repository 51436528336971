import React, { useState, useEffect, useRef } from "react";
import TrainingsToolbar from "./trainingsToolbar";
import { emptyEmployee } from "../BoilerplateObjects";
import { mapAllTrainings, mapAllInstructors } from "./trainingsService";

// Axios Imports
import { getAllTrainings } from "../axios/Trainings";
import { getInstructors } from "../axios/Users";

// Material Imports
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { red } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

// Helper Functions
import {
  handleDetailsClick,
  handleChange,
  handleCancelOrSave,
} from "../../functions/HelperFunctions";

const NewTable = styled(DataGrid)({
  fontFamily: "Poppins",
  textTransform: "none",
  backgroundColor: "white",
});

function TrainingsTable({
  handleAddButtonOpen,
  handleDeleteButtonOpen,
  setAllTrainings,
  selectedValue,
  handleAlertSuccess,
}) {
  const trainingColumns = [
    {
      field: "title",
      headerName: "Title",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "date",
      headerName: "Training Date",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "companyName",
      headerName: "Company",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "instructor",
      headerName: "Instructor",
      width: 200,
      sortable: true,
    },
    {
      field: "isHidden",
      headerName: "Status",
      minWidth: 150,
      sortable: true,
      renderCell: (params) => {
        return <div>{params.value ? "Deleted" : "Active"}</div>;
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 60,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              sx={{ color: red[500] }}
              onClick={() => {
                handleDeleteButtonOpen("Training", params);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const instructorColumns = [
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "admin",
      headerName: "Administrator",
      width: 120,
      sortable: true,
    },
    {
      field: "instructor",
      headerName: "Instructor",
      width: 120,
      sortable: true,
    },
    {
      field: "delete",
      headerName: "",
      width: 60,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              sx={{ color: red[500] }}
              onClick={() => {
                handleDeleteButtonOpen("Instructor", params);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const [tab, setTab] = useState("list");
  const [rows, setRows] = useState([]);
  const [rowClicked, setRowClicked] = useState({});
  const [checked, setChecked] = useState({
    isHidden: false,
    isDriver: false,
    clearinghouse: false,
  });

  const values = useRef(emptyEmployee);

  const [detailsOpen, isDetailsOpen] = useState(false);

  const fetchTrainingsAndSave = async () => {
    try {
      const allTrainings = await getAllTrainings();
      setRows(mapAllTrainings(allTrainings));
      setAllTrainings(allTrainings);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInstructorsAndSave = async () => {
    try {
      const allInstructors = await getInstructors();
      setRows(mapAllInstructors(allInstructors));
    } catch (error) {
      console.error(error);
    }
  };

  const [selectedColumns, setSelectedColumns] = useState(trainingColumns);

  const updateTableForSearch = () => {
    if (selectedValue && typeof selectedValue === "object") {
      // Check if selectedValue is an object (selected from dropdown)
      const filteredTrainings = rows.filter(
        (training) =>
          `${training.companyName}` === `${selectedValue.companyName}`
      );
      setRows(filteredTrainings); // Map only the selected training
    } else if (typeof selectedValue === "string" && selectedValue !== "") {
      // If the user types in a value, filter by that value
      const filteredTrainings = rows.filter((training) =>
        `${training.companyName}`
          .toLowerCase()
          .includes(selectedValue.toLowerCase())
      );
      setRows(filteredTrainings); // Map the filtered trainings
    } else {
      // Show all trainings if no input or selection
      fetchTrainingsAndSave(); // Re-fetch and display all trainings
    }
  };

  useEffect(() => {
    fetchTrainingsAndSave();
  }, []);

  useEffect(() => {
    if (selectedValue === null) {
      fetchTrainingsAndSave();
    } else {
      updateTableForSearch();
    }
  }, [selectedValue]);

  const handleRowClick = (params) => {
    setRowClicked(Object.assign(rowClicked, params.row));
  };

  const tabClicked = (tab) => {
    setTab(tab);
    if (tab === "list") {
      setSelectedColumns(trainingColumns);
      fetchTrainingsAndSave();
    } else if (tab === "instructors") {
      setSelectedColumns(instructorColumns);
      fetchInstructorsAndSave();
    }
  };

  const refreshData = async () => {
    if (tab === "list") {
      await fetchTrainingsAndSave();
    } else if (tab === "instructors") {
      await fetchInstructorsAndSave();
    }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <NewTable
          disableRowSelectionOnClick
          rows={rows}
          columns={selectedColumns}
          onRowClick={handleRowClick}
          onRowDoubleClick={handleDetailsClick({
            isDetailsOpen,
            rowClicked,
            setChecked,
            values,
          })}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          slots={{
            toolbar: TrainingsToolbar,
            footer: () =>
              tab === "list" ? (
                <div style={{ display: "flex", direction: "row" }}>
                  <Box
                    sx={{
                      p: 2,
                      fontWeight: "bold",
                      width: "75%",
                    }}
                  >
                    Total Trainings: {rows.length}
                  </Box>
                  <GridPagination />
                </div>
              ) : (
                <GridPagination />
              ),
          }}
          slotProps={{
            toolbar: {
              tabClicked,
              checked,
              setChecked,
              rowClicked,
              tab,
              handleDetailsClick,
              isDetailsOpen,
              handleChange,
              handleCancelOrSave,
              handleAlertSuccess,
              detailsOpen,
              values,
              printOptions: {
                hideFooter: true,
                hideToolbar: true,
              },
              handleAddButtonOpen,
              refreshData,
            },
          }}
        />
      </div>
    </div>
  );
}

export default TrainingsTable;
