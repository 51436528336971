import React, { useState } from "react";
import "./AddUserModal.css";
import "./DeleteEmployeeModal.css";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "25%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
};

const StyledButton = styled(Button)({
  marginRight: "10px",
  fontFamily: "Poppins",
  textTransform: "none",
});

const steps = ["Download Report", "Copy Email Template", "Email Report"];

export default function EmailCompanyTrainingsModal({
  isOpen,
  handleClose,
  handleDownloadReport,
  rowClicked,
}) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleClose();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const copyToClipboard = () => {
    const template = `
Hi ${rowClicked?.contact},

Here is a copy of your most recent trainings report for all the employees at ${rowClicked?.companyName}. Please let us know if you have any questions. Thanks!
    `;
    navigator.clipboard.writeText(template).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  const generateMailToLink = () => {
    const subject = encodeURIComponent("Recent Trainings Report");
    return `mailto:${rowClicked.email}?subject=${subject}`;
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <IconButton onClick={handleClose} style={{ marginLeft: "95%" }}>
            <ClearIcon />
          </IconButton>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className="inputContainer">
            {activeStep === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "10px",
                }}
              >
                <div style={{ margin: "auto" }}>
                  Click to Download Reminders Report
                </div>
                <div style={{ margin: "auto" }}>
                  <StyledButton
                    style={{ color: "#21357B" }}
                    onClick={handleDownloadReport}
                  >
                    <CloudDownloadIcon />
                  </StyledButton>
                </div>
              </div>
            )}
            {activeStep === 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "10px",
                }}
              >
                <div style={{ margin: "auto" }}>
                  Click to Copy Email Template
                </div>
                <div style={{ margin: "auto" }}>
                  <StyledButton
                    style={{ color: "#21357B" }}
                    onClick={copyToClipboard}
                  >
                    <ContentCopyIcon />
                  </StyledButton>
                </div>
              </div>
            )}
            {activeStep === 2 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "10px",
                }}
              >
                <div style={{ margin: "auto" }}>
                  Send Email to {rowClicked?.contact} at{" "}
                  {rowClicked?.companyName}
                </div>
                <div style={{ margin: "auto" }}>
                  <StyledButton
                    style={{ color: "#21357B" }}
                    href={generateMailToLink()}
                  >
                    <ForwardToInboxIcon />
                  </StyledButton>
                </div>
              </div>
            )}
            <div id="saveOrCancel">
              <StyledButton
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="outlined"
                style={{
                  color: "#21357B",
                  backgroundColor: "#FFFFFF",
                  borderColor: "#21357B",
                }}
              >
                Back
              </StyledButton>
              <StyledButton
                variant="contained"
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#21357B",
                  borderColor: "#FFFFFF",
                }}
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? "Done" : "Next"}
              </StyledButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
