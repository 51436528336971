import { Navigate, Outlet } from "react-router-dom";
import { isAdmin } from "./axios/UserSession";

export const PrivateRoutes = () => {
  return sessionStorage["authenticated"] ? <Outlet /> : <Navigate to="/" />;
};

export const AdminRoutes = () => {
  return isAdmin ? <Outlet /> : <Navigate to="/home" />; 
}

export const PasswordRoute = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token"); // Retrieve the token from storage
  return token ? <Outlet /> : <Navigate to="/" />;
};
