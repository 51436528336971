import React from "react";
import { deleteInfrequentScreening } from "../axios/Screenings";
import "./AddUserModal.css";
import "./DeleteEmployeeModal.css";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "25%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
};

const DeleteOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

export default function DeleteInfrequentScreeningModal({
  isOpen,
  handleClose,
  rowClicked,
  handleAlert,
}) {
  const handleCancelOrSave = async (e) => {
    if (e.target.name === "cancel") {
      handleClose();
    } else {
      try {
        await deleteInfrequentScreening(rowClicked);
        handleClose();
        handleAlert("Infrequent screening deleted successfully!");
      } catch (error) {
        console.error("Error deleting infrequent screening:", error);
        handleAlert(
          "Error deleting infrequent screening. Please try again.",
          "error"
        );
      }
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <ClearIcon />
          </IconButton>
          <div className="inputContainer">
            <div className="deleteRow">
              <div className="deleteCell">
                <h2>Are you sure you want to delete this screening?</h2>
                <div>
                  Employee Name:{" "}
                  {rowClicked.selectedEmployee
                    ? rowClicked.selectedEmployee.lastName
                    : ""}
                  ,{" "}
                  {rowClicked.selectedEmployee
                    ? rowClicked.selectedEmployee.firstName
                    : ""}
                </div>
                <div>
                  Company Name:{" "}
                  {rowClicked.selectedEmployee
                    ? rowClicked.selectedEmployee.companyName
                    : ""}
                </div>
                <div>
                  Type:{" "}
                  {rowClicked.selectedEmployee ? rowClicked.screeningType : ""}
                </div>
                <div>
                  Reason:{" "}
                  {rowClicked.selectedEmployee
                    ? rowClicked.screeningReason
                    : ""}
                </div>
                <div>
                  Date:{" "}
                  {rowClicked.selectedEmployee
                    ? dayjs(rowClicked.screeningDate)
                        .tz("US/Central")
                        .format("MM/DD/YYYY")
                    : ""}
                </div>
              </div>
            </div>
          </div>

          <div id="deleteOrCancel">
            <div>
              <DeleteOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e);
                }}
                name="cancel"
                variant="contained"
              >
                Cancel
              </DeleteOrCancel>
              <DeleteOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e);
                }}
                name="delete"
                variant="contained"
              >
                Delete
              </DeleteOrCancel>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
