import React from "react";
import { updateCompany } from "../axios/Companies";
import { updateAllRemindersForCompany } from "../axios/Reminders";
import "./AddUserModal.css";
import "./DeleteEmployeeModal.css";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "25%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
};

const DeleteOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

export default function DeleteCompanyModal({
  isOpen,
  handleClose,
  companyRef,
  handleAlertSuccess,
}) {
  const handleCancelOrSave = async (e) => {
    if (e.target.name === "cancel") {
      handleClose();
      return;
    }
    try {
      companyRef.current.isHidden = true;
      const deletedCompany = await updateCompany({
        company: companyRef.current,
      });
      if (deletedCompany === null || deletedCompany === undefined) {
        const updatedCompanyReminders = await updateAllRemindersForCompany({
          companyId: companyRef.current.companyId,
        });
        if (
          updatedCompanyReminders === null ||
          updatedCompanyReminders === undefined
        ) {
          handleAlertSuccess();
        }
      }
    } catch (error) {
      console.error("Error deleting company:", error);
    } finally {
      handleClose();
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <ClearIcon />
          </IconButton>
          <div className="inputContainer">
            <div className="formRow">
              <div className="deleteCell">
                <h1>
                  Are you sure you want to delete{" "}
                  {companyRef.current.companyName}?
                </h1>
              </div>
            </div>
          </div>

          <div id="deleteOrCancel">
            <div>
              <DeleteOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e);
                }}
                name="cancel"
                variant="contained"
              >
                Cancel
              </DeleteOrCancel>
              <DeleteOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e);
                }}
                name="delete"
                variant="contained"
              >
                Delete
              </DeleteOrCancel>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
