import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";

import { Button, styled, TextField } from "@mui/material";
import rhino from "./loginRhino.svg";
import "./login.css";
import { fetchUser, sendPWResetLink } from "../components/axios/Users";

const MainButton = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  textTransform: "none",
});

const SecondaryButton = styled(Button)({
  fontFamily: "Poppins",
  textTransform: "none",
});

const LoginCard = styled(Card)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignContent: "space-between",
});

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState("");
  const [pw, setPw] = useState("");
  const [forgot, hasForgot] = useState(false);
  const [alert, setAlert] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const userInputRef = useRef(null);
  const pwInputRef = useRef(null);
  const loginButtonRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        loginButtonRef.current.click();
      }
    };

    const userInput = userInputRef.current;
    const pwInput = pwInputRef.current;

    userInput.addEventListener("keypress", handleKeyPress);
    pwInput.addEventListener("keypress", handleKeyPress);

    return () => {
      userInput.removeEventListener("keypress", handleKeyPress);
      pwInput.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleUser = (e) => {
    setUser(e.target.value);
  };

  const handlePw = (e) => {
    setPw(e.target.value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  document.body.style.overflow = "hidden";
  return (
    <div className="login">
      <div className="test">
        <LoginCard>
          <div id="linscot">
            <h2 id="linscot">Linscot Pulse</h2>
          </div>
          {alert ? (
            <Alert
              sx={{ display: "flex", justifyContent: "center" }}
              severity="info"
              onClose={() => {
                setAlert(false);
              }}
            >
              Email has been sent if this account exists! Please check your
              inbox
            </Alert>
          ) : (
            <></>
          )}
          {forgot ? (
            <Fragment>
              <div className="formInput">
                <div className="formItem passwordItemTop">
                  <div>
                    Enter your username to reset your password. <br /> Please
                    check your inbox after clicking send
                  </div>
                </div>
                <div className="formItem formItemBottom">
                  <TextField
                    id="outlined-basic"
                    label="Username"
                    variant="outlined"
                    onChange={(e) => {
                      handleUser(e);
                    }}
                  />
                </div>
              </div>
              <div className="loginButtonContainer">
                <MainButton
                  size="large"
                  variant="contained"
                  onClick={() => {
                    sendPWResetLink({ user, setAlert });
                  }}
                >
                  Send
                </MainButton>
                <SecondaryButton size="small" onClick={() => hasForgot(false)}>
                  Go Back
                </SecondaryButton>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="formInput">
                <div className="formItem formItemTop">
                  <TextField
                    error={usernameError}
                    helperText={usernameError ? "Incorrect username" : ""}
                    id="userInput"
                    label="Username"
                    variant="outlined"
                    inputRef={userInputRef}
                    onChange={(e) => {
                      handleUser(e);
                      setUsernameError(false); // Reset error on change
                    }}
                  />
                </div>
                <div className="formItem formItemBottom">
                  <FormControl
                    error={passwordError}
                    sx={{ m: 1, width: "200px" }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      error={passwordError}
                      onChange={(e) => {
                        handlePw(e);
                        setPasswordError(false); // Reset error on change
                      }}
                      id="password"
                      type={showPassword ? "text" : "password"}
                      inputRef={pwInputRef}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </div>
              </div>
              <div className="loginButtonContainer">
                <MainButton
                  id="login"
                  size="large"
                  variant="contained"
                  ref={loginButtonRef}
                  onClick={() => {
                    fetchUser({
                      user,
                      pw,
                      navigate,
                      setError: (errorType) => {
                        setUsernameError(
                          errorType === "username" || errorType === "both"
                        );
                        setPasswordError(
                          errorType === "password" || errorType === "both"
                        );
                      },
                    });
                  }}
                >
                  Login
                </MainButton>

                <SecondaryButton size="small" onClick={() => hasForgot(true)}>
                  Forgot Password?
                </SecondaryButton>
              </div>
            </Fragment>
          )}
        </LoginCard>
      </div>
      <div>
        <img src={rhino} className="loginBackground" alt="logo" />
      </div>
    </div>
  );
};

export default Login;
