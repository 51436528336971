import React, { useState, useEffect, useCallback } from "react";
import NavigationBar from "../components/NavigationBar";
import RemindersTable from "../components/reminders/remindersTable";
import AvatarDropdown from "../components/AvatarDropdown";
import {
  saveRemindersFromTimeFrame,
  updateReminders,
} from "../components/axios/Reminders";
import { fetchCompanies } from "../components/axios/Companies";
import { getRemindersReport } from "../components/axios/Reports";
import { groupEmployeesByCompany } from "../functions/HelperFunctions";

// Material Imports
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Alert, Snackbar } from "@mui/material";

dayjs.extend(utc);
dayjs.extend(timezone);

const TableMenuButton = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  color: "white",
  margin: "5px",
  textTransform: "none",
  width: "110px",
  ":hover": {
    opacity: ".8",
    backgroundColor: "#21357B",
  },
});

const textFieldStyle = {
  fontFamily: "Poppins",
  marginTop: ".4rem",
  marginBottom: ".4rem",
  width: "220px",
};

const today = dayjs().tz("US/Central");
const future = dayjs(today).add(15, "days");
// Define filter options for each tab
const employeeFilterOptions = ["DMV", "CDL", "HAZMAT", "PHYS", "HME", "CLRHSE"];
const companyFilterOptions = ["USDOT", "UCR", "RIN", "2290", "CAB", "USHAZ"];

let allCompanies = [];

const Reminders = () => {
  const [selectedRecord, setSelectedRecord] = useState("Current");
  const [selectedTab, setSelectedTab] = useState("Employee");
  const [currentDate, setCurrentDate] = useState(today);
  const [days, setDays] = useState(15);
  const [futureDate, setFutureDate] = useState(future);
  const [selectedReminderTypes, setSelectedReminderTypes] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [filteredReminders, setFilteredReminders] = useState([]);
  const [currentFilterOptions, setCurrentFilterOptions] = useState(
    employeeFilterOptions
  );
  const [isCommitting, setIsCommitting] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [asyncRefresh, setAsyncRefresh] = useState(null);
  const [showRefreshAlert, setShowRefreshAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCompaniesAndSave = async () => {
    try {
      const companies = await fetchCompanies();
      allCompanies = companies;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCompaniesAndSave();
  }, []);

  useEffect(() => {
    if (reminders && reminders.length) {
      const filterRemindersByType = (selectedTypes) => {
        if (selectedTypes.length === 0) {
          return reminders; // Return all reminders if no filter is selected
        }
        return reminders.filter((reminder) =>
          selectedTypes.includes(reminder.reminderType)
        );
      };
      const filtered = filterRemindersByType(selectedReminderTypes);
      setFilteredReminders(filtered);
    }
  }, [selectedReminderTypes, reminders]);

  const handleDateChange = (e) => {
    let newDay = dayjs(e.$d).tz("US/Central");
    let newFutureDay = dayjs(newDay).add(days, "days");
    setCurrentDate(newDay);
    setFutureDate(newFutureDay);
  };

  const handleDaysAheadChange = (e) => {
    let timeDifference = e.target.value;
    let newFutureDay = dayjs(currentDate).add(timeDifference, "days");
    setDays(timeDifference);
    setFutureDate(newFutureDay);
  };

  const handleRecordsChange = (event) => {
    setSelectedRecord(event.target.value);
  };

  const handleSearch = async () => {
    // When handling a search, look at current tab and add the scope "emp" or "comp" with this request
    const data = await saveRemindersFromTimeFrame({
      startDate: dayjs(currentDate).unix(),
      endDate: dayjs(futureDate).unix(),
      scope: selectedTab,
    });
    setReminders(data);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    // Case when "ALL" checkbox is clicked
    if (name === "all") {
      if (checked) {
        // If "All" is checked, select all reminder types (including "ALL")
        setSelectedReminderTypes(["all", ...currentFilterOptions]);
      } else {
        // If "All" is unchecked, clear the selection (including "ALL")
        setSelectedReminderTypes([]);
      }
    } else {
      // Case when any other checkbox is clicked
      if (checked) {
        const newSelections = [
          ...selectedReminderTypes.filter((type) => type !== "all"),
          name,
        ];
        // Check if all checkboxes are selected, if so, add "ALL" to the selection
        if (newSelections.length === currentFilterOptions.length) {
          setSelectedReminderTypes(["all", ...newSelections]);
        } else {
          setSelectedReminderTypes(newSelections);
        }
      } else {
        // Remove the unchecked selection
        setSelectedReminderTypes(
          selectedReminderTypes.filter((type) => type !== name)
        );
      }
    }
  };

  const handleAlert = (message, severity = "success") => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
    setShowRefreshAlert(true);
  };

  const refreshData = useCallback(async () => {
    setIsLoading(true);
    try {
      await handleSearch();
      if (showRefreshAlert) {
        handleAlert("Data refreshed successfully!");
        setShowRefreshAlert(false);
      }
    } catch (error) {
      console.error("Error refreshing data:", error);
      handleAlert("Error refreshing data. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  }, [currentDate, futureDate, selectedTab, selectedReminderTypes]);

  useEffect(() => {
    setAsyncRefresh(() => refreshData);
  }, [refreshData]);

  const renderFilterCheckboxes = () => (
    <>
      <FormControlLabel
        control={
          <Checkbox
            sx={{ "&.Mui-checked": { color: "#21357B" } }}
            name="all"
            onChange={handleCheckboxChange}
            checked={selectedReminderTypes.includes("all")}
          />
        }
        label="ALL"
      />
      {currentFilterOptions.map((option) => (
        <FormControlLabel
          key={option}
          control={
            <Checkbox
              sx={{ "&.Mui-checked": { color: "#21357B" } }}
              name={option}
              onChange={handleCheckboxChange}
              checked={selectedReminderTypes.includes(option)}
            />
          }
          label={option}
        />
      ))}
    </>
  );

  const tabClicked = (tab) => {
    setSelectedTab(tab);
    setReminders([]);
    setFilteredReminders([]);
    // Update current filter options based on the selected tab
    if (tab === "Employee") {
      setCurrentFilterOptions(employeeFilterOptions);
    } else if (tab === "Company") {
      setCurrentFilterOptions(companyFilterOptions);
    }
  };

  const handleDownloadReport = () => {
    const reportData = {
      companies: groupEmployeesByCompany(filteredReminders, allCompanies),
    };
    getRemindersReport(reportData);
  };

  // Function to handle the Commit button click
  const handleCommitClick = async () => {
    setIsCommitting(true);
    const reminderTypeDurations = {
      DMV: 1,
      CDL: 5,
      HAZMAT: 3,
      PHYS: 2,
      HME: 5,
      CLRHSE: 1,
      USDOT: 2,
      UCR: 1,
      RIN: 5,
      2290: 1,
      CAB: 2,
      USHAZ: false, // Handle dynamically
    };

    try {
      // Iterate through all filtered reminders and update dueDate and completedDate
      for (const reminder of filteredReminders) {
        let newCompletedDate = dayjs(reminder.dueDate).tz("US/Central");
        let newDueDate;

        // Handle dynamic USHAZ reminderType
        if (reminder.reminderType === "USHAZ") {
          const hazmatDuration = reminder.hazmatRegistrationInterval || 1; // Default to 1 year if undefined
          newDueDate = newCompletedDate.add(hazmatDuration, "years");
        } else {
          // Handle static reminderType durations
          const duration = reminderTypeDurations[reminder.reminderType];
          newDueDate = newCompletedDate.add(duration, "years");
        }

        // Update the reminder with the new dueDate and completedDate
        await updateReminders(reminder._id, {
          ...reminder,
          completedDate: newCompletedDate.toISOString(),
          dueDate: newDueDate.toISOString(),
        });
      }

      // Refresh the reminders list after updating
      await handleSearch();
      // Provide user feedback
      handleAlert("Reminders have been successfully committed.");
    } catch (error) {
      console.error("Error committing reminders: ", error);
      handleAlert(
        "An error occurred while committing reminders. Please try again.",
        "error"
      );
    } finally {
      setIsCommitting(false);
    }
  };

  return (
    <div className="home">
      <div className="headerContainer">
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={() => {
            setAlertOpen(false);
            if (showRefreshAlert && typeof asyncRefresh === "function") {
              asyncRefresh();
            }
          }}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <Alert
            onClose={() => setAlertOpen(false)}
            severity={alertSeverity}
            sx={{
              width: "300px",
              height: "50px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <div id="linscot">
          <h2>Linscot Pulse</h2>
        </div>
        <div id="userInfo">
          <AvatarDropdown />
        </div>
      </div>
      <div className="menuContainer">
        <div id="navBar">
          <NavigationBar />
        </div>
      </div>
      <div className="tableContainer">
        <div id="operationsTable">
          <div id="tableMenuColumn">
            <div className="tableMenuStart">Reminder Criteria</div>
            <div className="tableMenuRow">
              Start Date
              <LocalizationProvider
                className="localProvider"
                dateAdapter={AdapterDayjs}
              >
                <div className="customDatePickerWidth">
                  <DatePicker
                    name="startDate"
                    value={currentDate}
                    textField={(params) => <TextField {...params} />}
                    sx={{ width: "220px" }}
                    slotProps={{
                      textField: { size: "small" },
                    }}
                    onChange={(e) => {
                      handleDateChange(e);
                    }}
                  />
                </div>
              </LocalizationProvider>
            </div>
            <div className="tableMenuRow">
              Days Ahead
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                defaultValue={days}
                onChange={(e) => {
                  handleDaysAheadChange(e);
                }}
                name="daysAhead"
              />
            </div>
            <div className="tableMenuRow">
              End Date
              <LocalizationProvider
                className="localProvider"
                dateAdapter={AdapterDayjs}
              >
                <div className="customDatePickerWidth">
                  <DatePicker
                    disabled
                    value={futureDate}
                    name="endDate"
                    textField={(params) => <TextField {...params} />}
                    sx={{ width: "220px" }}
                    slotProps={{
                      textField: { size: "small" },
                    }}
                  />
                </div>
              </LocalizationProvider>
            </div>
            <div className="tableMenuRow">
              Record Set
              <Select
                size="small"
                fullWidth
                name="recordSet"
                sx={textFieldStyle}
                value={selectedRecord}
                onChange={handleRecordsChange}
              >
                <MenuItem value={"Current"}>Current</MenuItem>
                <MenuItem value={"Archived"}>Archived</MenuItem>
              </Select>
            </div>
            <div className="tableMenuRow">
              Filters
              <FormGroup>{renderFilterCheckboxes()}</FormGroup>
            </div>
            <div className="tableMenuButton">
              <TableMenuButton onClick={handleSearch}>Search</TableMenuButton>
            </div>
            <div className="tableMenuButton">
              <TableMenuButton onClick={handleDownloadReport}>
                Show Report
              </TableMenuButton>
            </div>
          </div>
          <RemindersTable
            rows={filteredReminders}
            tabClicked={tabClicked}
            tab={selectedTab}
            isCommitting={isCommitting}
            handleCommitClick={handleCommitClick}
            handleAlert={handleAlert}
            asyncRefresh={asyncRefresh}
          />
        </div>
      </div>
    </div>
  );
};

export default Reminders;
