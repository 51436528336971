import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import { Button, styled } from "@mui/material";
import rhino from "./loginRhino.svg";
import "./login.css";
import { updateUserPassword } from "../components/axios/Users";

const MainButton = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  textTransform: "none",
});

const ResetCard = styled(Card)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "20px",
  boxSizing: "border-box",
});

const Reset = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const email = urlParams.get("email"); // Retrieve the email from url

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState(email);
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");
  const [error, setError] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handlePw1 = (e) => {
    setPw1(e.target.value);
  };

  const handlePw2 = (e) => {
    setPw2(e.target.value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  document.body.style.overflow = "hidden";
  return (
    <div className="login">
      <div className="test">
        <ResetCard>
          <div id="linscot">
            <h2 id="linscot">Linscot Pulse</h2>
          </div>
          <div className="formInput">
            <div className="formItem passwordItemTop">
              <div>
                Please enter and confirm your new password <br />
                associated with {email}
              </div>
            </div>
            <div className="resetItem formItemBottom">
              <FormControl sx={{ m: 1, width: "200px" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  error={error}
                  onChange={(e) => {
                    handlePw1(e);
                    if (error) {
                      setError(false);
                    }
                  }}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "200px" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  error={error}
                  onChange={(e) => {
                    handlePw2(e);
                    if (error) {
                      setError(false);
                    }
                  }}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>
          </div>
          <div className="loginButtonContainer">
            <MainButton
              size="large"
              variant="contained"
              fullWidth
              onClick={() => {
                if (pw1 === pw2) {
                  updateUserPassword({ user, pw1, navigate });
                } else {
                  setError(true);
                }
              }}
            >
              Save
            </MainButton>
          </div>
        </ResetCard>
      </div>
      <div className="loginBackgroundContainer">
        <img src={rhino} className="loginBackground" alt="logo" />
      </div>
    </div>
  );
};

export default Reset;
