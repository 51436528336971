import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./SearchBar.css";

const CompanySearchBar = ({
  allCompanies,
  setSelectedValue,
  selectedValue,
  isAdmin,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleSelectionChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      disabled={!isAdmin}
      id="search-autocomplete"
      size="small"
      freeSolo
      options={allCompanies}
      inputValue={inputValue}
      getOptionLabel={(option) => option?.companyName || inputValue}
      renderInput={(params) => <TextField {...params} label="Search" />}
      onChange={handleSelectionChange}
      onInputChange={handleInputChange}
    />
  );
};

export default CompanySearchBar;
