import React, { useRef, useState } from "react";
import NavigationBar from "../components/NavigationBar";
import CompanySearchBar from "../components/CompanySearchBar";
import Table from "../components/home/homeTable";
import AvatarDropdown from "../components/AvatarDropdown";
import {
  emptyCompany,
  emptyEmployee,
  emptyTraining,
} from "../components/BoilerplateObjects";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

// Modal Imports
import AddUserModal from "../components/modals/AddUserModal";
import AddCompanyModal from "../components/modals/AddCompanyModal";
import AddEmployeeModal from "../components/modals/AddEmployeeModal";
import AddTrainingModal from "../components/modals/AddTrainingModal";
import DeleteCompanyModal from "../components/modals/DeleteCompanyModal";
import DeleteEmployeeModal from "../components/modals/DeleteEmployeeModal";
import DeleteTrainingModal from "../components/modals/DeleteTrainingModal";

import "./home.css";
import { isAdmin } from "../components/axios/UserSession";

const Home = () => {
  const navigate = useNavigate();

  // State for AddUserModal
  const [isOpen, setIsOpen] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState();

  // State for Company Search Bar
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  // State for AddCompanyModal
  const [isAddCompanyOpen, setIsAddCompanyOpen] = useState(false);
  // State for AddEmployeeModal
  const [isAddEmployeeOpen, setIsAddEmployeeOpen] = useState(false);
  // State for AddTrainingModal
  const [isAddTrainingOpen, setIsAddTrainingOpen] = useState(false);
  // State for DeleteCompanyModal
  const [isDeleteCompanyOpen, setIsDeleteCompanyOpen] = useState(false);
  // State for DeleteEmployeeModal
  const [isDeleteEmployeeOpen, setIsDeleteEmployeeOpen] = useState(false);
  // State for DeleteTrainingModal
  const [isDeleteTrainingOpen, setIsDeleteTrainingOpen] = useState(false);
  // State to manage alert visibility
  const [alertOpen, setAlertOpen] = useState(false);
  // State for refresh functionality
  const [asyncRefresh, setAsyncRefresh] = useState();

  const handleAlertSuccess = () => {
    setAlertOpen(true);
  };

  const handleAddButtonOpen = (e) => {
    if (e.target.name === "Company") {
      setIsAddCompanyOpen(true);
    } else if (e.target.name === "Employee") {
      setIsAddEmployeeOpen(true);
    } else if (e.target.name === "Training") {
      setIsAddTrainingOpen(true);
    }
  };

  const handleAddButtonClose = () => {
    setIsAddCompanyOpen(false);
    setIsAddEmployeeOpen(false);
    setIsAddTrainingOpen(false);
  };

  const handleDeleteButtonOpen = (e, params) => {
    if (e === "Company") {
      setIsDeleteCompanyOpen(true);
      companyRef.current = params.row;
    } else if (e === "Employee") {
      setIsDeleteEmployeeOpen(true);
      employeeRef.current = params.row;
    } else if (e === "Training") {
      setIsDeleteTrainingOpen(true);
      trainingRef.current = params.row;
    }
  };

  const handleDeleteButtonClose = (e) => {
    setIsDeleteCompanyOpen(false);
    setIsDeleteEmployeeOpen(false);
    setIsDeleteTrainingOpen(false);
    companyRef.current = emptyCompany;
    employeeRef.current = emptyEmployee;
    trainingRef.current = emptyTraining;
  };

  const companyRef = useRef(emptyCompany);
  const employeeRef = useRef(emptyEmployee);
  const trainingRef = useRef(emptyTraining);

  return (
    <div className="home">
      <div className="headerContainer">
        <div id="linscot">
          <h2
            onClick={() => {
              navigate("/home");
            }}
          >
            Linscot Pulse
          </h2>
        </div>
        <div id="userInfo">
          <AvatarDropdown handleOpen={handleOpen} handleClose={handleClose} />
        </div>
      </div>
      <div className="menuContainer">
        <div id="navBar">
          <NavigationBar />
        </div>

        <div id="search">
          <CompanySearchBar
            isAdmin={isAdmin}
            allCompanies={allCompanies}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
        </div>
      </div>
      <div className="tableContainer">
        <div id="table">
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={() => {
              setAlertOpen(false);
              if (typeof asyncRefresh === "function") {
                asyncRefresh(); // Call only if asyncRefresh is a function
              }
            }}
            anchorOrigin={{ vertical: "top", horizontal: "left" }} // Position at top left
          >
            <Alert
              onClose={() => setAlertOpen(false)}
              severity="success"
              sx={{
                width: "300px",
                height: "50px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Success - Please refresh for updated data!
            </Alert>
          </Snackbar>
          <Table
            handleAddButtonOpen={handleAddButtonOpen}
            handleDeleteButtonOpen={handleDeleteButtonOpen}
            setCompanyId={setCompanyId}
            setCompanyName={setCompanyName}
            setAllCompanies={setAllCompanies}
            selectedValue={selectedValue}
            handleAlertSuccess={handleAlertSuccess}
            setAsyncRefresh={setAsyncRefresh}
          />
        </div>
      </div>
      <AddUserModal
        isOpen={isOpen}
        handleClose={handleClose}
        handleAlertSuccess={handleAlertSuccess}
      />
      <AddCompanyModal
        isAddCompanyOpen={isAddCompanyOpen}
        handleAddButtonClose={handleAddButtonClose}
        handleAlertSuccess={handleAlertSuccess}
      />
      <AddEmployeeModal
        isAddEmployeeOpen={isAddEmployeeOpen}
        handleAddButtonClose={handleAddButtonClose}
        companyId={companyId}
        companyName={companyName}
        handleAlertSuccess={handleAlertSuccess}
      />
      <AddTrainingModal
        isAddTrainingOpen={isAddTrainingOpen}
        handleAddButtonClose={handleAddButtonClose}
        companyId={companyId}
        companyName={companyName}
        showCompanySelector={false}
        handleAlertSuccess={handleAlertSuccess}
      />
      <DeleteCompanyModal
        isOpen={isDeleteCompanyOpen}
        handleClose={handleDeleteButtonClose}
        companyRef={companyRef}
        handleAlertSuccess={handleAlertSuccess}
      />
      <DeleteEmployeeModal
        isOpen={isDeleteEmployeeOpen}
        handleClose={handleDeleteButtonClose}
        employeeRef={employeeRef}
        handleAlertSuccess={handleAlertSuccess}
      />
      <DeleteTrainingModal
        isOpen={isDeleteTrainingOpen}
        handleClose={handleDeleteButtonClose}
        trainingRef={trainingRef}
        handleAlertSuccess={handleAlertSuccess}
      />
    </div>
  );
};

export default Home;
