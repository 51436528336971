// Getting all login info
const userInfo = JSON.parse(sessionStorage.getItem("user"));

export const updatedByInitials =
  userInfo?.authenticatedUser?.firstName[0] +
  "." +
  userInfo?.authenticatedUser?.lastName[0] +
  ".";

export const fullName =
  userInfo?.authenticatedUser?.firstName +
  " " +
  userInfo?.authenticatedUser?.lastName[0];

export const loginInitials =
  userInfo?.authenticatedUser?.firstName[0] +
  userInfo?.authenticatedUser?.lastName[0];

export const isAdmin = userInfo?.authenticatedUser?.isAdmin;

export const companyName = userInfo?.authenticatedUser?.companyName;

export const companyId = userInfo?.authenticatedUser?.companyId;
