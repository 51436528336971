import api from "./BaseUrl";
import { updatedByInitials } from "./UserSession";

export const saveRemindersFromTimeFrame = async ({
  startDate,
  endDate,
  scope,
}) => {
  try {
    const remindersFromTimeFrame = await await api.get(
      `/reminders/findReminders/${scope}/${startDate}/${endDate}`
    );
    return remindersFromTimeFrame.data;
  } catch (error) {
    console.error("Error fetching reminders: ", error);
  }
};

export const saveRemindersForDriver = async ({ employeeId }) => {
  try {
    const remindersForDriver = await api.get(
      `/reminders/allDriverReminders/${employeeId}`
    );
    return remindersForDriver.data;
  } catch (error) {
    console.error(`Error finding reminders for employee ${employeeId}`, error);
  }
};

export const saveRemindersForCompany = async ({ companyId }) => {
  try {
    const remindersForCompany = await api.get(
      `/reminders/allCompanyReminders/${companyId}`
    );
    return remindersForCompany.data;
  } catch (error) {
    console.error(`Error finding reminders for company ${companyId}`, error);
  }
};

export const updateReminders = async (reminderId, reminderData) => {
  try {
    await api.post(`/reminders/updateReminder/${reminderId}`, {
      reminderType: reminderData.reminderType,
      dueDate: reminderData.dueDate,
      companyName: reminderData.companyName,
      companyId: reminderData.companyId,
      firstName: reminderData.firstName,
      lastName: reminderData.lastName,
      employeeId: reminderData.employeeId,
      message: reminderData.message,
      isHidden: reminderData.isHidden,
      completedDate: reminderData.completedDate,
      hazmatRegistrationInterval: reminderData.hazmatRegistrationInterval
        ? reminderData.hazmatRegistrationInterval
        : null,
      reminderScope: reminderData.employeeId ? "Employee" : "Company",
      isLinscotManaged: reminderData.isLinscotManaged,
      updatedBy: updatedByInitials,
    });
  } catch (error) {
    console.error(`Error updating reminder with ID ${reminderId}: `, error);
  }
};

export const updateAllRemindersForDriver = async (employeeId) => {
  try {
    const response = await api.post(
      `reminders/updateAllForDriver/${employeeId}`
    );
    if (response.status === 404 || response.status === 200) {
      return null; // no reminders found to update
    }
    return response.data;
  } catch (error) {
    console.error(
      `Error updating reminders for employee ${employeeId}:`,
      error
    );
  }
};

export const updateAllRemindersForCompany = async (companyId) => {
  try {
    const response = await api.post(
      `reminders/updateAllForCompany/${companyId}`
    );
    if (response.status === 404 || response.status === 200) {
      return null; // no reminders found to update
    }
    return response.data;
  } catch (error) {
    console.error(`Error updating reminders for company ${companyId}:`, error);
  }
};

export const createReminders = async ({ reminderData }) => {
  try {
    await api.post(`/reminders/createReminder`, {
      reminderType: reminderData.reminderType,
      dueDate: reminderData.dueDate,
      companyName: reminderData.companyName,
      companyId: reminderData.companyId,
      firstName: reminderData.firstName,
      lastName: reminderData.lastName,
      employeeId: reminderData.employeeId,
      message: reminderData.message,
      isHidden: reminderData.isHidden,
      completedDate: reminderData.completedDate,
      hazmatRegistrationInterval: reminderData.hazmatRegistrationInterval
        ? reminderData.hazmatRegistrationInterval
        : null,
      reminderScope: reminderData.employeeId ? "Employee" : "Company",
      isLinscotManaged: reminderData.isLinscotManaged,
      createdBy: updatedByInitials,
    });
  } catch (error) {
    console.error("Error creating a reminder:", error);
  }
};
