import React, { useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { red, green } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

import EmployeeToolbar from "./employeeToolbar";

import { styled } from "@mui/material/styles";
import { emptyEmployee } from "../BoilerplateObjects";
import { getAllEmployees, getCompanyEmployees } from "../axios/Employees";
import {
  handleDetailsClick,
  handleChange,
  handleCancelOrSave,
  mapAllEmployees,
  countDrivers,
} from "../../functions/HelperFunctions";
import { isAdmin, companyId } from "../axios/UserSession";

const NewTable = styled(DataGrid)({
  fontFamily: "Poppins",
  textTransform: "none",
  backgroundColor: "white",
});

const authenticatedUserCompany = companyId;

function EmployeesTable({
  handleButtonOpen,
  setAllEmployees,
  selectedValue,
  handleAlertSuccess,
}) {
  const [tab, setTab] = useState("employees");
  const [rows, setRows] = useState([]);
  const [rowClicked, setRowClicked] = useState({});
  const [checked, setChecked] = useState({
    isHidden: false,
    isDriver: false,
    clearinghouse: false,
  });

  const values = useRef(emptyEmployee);

  const [detailsOpen, isDetailsOpen] = useState(false);
  const actionCategory = "Employee";

  const fetchEmployeesAndSave = async () => {
    try {
      if (isAdmin) {
        const employees = await getAllEmployees();
        setRows(mapAllEmployees(employees));
        setAllEmployees(mapAllEmployees(employees));
      } else {
        const employees = await getCompanyEmployees(authenticatedUserCompany);
        setRows(mapAllEmployees(employees));
        setAllEmployees(mapAllEmployees(employees));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateTableForSearch = () => {
    if (selectedValue && typeof selectedValue === "object") {
      // Check if selectedValue is an object (selected from dropdown)
      const filteredEmployees = rows.filter(
        (employee) =>
          `${employee.firstName} ${employee.lastName}` ===
          `${selectedValue.firstName} ${selectedValue.lastName}`
      );
      setRows(filteredEmployees); // Map only the selected employee
    } else if (typeof selectedValue === "string" && selectedValue !== "") {
      // If the user types in a value, filter by that value
      const filteredEmployees = rows.filter((employee) =>
        `${employee.firstName} ${employee.lastName}`
          .toLowerCase()
          .includes(selectedValue.toLowerCase())
      );
      setRows(filteredEmployees); // Map the filtered employees
    } else {
      // Show all employees if no input or selection
      fetchEmployeesAndSave(); // Re-fetch and display all employees
    }
  };

  useEffect(() => {
    if (selectedValue === null) {
      fetchEmployeesAndSave();
    } else {
      updateTableForSearch();
    }
  }, [selectedValue]);

  const handleRowClick = (params) => {
    setRowClicked(Object.assign(rowClicked, params.row));
  };

  const tabClicked = (tab) => {
    setTab(tab);
  };

  const allEmployeesColumns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 120,
      sortable: false,
      renderCell: (params) => {
        return <div>{params.value.slice(-6).toUpperCase()}</div>;
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "companyName",
      headerName: "Company",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "isHidden",
      headerName: "Status",
      minWidth: 120,
      sortable: true,
      renderCell: (params) => {
        return <div>{params.value ? "Deleted" : "Active"}</div>;
      },
    },
    {
      field: "state",
      headerName: "State",
      minWidth: 100,
      sortable: true,
    },
    {
      field: "consortium",
      headerName: "Consortium",
      minWidth: 120,
      sortable: true,
    },
    {
      field: "isDriver",
      headerName: "Driver",
      minwidth: 120,
      sortable: false,
      renderCell: (params) => {
        return <div>{params.value && "D"}</div>;
      },
    },
    {
      field: "reassign",
      headerName: "",
      minWidth: 100,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Reassign">
            <IconButton
              sx={{ color: green[500] }}
              onClick={() => {
                handleButtonOpen("reassign", params);
              }}
              disabled={!isAdmin}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      minWidth: 100,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              sx={{ color: red[500] }}
              onClick={() => {
                handleButtonOpen("delete", params);
              }}
              disabled={!isAdmin}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <NewTable
          disableRowSelectionOnClick
          rows={rows}
          columns={allEmployeesColumns}
          onRowClick={handleRowClick}
          onRowDoubleClick={handleDetailsClick({
            actionCategory,
            isDetailsOpen,
            rowClicked,
            setChecked,
            values,
          })}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
              sortModel: [{ field: "lastName", sort: "asc" }],
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          slots={{
            toolbar: EmployeeToolbar,
            footer: () => (
              <>
                <div
                  style={{
                    display: "flex",
                    direction: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      fontWeight: "bold",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        direction: "row",
                      }}
                    >
                      <div style={{ marginRight: "20px" }}>
                        Total Employees: {rows.length}
                      </div>
                      <div>Total Drivers: {countDrivers(rows)}</div>
                    </div>
                  </Box>
                  <GridPagination />
                </div>
              </>
            ),
          }}
          slotProps={{
            toolbar: {
              actionCategory,
              tabClicked,
              checked,
              setChecked,
              rowClicked,
              tab,
              handleDetailsClick,
              isDetailsOpen,
              handleChange,
              handleCancelOrSave,
              handleAlertSuccess,
              detailsOpen,
              values,
              printOptions: {
                hideFooter: true,
                hideToolbar: true,
              },
              handleButtonOpen,
              refreshData: fetchEmployeesAndSave,
            },
          }}
        />
      </div>
    </div>
  );
}

export default EmployeesTable;
