import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Session Imports
import { isAdmin } from "./axios/UserSession";

import "./NavigationBar.css";

const MenuButton = styled(Button)({
  fontFamily: "Poppins",
  textTransform: "none",
});

const selected = {
  color: "#FFFFFF",
  backgroundColor: "#21357B",
  borderColor: "#FFFFFF",
};

const unselected = {
  color: "#21357B",
  backgroundColor: "#FFFFFF",
  borderColor: "#21357B",
};

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Vars for Operations menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="navbar">
      <div className="firstButtonContainer">
        <MenuButton
          variant={location.pathname === "/home" ? "contained" : "outlined"}
          fullWidth={true}
          sx={location.pathname === "/home" ? selected : unselected}
          onClick={() => {
            navigate("/home");
          }}
        >
          Company
        </MenuButton>
      </div>
      <div className="buttonContainer">
        <MenuButton
          variant={
            location.pathname === "/employees" ? "contained" : "outlined"
          }
          sx={location.pathname === "/employees" ? selected : unselected}
          fullWidth={true}
          onClick={() => {
            navigate("/employees");
          }}
        >
          Employee
        </MenuButton>
      </div>
      <div className="buttonContainer">
        <MenuButton
          variant={
            location.pathname === "/training" ||
            location.pathname === "/reports" ||
            location.pathname === "/screenings" ||
            location.pathname === "/reminders"
              ? "contained"
              : "outlined"
          }
          sx={
            location.pathname === "/training" ||
            location.pathname === "/reports" ||
            location.pathname === "/screenings" ||
            location.pathname === "/reminders"
              ? selected
              : unselected
          }
          fullWidth={true}
          onClick={handleClick}
          // Disabling button if User is NOT admin
          disabled={!isAdmin}
        >
          Operations
        </MenuButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              navigate("/training");
            }}
          >
            Training
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/screenings");
            }}
          >
            Screening
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/reminders");
            }}
          >
            Reminders
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/reports");
            }}
          >
            Reports
          </MenuItem>
        </Menu>
      </div>
      <div className="buttonContainer">
        <MenuButton
          variant={location.pathname === "/settings" ? "contained" : "outlined"}
          sx={location.pathname === "/settings" ? selected : unselected}
          fullWidth={true}
          onClick={() => {
            navigate("/settings");
          }}
          // Disabling button if User is NOT admin
          disabled={!isAdmin}
        >
          Settings
        </MenuButton>
      </div>
    </div>
  );
};

export default NavigationBar;
