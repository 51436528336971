import React, { useEffect, useRef, forwardRef, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import api from "../axios/BaseUrl";
import "../TableToolbar.css";
import { updatedByInitials } from "../axios/UserSession";
import {
  saveRemindersForDriver,
  updateReminders,
  createReminders,
} from "../axios/Reminders";
import {
  getEmployeeScreenings,
  updateEmployeeScreening,
} from "../axios/Screenings";
import { getAllTrainings } from "../axios/Trainings";
import { isAdmin } from "../axios/UserSession";
import {
  mapSingleEmployeeScreenings,
  mapTrainingsByAttendee,
} from "../../functions/HelperFunctions";
import { getEmployeeFiles, deleteEmployeeFile } from "../axios/EmployeeFiles";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

const RefTooltip = forwardRef((props, ref) => <Tooltip ref={ref} {...props} />);

function useDeepCompareEffect(callback, dependencies) {
  const currentDependenciesRef = useRef();

  const serializedDependencies = JSON.stringify(dependencies);

  if (serializedDependencies !== currentDependenciesRef.current) {
    currentDependenciesRef.current = serializedDependencies;
  }

  useEffect(callback, [currentDependenciesRef.current]);
}

const StyledDetailsTooltip = styled(({ className, ...props }) => (
  <RefTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    color: "black",
    backgroundColor: "white",
    fontSize: "14px",
    fontFamily: "Poppins",
    padding: "0px 0px 0px 20px",
    margin: "0px 0px 0px 25px",
    position: "relative",
  },
});

const SaveOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

const ProgramsForm = styled(FormGroup)({
  display: "flex",
  flexDirection: "row",
  width: "99%",
});

const ProgramsLabel = styled(FormControlLabel)({
  marginRight: "30px",
});

// Reminder types and their intervals in years
const REMINDER_TYPES = {
  DMV: { label: "DMV", intervalYears: 1 },
  CDL: { label: "CDL", intervalYears: 5 },
  HAZMAT: { label: "HAZMAT", intervalYears: 3 },
  PHYS: { label: "PHYS", intervalYears: 2 },
  HME: { label: "HME", intervalYears: 5 },
  CLRHSE: { label: "CLRHSE", intervalYears: 1 },
};

const FILE_TYPES = {
  APPLICATION: { label: "Application" },
  DRIVER_DATA_SHEET: { label: "Drivers Data Sheet" },
  ROAD_TEST: { label: "Road Test and Certificate" },
  BACKGROUND_CHECK: { label: "Background Check Authorization" },
  CDL: { label: "CDL" },
  DRIVING_RECORD: { label: "Driving Record" },
  DRIVER_RECORD_REVIEW: { label: "Annual Review" },
  DISCLOSURE_RELEASE_FORM: { label: "Disclosure and Release Form" },
  MED_CARD: { label: "Medical Card" },
  PRE_EMP_SCREEN: { label: "Pre-Employment Drug Test" },
  CLEARINGHOUSE: { label: "Clearinghouse" },
  CERT_CONSENT: { label: "Certificate Consent" },
  GEN_CONSENT: { label: "General Consent" },
};

const failureReasons = [
  "Amphetamine",
  "Barbiturate",
  "Benzodiazepine",
  "Cannabis",
  "Cocaine",
  "Opioid",
];

// Function to find a reminder by its type
const findReminder = (reminders, type) =>
  reminders.find((r) => r.reminderType === type);

// Function to calculate the due date by adding a number of years
const calculateDueDate = (startDate, intervalYears) =>
  dayjs(startDate).add(intervalYears, "year");

export function EmployeesTooltip({ props, children }) {
  let {
    actionCategory,
    checked,
    formInfo,
    isDetailsOpen,
    detailsOpen,
    handleChange,
    handleCancelOrSave,
    handleAlertSuccess,
    values,
    setChecked,
  } = props;

  const tooltipRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [reminders, setReminders] = useState([]);
  const [screenings, setScreenings] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [fileData, setFileData] = useState({});
  const [reminderTypes, setReminderTypes] = useState({
    DMV: false,
    CDL: false,
    HAZMAT: false,
    PHYS: false,
    HME: false,
    CLRHSE: false,
  });

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      const DontCloseElement =
        event.target.classList.contains("DontClose") ||
        event.target.classList.contains("MuiAutocomplete-option") ||
        event.target.classList.contains("MuiInputBase-input") ||
        event.target.classList.contains("MuiModal-backdrop") ||
        event.target.classList.contains("MuiPickersDay-root") ||
        event.target.classList.contains("addCourseButton") ||
        event.target.classList.contains("MuiPopper-root") ||
        event.target.classList.contains("MuiTooltip-popper") ||
        event.target.name === "cancelModal" ||
        event.target.name === "saveModal" ||
        event.target.classList.contains("MuiAutocomplete-popper") ||
        event.target.classList.contains("MuiPickersCalendarHeader-label") ||
        event.target.classList.contains("MuiModal-backdrop") ||
        event.target.classList.contains("MuiTooltip-popper") ||
        event.target.classList.contains("MuiPickersDay-root") ||
        event.target.classList.contains("DontClose") ||
        event.target.classList.contains("MuiPickersCalendarHeader-label") ||
        event.target.classList.contains("MuiDayCalendar-weekDayLabel") ||
        event.target.classList.contains(
          "MuiPickersCalendarHeader-labelContainer"
        ) ||
        event.target.classList.contains("MuiPickersCalendarHeader-root") ||
        event.target.classList.contains("MuiDayCalendar-header") ||
        event.target.classList.contains("MuiPickersYear-yearButton") ||
        event.target.classList.contains("MuiPickersCalendar-root") ||
        event.target.classList.contains("MuiIconButton-root") ||
        event.target.classList.contains("MuiPickersYear-root") ||
        event.target.classList.contains("MuiSvgIcon-root") ||
        event.target.classList.contains("MuiDayCalendar-weekContainer") ||
        event.target.classList.contains("MuiDateCalendar-root") ||
        event.target.classList.contains("MuiPickersFadeTransitionGroup-root") ||
        event.target.classList.contains("MuiPickersArrowSwitcher-root") ||
        event.target.classList.contains("MuiPickersArrowSwitcher-spacer") ||
        event.target.classList.contains("MuiDayCalendar-monthContainer") ||
        event.target.classList.contains(
          "MuiPickersCalendarHeader-switchViewIcon"
        );
      if (!DontCloseElement) {
        isDetailsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  // Additional useEffect for loading data when formInfo changes
  useDeepCompareEffect(() => {
    const loadData = async () => {
      if (!formInfo || !formInfo.id) {
        setReminders([]);
        setScreenings([]);
        setTrainings([]);
        return;
      }

      try {
        let { firstName, lastName, id, companyName } = formInfo;
        const attendeeName = `${firstName} ${lastName}`;
        const reminderData = await saveRemindersForDriver({
          employeeId: id,
        });
        setReminders(reminderData);

        // Update reminderTypes state based on loaded reminders
        const updatedReminderTypes = Object.keys(REMINDER_TYPES).reduce(
          (acc, type) => {
            acc[type] = reminderData.some(
              (reminder) => reminder.reminderType === type
            );
            return acc;
          },
          {}
        );
        setReminderTypes(updatedReminderTypes);

        const employeeScreenings = await getEmployeeScreenings(id);
        const mappedScreenings =
          mapSingleEmployeeScreenings(employeeScreenings);
        setScreenings(mappedScreenings);

        const trainingsData = await getAllTrainings();
        const filteredTrainings = mapTrainingsByAttendee(
          trainingsData,
          companyName,
          attendeeName
        );
        setTrainings(filteredTrainings);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    loadData();
  }, [formInfo]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleRemindersCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setReminderTypes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    setReminders((prevReminders) => {
      const updatedReminders = prevReminders.map((reminder) =>
        reminder.reminderType === name
          ? { ...reminder, isHidden: !checked }
          : reminder
      );

      return updatedReminders;
    });
  };

  const validateReminders = () => {
    for (const reminder of reminders) {
      if (reminderTypes[reminder.reminderType] && !reminder.completedDate) {
        return false;
      }
    }
    return true;
  };

  const handleRemindersSave = async (event) => {
    event.preventDefault();

    if (!validateReminders()) {
      alert("Please select a completed date for all checked reminders.");
      return;
    }

    try {
      for (const reminder of reminders) {
        if (reminder.isNew) {
          await createReminders({ reminderData: reminder });
        } else {
          await updateReminders(reminder._id, {
            ...reminder,
            isHidden: reminder.isHidden,
          });
        }
        isDetailsOpen(false);
        handleAlertSuccess();
      }
    } catch (error) {
      console.error("Error saving reminders:", error);
    }
  };
  // Upload file to AWS S3 and get the file URL
  // Could not figure out how to keep reference to formData
  // while passing it as a param when using our normal method
  // having to inject axios endpoint directly here for the additional middleware

  const handleFileUpload = async (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", type);
    formData.append("employeeId", formInfo.id); // Updated to use `formInfo.id`
    formData.append("firstName", formInfo.firstName);
    formData.append("lastName", formInfo.lastName);
    formData.append("companyName", formInfo.companyName);
    formData.append("companyId", formInfo.companyId);
    formData.append("updatedBy", updatedByInitials);
    // Could not figure out how to keep reference to formData
    // while passing it as a param when using our normal method
    // having to inject axios endpoint directly here for the additional middleware
    try {
      const updatedEmployeeFile = await api.post(
        `/employeefiles/upload/${formInfo.id}`, // Updated to use `formInfo.id`
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (updatedEmployeeFile.status === 200) {
        isDetailsOpen(false);
        handleAlertSuccess();
      }
    } catch (error) {
      console.error(
        "Error updating employee file: ",
        error.response || error.message || error
      );
    }
  };

  const handleStatusChange = (screeningId, newStatus) => {
    setScreenings((prevScreenings) =>
      prevScreenings.map((screening) =>
        screening.id === screeningId
          ? {
              ...screening,
              result: newStatus,
              failureReason:
                newStatus === "failed" ? screening.failureReason : null,
            }
          : screening
      )
    );
  };

  const handleFailureReasonChange = (screeningId, newFailureReason) => {
    setScreenings((prevScreenings) => {
      const updatedScreenings = prevScreenings.map((screening) => {
        if (screening.screeningId === screeningId) {
          return { ...screening, failureReason: newFailureReason };
        }
        return screening;
      });

      return updatedScreenings;
    });
  };

  // ... existing code ...

  const handleSaveScreenings = async () => {
    try {
      for (const screening of screenings) {
        await updateEmployeeScreening({
          screeningType: screening.screen,
          screeningId: screening.screeningId,
          employeeId: formInfo.id,
          status: screening.result,
          failureReason: screening.failureReason || null,
          screeningReason: screening.screeningReason || null,
        });
      }
      handleAlertSuccess();
      isDetailsOpen(false);
    } catch (error) {
      console.error("Error saving screenings:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  // ... rest of the code ...

  const fetchEmployeeFiles = async () => {
    if (formInfo.employeeId) {
      try {
        const employeeFileData = await getEmployeeFiles(formInfo.employeeId);
        if (employeeFileData && employeeFileData.employee.files) {
          setFileData(updateEmployeeImageUrls(employeeFileData));
        } else {
          setFileData({ files: {} });
        }
      } catch (error) {
        setFileData({ files: {} });
        console.error(
          "Employee may not exist or there is an error fetching employee files:",
          error
        );
      }
    }
  };

  const updateEmployeeImageUrls = (data) => {
    const { employee, urls } = data;

    if (employee.files && Array.isArray(employee.files)) {
      employee.files = employee.files.reduce((acc, file) => {
        const imageUrl = urls[file.imageName];
        if (imageUrl) {
          acc[file.fileType] = {
            imageName: file.imageName,
            imageUrl,
          };
        } else {
          acc[file.fileType] = {
            imageName: file.imageName,
          };
        }
        return acc;
      }, {});
    }

    return employee;
  };

  const handleFileDownload = (url) => {
    window.open(url, "_blank");
  };

  const handleFileDelete = async (type) => {
    try {
      await deleteEmployeeFile({
        fileType: type,
        employeeId: formInfo.employeeId,
      });
      handleAlertSuccess();
    } catch (error) {
      console.error("Error deleting employee file: ", error);
    }
  };

  useEffect(() => {
    if (selectedTab === 4) {
      fetchEmployeeFiles();
    }
  }, [formInfo.employeeId, selectedTab]);

  const renderTabContent = (formInfo) => {
    let {
      firstName,
      lastName,
      employeeNotes,
      consortium,
      state,
      companyName,
      id,
      cdl,
      updatedAt,
      updatedBy,
      isHidden,
    } = formInfo;

    const hireDate = formInfo.hireDate
      ? dayjs(formInfo.hireDate).tz("US/Central")
      : null;
    const birthday = formInfo.birthday
      ? dayjs(formInfo.birthday).tz("US/Central")
      : null;
    const terminationDate = formInfo.terminationDate
      ? dayjs(formInfo.terminationDate).tz("US/Central")
      : null;
    const fullName = firstName + " " + lastName;

    switch (selectedTab) {
      case 0: // Details
        return (
          <React.Fragment>
            <div className="tooltipFormHeader">
              <h3>Employee Details</h3>
              <IconButton
                aria-label="close"
                onClick={() => {
                  isDetailsOpen(false);
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <div className="tooltipFormRow">
              <div className="tooltipNames">
                <div className="tooltipNamesCell">First Name</div>
                <TextField
                  name="firstName"
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                  fullWidth
                  defaultValue={firstName || ""}
                  size="small"
                  variant="outlined"
                />
              </div>
              <div className="tooltipNames">
                <div className="tooltipNamesCell">Last Name</div>
                <TextField
                  name="lastName"
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                  fullWidth
                  defaultValue={lastName || ""}
                  size="small"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="tooltipFormRow">
              <LocalizationProvider
                className="localProvider"
                dateAdapter={AdapterDayjs}
              >
                <div className="tooltipNames">
                  <div className="tooltipNamesCell">Date of Hire</div>
                  <div className="customDatePickerWidth">
                    <DatePicker
                      className="DontClose"
                      name="hireDate"
                      value={hireDate}
                      sx={{ width: "fullWidth" }}
                      slotProps={{
                        calendarHeader: {
                          className: "DontClose",
                          slotProps: {
                            previousIconButton: { className: "DontClose" },
                            switchViewButton: { className: "DontClose" },
                            switchViewIcon: { className: "DontClose" },
                          },
                        },
                        openPickerButton: { className: "DontClose" },
                        openPickerIcon: { className: "DontClose" },
                        textField: {
                          size: "small",
                          fullWidth: true,
                          className: "DontClose",
                        },
                        popper: {
                          className: "DontClose",
                          placement: "bottom",
                          sx: { zIndex: "1500" },
                        },
                      }}
                      onChange={(e) => {
                        handleChange(e, { values, setChecked }, "hireDate");
                      }}
                      textField={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </div>
                </div>
                {isHidden === true || isHidden === "true" ? (
                  <div className="tooltipNames">
                    <div className="tooltipNamesCell">Date of Termination</div>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        name="terminationDate"
                        value={terminationDate}
                        sx={{ width: "fullWidth" }}
                        slotProps={{
                          textField: { size: "small", fullWidth: true },
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                        }}
                        onChange={(e) => {
                          handleChange(
                            e,
                            { values, setChecked },
                            "terminationDate"
                          );
                        }}
                        textField={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="tooltipNames">
                    <div className="tooltipNamesCell">Date of Birth</div>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        name="birthday"
                        value={birthday}
                        sx={{ width: "fullWidth" }}
                        slotProps={{
                          textField: { size: "small", fullWidth: true },
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                        }}
                        onChange={(e) => {
                          handleChange(e, { values, setChecked }, "birthday");
                        }}
                        textField={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </div>
                  </div>
                )}
              </LocalizationProvider>
            </div>
            <div className="tooltipFormRow">
              <div className="tooltipCSD">
                <div className="tooltipConsortiumCell">Consortium</div>
                <Autocomplete
                  value={consortium}
                  fullWidth
                  name="consortium"
                  onChange={(event, newValue) => {
                    handleChange(
                      { target: { name: "consortium", value: newValue } },
                      { values, setChecked }
                    );
                  }}
                  options={["DOT", "NONDOT"]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  slotProps={{
                    popper: {
                      className: "DontClose",
                      placement: "bottom",
                      sx: { zIndex: "1500" },
                    },
                  }}
                  disableClearable
                  className="DontClose"
                />
              </div>
              <div className="tooltipCSD">
                <div className="tooltipFormCell">State</div>
                <TextField
                  name="state"
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                  fullWidth
                  defaultValue={state}
                  size="small"
                  variant="outlined"
                />
              </div>
              <div className="tooltipCSD">
                <div className="tooltipFormCell">CDL</div>
                <TextField
                  name="cdl"
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                  fullWidth
                  defaultValue={cdl}
                  size="small"
                  variant="outlined"
                />
              </div>
              <div className="tooltipCSD">
                <div className="tooltipNamesCell">Is a Driver</div>
                <ProgramsForm>
                  <ProgramsLabel
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": {
                            color: "#21357B",
                          },
                        }}
                        checked={checked.isDriver}
                        name="isDriver"
                        onChange={(e) => {
                          handleChange(e, { values, setChecked, checked });
                        }}
                      />
                    }
                  />
                </ProgramsForm>
              </div>
            </div>
            <div className="employeeRow">
              <div className="tooltipFormCell">Company</div>
              <TextField
                name="company"
                fullWidth
                disabled
                size="small"
                variant="outlined"
                defaultValue={companyName}
              />
            </div>
            <div className="employeeRow">
              <div className="tooltipFormCell">Notes</div>
              <TextField
                name="employeeNotes"
                onChange={(e) => {
                  handleChange(e, { values, setChecked });
                }}
                fullWidth
                size="small"
                variant="outlined"
                multiline
                defaultValue={employeeNotes}
              />
            </div>
            {updatedAt && updatedBy ? (
              <div className="tooltipFormRow">
                <span className="lastModified">
                  Last Modified at {updatedAt} by {updatedBy}
                </span>
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <div id="saveOrCancel">
              <SaveOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e, {
                    isDetailsOpen,
                  });
                }}
                name="cancel"
                variant="contained"
              >
                Cancel
              </SaveOrCancel>
              <SaveOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e, {
                    isDetailsOpen,
                    detailsOpen,
                    actionCategory,
                    values,
                  });
                  handleAlertSuccess();
                }}
                name="save"
                variant="contained"
                disabled={!isAdmin}
              >
                Save
              </SaveOrCancel>
            </div>
          </React.Fragment>
        );
      case 1: // Trainings
        return (
          <React.Fragment>
            <div className="tooltipFormHeader">
              <h3>{fullName}'s Trainings</h3>
              <IconButton
                aria-label="close"
                onClick={() => {
                  isDetailsOpen(false);
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Course Title</TableCell>
                    <TableCell align="center">Grade</TableCell>
                    <TableCell align="center">Instructor</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trainings.map((training) => (
                    <TableRow
                      key={training._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="center"
                        sx={{ width: "20%" }}
                      ></TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        {dayjs(training.trainingDate)
                          .tz("US/Central")
                          .format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: "20%" }}
                        component="th"
                        scope="row"
                      >
                        {training.courseTitle}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        {""}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "20%" }}>
                        {training.instructor}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: "20%" }}
                      ></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        );
      case 2: // Reminders
        return (
          <React.Fragment>
            <div className="tooltipFormHeader">
              <h3>{fullName}'s Reminders</h3>
              <IconButton
                aria-label="close"
                onClick={() => {
                  isDetailsOpen(false);
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TableContainer component={Paper}>
                <Table aria-label="reminder table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">Type</TableCell>
                      <TableCell align="center">Completed</TableCell>
                      <TableCell align="center">Due</TableCell>
                      <TableCell align="center">Applicable</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(REMINDER_TYPES).map((type) => {
                      const reminderType = REMINDER_TYPES[type];
                      const reminder = findReminder(
                        reminders,
                        reminderType.label
                      ) || {
                        reminderType: reminderType.label,
                        dueDate: null,
                        companyName: formInfo.companyName,
                        companyId: formInfo.companyId,
                        firstName: formInfo.firstName,
                        lastName: formInfo.lastName,
                        employeeId: formInfo.id,
                        message: "",
                        isHidden: true,
                        completedDate: null,
                        isLinscotManaged: false,
                        isNew: true, // Flag to indicate new reminder
                      };
                      const completedDate = reminder.completedDate
                        ? dayjs(reminder.completedDate).tz("US/Central")
                        : null;
                      const dueDate = reminder.dueDate
                        ? dayjs(reminder.dueDate).tz("US/Central")
                        : null;
                      // Update isApplicable condition for CLRHSE
                      const isApplicable =
                        reminderType.label === "CLRHSE"
                          ? checked.clearinghouse ||
                            values.current.clearinghouse
                          : Boolean(reminderTypes[reminderType.label]);

                      const handleCompletedDateChange = (date) => {
                        if (!date || !date.$d) return;

                        // Explicitly set the time to noon (12:00 PM) in the Central Time Zone
                        const timestamp = dayjs(date.$d)
                          .hour(12) // Set to noon
                          .minute(0)
                          .second(0)
                          .millisecond(0)
                          .tz("US/Central"); // Convert to Central Time

                        // Calculate the due date, also set to noon in Central Time
                        const newDueDate = calculateDueDate(
                          timestamp,
                          reminderType.intervalYears
                        )
                          .hour(12)
                          .minute(0)
                          .second(0)
                          .millisecond(0);

                        setReminders((prevReminders) => {
                          const existingReminderIndex = prevReminders.findIndex(
                            (r) => r.reminderType === reminderType.label
                          );

                          if (existingReminderIndex !== -1) {
                            // Update existing reminder
                            const updatedReminders = [...prevReminders];
                            updatedReminders[existingReminderIndex] = {
                              ...updatedReminders[existingReminderIndex],
                              completedDate: timestamp,
                              dueDate: newDueDate.$d,
                            };
                            return updatedReminders;
                          } else {
                            // Create new reminder
                            return [
                              ...prevReminders,
                              {
                                reminderType: reminderType.label,
                                dueDate: newDueDate.$d,
                                companyName: formInfo.companyName,
                                companyId: formInfo.companyId,
                                firstName: formInfo.firstName,
                                lastName: formInfo.lastName,
                                employeeId: formInfo.id,
                                message: "",
                                isHidden: false,
                                completedDate: timestamp.$d, // Save at noon in Central Time
                                isLinscotManaged: false,
                                isNew: true, // Set isNew to true for new reminders
                              },
                            ];
                          }
                        });
                      };

                      const handleDueDateChange = (date) => {
                        if (!date || !date.$d) return;

                        // Explicitly set the time to noon (12:00 PM) in the Central Time Zone
                        const timestamp = dayjs(date.$d)
                          .hour(12) // Set to noon
                          .minute(0)
                          .second(0)
                          .millisecond(0)
                          .tz("US/Central"); // Convert to Central Time

                        setReminders((prevReminders) => {
                          const updatedReminders = prevReminders.map((r) => {
                            const isNew =
                              !r._id && r.reminderType === reminderType.label;

                            if (r.reminderType === reminderType.label) {
                              return {
                                ...r,
                                dueDate: timestamp,
                                isNew,
                              };
                            }

                            return r;
                          });
                          return updatedReminders;
                        });
                      };

                      // This function handles manual input of date values and triggers the appropriate change handler.
                      const handleDateBlur = (event, handleChangeFunction) => {
                        const inputValue = event.target.value;
                        const date = dayjs(inputValue, "MM/DD/YYYY"); // Adjust the format as needed

                        if (date.isValid()) {
                          handleChangeFunction(date);
                        }
                      };

                      return (
                        <TableRow key={reminderType.label}>
                          <TableCell
                            align="center"
                            sx={{ width: "20%" }}
                          ></TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            {reminderType.label}
                          </TableCell>
                          <TableCell align="center" sx={{ width: "20%" }}>
                            <DatePicker
                              name="completedDate"
                              className="DontClose"
                              value={completedDate}
                              disabled={!isApplicable}
                              onChange={handleCompletedDateChange}
                              onBlur={(event) =>
                                handleDateBlur(event, handleCompletedDateChange)
                              }
                              slotProps={{
                                popper: {
                                  className: "DontClose",
                                  placement: "bottom",
                                  sx: { zIndex: "1500" },
                                },
                                textField: { size: "small" },
                              }}
                              textField={(params) => (
                                <TextField
                                  {...params}
                                  disabled={!isApplicable}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell align="center" sx={{ width: "20%" }}>
                            <DatePicker
                              name="dueDate"
                              value={dueDate}
                              disabled={completedDate ? false : true}
                              onChange={handleDueDateChange}
                              onBlur={(event) =>
                                handleDateBlur(event, handleDueDateChange)
                              }
                              slotProps={{
                                popper: {
                                  className: "DontClose",
                                  placement: "bottom",
                                  sx: { zIndex: "1500" },
                                },
                                textField: { size: "small" },
                              }}
                              textField={(params) => <TextField {...params} />}
                            />
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            <Checkbox
                              name={reminderType.label}
                              checked={
                                reminderType.label === "CLRHSE"
                                  ? checked.clearinghouse ||
                                    values.current.clearinghouse
                                  : Boolean(reminderTypes[reminderType.label])
                              }
                              onChange={handleRemindersCheckboxChange}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "20%" }}
                          ></TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </LocalizationProvider>
            <div id="saveOrCancel">
              <SaveOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e, {
                    isDetailsOpen,
                  });
                }}
                name="cancel"
                variant="contained"
              >
                Cancel
              </SaveOrCancel>
              <SaveOrCancel
                onClick={handleRemindersSave}
                name="save"
                variant="contained"
                disabled={!isAdmin}
              >
                Save
              </SaveOrCancel>
            </div>
          </React.Fragment>
        );
      case 3: // Screenings
        return (
          <React.Fragment>
            <div className="tooltipFormHeader">
              <h3>{fullName}'s Screenings</h3>
              <IconButton
                aria-label="close"
                onClick={() => {
                  isDetailsOpen(false);
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Screening Reason</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">Result</TableCell>
                    <TableCell align="center">Failure Reason</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {screenings.map((screening) => (
                    <TableRow
                      key={screening.screeningId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="center"
                        sx={{ width: "20%" }}
                        component="th"
                        scope="row"
                      >
                        {screening.screeningReason || "Random Selection"}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "15%" }}>
                        {screening.date}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: "15%" }}
                        component="th"
                        scope="row"
                      >
                        {screening.screen}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "15%" }}>
                        <Autocomplete
                          value={screening.result}
                          onChange={(event, newValue) =>
                            handleStatusChange(screening.id, newValue)
                          }
                          options={[
                            "selected",
                            "passed",
                            "failed",
                            "wrong test",
                            "refused",
                            "not at company",
                            "sick",
                          ]}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                          disableClearable
                          slotProps={{
                            popper: {
                              className: "DontClose",
                              placement: "bottom",
                              sx: { zIndex: "1500" },
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ width: "15%" }}>
                        {screening.screen === "Drug" &&
                          screening.result === "failed" && (
                            <Autocomplete
                              value={screening.failureReason || null}
                              onChange={(event, newValue) =>
                                handleFailureReasonChange(
                                  screening.screeningId,
                                  newValue
                                )
                              }
                              options={failureReasons}
                              renderInput={(params) => (
                                <TextField {...params} size="small" />
                              )}
                              size="small"
                              disableClearable
                              slotProps={{
                                popper: {
                                  className: "DontClose",
                                  placement: "bottom",
                                  sx: { zIndex: "1500" },
                                },
                              }}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div id="saveOrCancel">
              <SaveOrCancel
                onClick={() => isDetailsOpen(false)}
                name="cancel"
                variant="contained"
              >
                Cancel
              </SaveOrCancel>
              <SaveOrCancel
                onClick={handleSaveScreenings}
                name="save"
                variant="contained"
                disabled={!isAdmin}
              >
                Save
              </SaveOrCancel>
            </div>
          </React.Fragment>
        );
      case 4: // Files
        return (
          <React.Fragment>
            <div className="tooltipFormHeader">
              <h3>{fullName}'s Files</h3>
              <IconButton
                aria-label="close"
                onClick={() => {
                  isDetailsOpen(false);
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="file table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">File Name</TableCell>
                    <TableCell align="center">Upload</TableCell>
                    <TableCell align="center">Download</TableCell>
                    <TableCell align="center">Delete</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(FILE_TYPES).map((type) => {
                    const fileType = FILE_TYPES[type];
                    const file =
                      fileData.files && fileData.files[type]
                        ? fileData.files[type]
                        : null;

                    return (
                      <TableRow key={fileType.label}>
                        <TableCell
                          align="center"
                          sx={{ width: "20%" }}
                        ></TableCell>
                        <TableCell align="center" sx={{ width: "20%" }}>
                          {fileType.label}
                        </TableCell>
                        <TableCell align="center" sx={{ width: "20%" }}>
                          <input
                            type="file"
                            onChange={(e) => handleFileUpload(e, type)}
                            disabled={file !== null}
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ width: "10%" }}>
                          {file && (
                            <IconButton
                              style={{ color: "#21357B" }}
                              onClick={() => handleFileDownload(file.imageUrl)}
                            >
                              <CloudDownloadIcon />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell align="center" sx={{ width: "10%" }}>
                          {file && (
                            <IconButton
                              sx={{ color: red[500] }}
                              onClick={() => handleFileDelete(type)}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "20%" }}
                        ></TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  if (
    Object.keys(formInfo).length <= 0 ||
    Object.keys(formInfo).length === undefined ||
    Object.keys(formInfo).length === null
  ) {
    return <React.Fragment>{children}</React.Fragment>;
  } else if (
    actionCategory === "Employee" &&
    Object.keys(formInfo).length > 0
  ) {
    return (
      <StyledDetailsTooltip
        title={
          <div ref={tooltipRef} className="tooltipFormBox">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              centered
              sx={{
                ".MuiTab-root": {
                  fontFamily: "Poppins, sans-serif",
                  textTransform: "none",
                },
              }}
            >
              <Tab label="Details" />
              <Tab label="Trainings" />
              <Tab label="Reminders" />
              <Tab label="Screenings" />
              <Tab label="Files" />
            </Tabs>
            <React.Fragment>{renderTabContent(formInfo)}</React.Fragment>
          </div>
        }
        open={detailsOpen}
      >
        {children}
      </StyledDetailsTooltip>
    );
  }
}
