import React, { useEffect } from "react";
import { fetchCompanies } from "../axios/Companies";
import { updateEmployee } from "../axios/Employees";
import "./AddUserModal.css";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "60%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
};

const SaveOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

const textFieldStyle = {
  marginTop: ".4rem",
  marginBottom: ".4rem",
};

let allCompanies = [];

export default function ReassignEmployeeModal({
  isOpen,
  handleClose,
  employeeRef,
}) {
  const handleChange = (e) => {
    employeeRef.current.companyName = e.target.value.companyName;
    employeeRef.current.companyId = e.target.value._id;
  };

  const handleCancelOrSave = (e) => {
    if (e.target.name === "cancel") {
      handleClose();
    } else {
      employeeRef.current.isHidden = false;
      updateEmployee({ employee: employeeRef.current });
      handleClose();
    }
  };

  const fetchCompaniesAndSave = async () => {
    try {
      const companies = await fetchCompanies();
      allCompanies = companies;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCompaniesAndSave();
  }, []);

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <h3>Reassign Employee</h3>
          <div className="inputContainer">
            <div className="formRow">
              <div className="formCell">First Name</div>
              <TextField
                fullWidth
                disabled
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                value={employeeRef.current.firstName}
                name="firstName"
              />
            </div>

            <div className="formRow">
              <div className="formCell">Last Name</div>
              <TextField
                fullWidth
                disabled
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                value={employeeRef.current.lastName}
                name="lastName"
              />
            </div>

            <div className="formRow">
              <div className="formCell">Old Company</div>
              <TextField
                fullWidth
                disabled
                size="small"
                variant="outlined"
                sx={textFieldStyle}
                value={employeeRef.current.companyName}
                name="companyName"
              />
            </div>
            <div className="formRow">
              <div className="formCell">New Company</div>
              <Select
                size="small"
                fullWidth
                name="companyName"
                sx={textFieldStyle}
                defaultValue={""}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                {allCompanies.map(({ companyName, _id }, index) => (
                  <MenuItem key={index} value={{ companyName, _id }}>
                    {companyName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div id="saveOrCancel">
            <SaveOrCancel
              onClick={(e) => {
                handleCancelOrSave(e);
              }}
              name="cancel"
              variant="contained"
            >
              Cancel
            </SaveOrCancel>
            <SaveOrCancel
              onClick={(e) => {
                handleCancelOrSave(e);
              }}
              name="save"
              variant="contained"
            >
              Save
            </SaveOrCancel>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
