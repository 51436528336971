import React, { useEffect, useRef, useState } from "react";
import { createCompany } from "../axios/Companies";
import { createReminders } from "../axios/Reminders";
import "./AddCompanyModal.css";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "70%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
  overflow: "scroll",
};

const textFieldStyle = {
  marginTop: ".4rem",
  marginBottom: ".4rem",
  width: "100%",
};

const cityTextFieldStyle = {
  marginTop: ".4rem",
  marginBottom: ".4rem",
  width: "100%",
};

const SaveOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

const company = {
  city: "",
  contact: "",
  companyName: "",
  email: "",
  fax: "",
  phone: "",
  programs: {
    alcoholAndDrug: false,
    training: false,
    reminders: false,
    clearinghouse: false,
  },
  companyNotes: "",
  state: "",
  street: "",
  usDotNumber: null,
  zip: "",
  uCR: "",
  rIN: "",
  usDotHazmatRegistration: "",
  hazmatRegistrationInterval: "",
  cabCard: "",
  twentyTwoNinety: "",
};

export default function AddCompanyModal({
  isAddCompanyOpen,
  handleAddButtonClose,
  handleAlertSuccess,
}) {
  const newCompanyValues = useRef(company);
  const [remindersChecked, setRemindersChecked] = useState(false);

  // State & Change Handler Function for Hazmat Reg. Renewal Interval
  const [hazmatRegistrationInterval, setHazmatRegistrationInterval] =
    useState(1);
  const handleYearChange = (event) => {
    setHazmatRegistrationInterval(event.target.value);
  };

  const [dueDates, setDueDates] = useState({
    usDotDue: null,
    ucrDue: null,
    rinDue: null,
    cabCardDue: null,
    twentyTwoNinetyDue: null,
    usDotHazmatRegistrationDue: null,
  });

  const [completedDates, setCompletedDates] = useState({
    usDotCompleted: null,
    ucrCompleted: null,
    rinCompleted: null,
    cabCardCompleted: null,
    twentyTwoNinetyCompleted: null,
    usDotHazmatRegistrationCompleted: null,
  });

  const remindersArray = [
    {
      reminderType: "USDOT",
      completedDate: completedDates.usDotCompleted,
      dueDate: dueDates.usDotDue,
    },
    {
      reminderType: "UCR",
      completedDate: completedDates.ucrCompleted,
      dueDate: dueDates.ucrDue,
    },
    {
      reminderType: "RIN",
      completedDate: completedDates.rinCompleted,
      dueDate: dueDates.rinDue,
    },
    {
      reminderType: "CAB",
      completedDate: completedDates.cabCardCompleted,
      dueDate: dueDates.cabCardDue,
    },
    {
      reminderType: "2290",
      completedDate: completedDates.twentyTwoNinetyCompleted,
      dueDate: dueDates.twentyTwoNinetyDue,
    },
    {
      reminderType: "USHAZ",
      completedDate: completedDates.usDotHazmatRegistrationCompleted,
      dueDate: dueDates.usDotHazmatRegistrationDue,
      hazmatRegistrationInterval,
    },
  ];

  const saveReminders = (companyId) => {
    for (let i = 0; i < remindersArray.length; i++) {
      let currentReminder = remindersArray[i];
      if (currentReminder.completedDate !== null) {
        currentReminder.companyName = newCompanyValues.current.companyName;
        currentReminder.companyId = companyId;
        currentReminder.isHidden = false;
        try {
          createReminders({ reminderData: currentReminder });
        } catch (error) {
          console.error("error creating reminder: ", error);
        }
      }
    }
  };

  const handleCancelOrSave = async (e) => {
    if (e.target.name === "cancel") {
      handleAddButtonClose();
      setRemindersChecked(false);
    } else {
      try {
        const companyId = await createCompany(newCompanyValues.current);
        saveReminders(companyId);
        handleAlertSuccess();
      } catch (error) {
        console.error("Error creating company:", error);
      }

      handleAddButtonClose();
      setRemindersChecked(false);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "usDotNumber") {
      newCompanyValues.current[e.target.name] = Number(e.target.value);
    } else {
      newCompanyValues.current[e.target.name] = e.target.value;
    }
  };

  const handleBoxChange = (e) => {
    newCompanyValues.current.programs[e.target.name] = e.target.checked;
    if (e.target.name === "reminders") {
      setRemindersChecked(!remindersChecked);
    }
  };

  // Function to calculate the due date by adding a number of years
  const calculateDueDate = (startDate, intervalYears) =>
    dayjs(startDate).add(intervalYears, "year");

  const handleCompletedDateChange = (date, name) => {
    const timestamp = date.$d;
    let newDueDate;
    let completedDate;

    switch (name) {
      case "rinCompleted":
        newDueDate = calculateDueDate(dayjs(timestamp), 5);
        completedDate = dayjs(timestamp);
        setDueDates((prevState) => ({
          ...prevState,
          rinDue: newDueDate,
        }));
        setCompletedDates((prevState) => ({
          ...prevState,
          rinCompleted: completedDate,
        }));
        break;
      case "ucrCompleted":
        newDueDate = calculateDueDate(dayjs(timestamp), 1);
        completedDate = dayjs(timestamp);
        setDueDates((prevState) => ({
          ...prevState,
          ucrDue: newDueDate,
        }));
        setCompletedDates((prevState) => ({
          ...prevState,
          ucrCompleted: completedDate,
        }));
        break;
      case "hazmatRegistrationCompleted":
        newDueDate = calculateDueDate(
          dayjs(timestamp),
          hazmatRegistrationInterval
        );
        completedDate = dayjs(timestamp);
        setDueDates((prevState) => ({
          ...prevState,
          usDotHazmatRegistrationDue: newDueDate,
        }));
        setCompletedDates((prevState) => ({
          ...prevState,
          usDotHazmatRegistrationCompleted: completedDate,
        }));
        break;
      case "twentyTwoNinetyCompleted":
        newDueDate = calculateDueDate(dayjs(timestamp), 1); // They will manually have to change the due date to June here
        completedDate = dayjs(timestamp);
        setDueDates((prevState) => ({
          ...prevState,
          twentyTwoNinetyDue: newDueDate,
        }));
        setCompletedDates((prevState) => ({
          ...prevState,
          twentyTwoNinetyCompleted: completedDate,
        }));
        break;
      case "usDotCompleted":
        newDueDate = calculateDueDate(dayjs(timestamp), 2);
        completedDate = dayjs(timestamp);
        setDueDates((prevState) => ({
          ...prevState,
          usDotDue: newDueDate,
        }));
        setCompletedDates((prevState) => ({
          ...prevState,
          usDotCompleted: completedDate,
        }));
        break;
      case "cabCardCompleted":
        newDueDate = calculateDueDate(dayjs(timestamp), 2);
        completedDate = dayjs(timestamp);
        setDueDates((prevState) => ({
          ...prevState,
          cabCardDue: newDueDate,
        }));
        setCompletedDates((prevState) => ({
          ...prevState,
          cabCardCompleted: completedDate,
        }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Modal open={isAddCompanyOpen} onClose={handleAddButtonClose}>
        <Box sx={style} className="boxStyle">
          <IconButton
            aria-label="close"
            onClick={handleAddButtonClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <ClearIcon />
          </IconButton>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <h3>Add A New Company</h3>
            <Divider></Divider>

            <div className="inputContainer">
              <div className="formRow">
                <div className="formUserCell">Name</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={textFieldStyle}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="companyName"
                />
              </div>

              <div className="formRow">
                <div className="formUserCell">Address</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={textFieldStyle}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="street"
                />
              </div>

              <div className="flexContainer">
                <div className="box">
                  <div className="boxOne">
                    <div className="city">City</div>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={cityTextFieldStyle}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="city"
                    />
                  </div>
                </div>

                <div className="box">
                  <div className="boxTwo">
                    <div className="state">State</div>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={textFieldStyle}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="state"
                    />
                  </div>
                </div>

                <div className="box">
                  <div className="boxThree">
                    <div className="zip">Zip</div>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={textFieldStyle}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="zip"
                    />
                  </div>
                </div>
              </div>

              <div className="formRow">
                <div className="formUserCell">Phone</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={textFieldStyle}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="phone"
                />
              </div>

              <div className="formRow">
                <div className="formUserCell">Fax</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={textFieldStyle}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="fax"
                />
              </div>

              <div className="formRow">
                <div className="formUserCell">Contact</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={textFieldStyle}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="contact"
                />
              </div>

              <div className="formRow">
                <div className="formUserCell">Email</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={textFieldStyle}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="email"
                />
              </div>

              <div className="programsContainer">
                <div className="box">
                  <div className="boxOne">
                    <div className="programs">Programs</div>
                    <div className="alcohol">Alcohol/Drug</div>
                    <Checkbox
                      name="alcoholAndDrug"
                      onClick={(e) => {
                        handleBoxChange(e);
                      }}
                    />
                  </div>
                </div>

                <div className="box">
                  <div className="boxTwo">
                    <div className="training">Training</div>
                    <Checkbox
                      name="training"
                      onClick={(e) => {
                        handleBoxChange(e);
                      }}
                    />
                  </div>
                </div>

                <div className="box">
                  <div className="boxThree">
                    <div className="reminders">Reminders</div>
                    <Checkbox
                      name="reminders"
                      onClick={(e) => {
                        handleBoxChange(e);
                      }}
                    />
                  </div>
                </div>

                <div className="box">
                  <div className="boxFour">
                    <div className="reminders">Clearinghouse</div>
                    <Checkbox
                      name="clearinghouse"
                      onClick={(e) => {
                        handleBoxChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="formRow">
                <div className="formUserCell">US DOT#</div>
                <TextField
                  type="number"
                  size="small"
                  variant="outlined"
                  // sx={textFieldStyle}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="usDotNumber"
                />
                {remindersChecked && (
                  <>
                    <div className="formUserCellAlt">Completed</div>
                    <DatePicker
                      name="usDotCompleted"
                      className="formUserCell"
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      textField={(params) => <TextField {...params} />}
                      onChange={(date) =>
                        handleCompletedDateChange(date, "usDotCompleted")
                      }
                    />

                    <div className="formUserCellAlt">Due</div>
                    <DatePicker
                      name="usDotDue"
                      className="DatePicker"
                      slotProps={{ textField: { size: "small" } }}
                      value={dueDates.usDotDue}
                      disabled
                      textField={(params) => <TextField {...params} />}
                    />
                  </>
                )}
              </div>

              {/* Conditionally Render Reminders inputs if "Reminders" program is checked */}
              {remindersChecked && (
                <>
                  <div className="formRow">
                    <div className="formUserCell">UCR</div>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="uCR"
                    />
                    <div className="formUserCellAlt">Completed</div>
                    <DatePicker
                      name="ucrCompleted"
                      className="formUserCell"
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      textField={(params) => <TextField {...params} />}
                      onChange={(date) =>
                        handleCompletedDateChange(date, "ucrCompleted")
                      }
                    />

                    <div className="formUserCellAlt">Due</div>
                    <DatePicker
                      name="ucrDue"
                      className="DatePicker"
                      slotProps={{ textField: { size: "small" } }}
                      disabled
                      value={dueDates.ucrDue}
                      textField={(params) => <TextField {...params} />}
                    />
                  </div>

                  <div className="formRow">
                    <div className="formUserCell">RIN</div>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="rIN"
                    />
                    <div className="formUserCellAlt">Completed</div>
                    <DatePicker
                      name="rinCompleted"
                      className="formUserCell"
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      textField={(params) => <TextField {...params} />}
                      onChange={(date) =>
                        handleCompletedDateChange(date, "rinCompleted")
                      }
                    />

                    <div className="formUserCellAlt">Due</div>
                    <DatePicker
                      name="rinDue"
                      className="DatePicker"
                      slotProps={{ textField: { size: "small" } }}
                      disabled
                      value={dueDates.rinDue}
                      textField={(params) => <TextField {...params} />}
                    />
                  </div>

                  <div className="formRow">
                    <div className="formUserCell">CAB Card</div>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="cabCard"
                    />

                    <div className="formUserCellAlt">Completed</div>
                    <DatePicker
                      name="cabCardCompleted"
                      className="formUserCell"
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      textField={(params) => <TextField {...params} />}
                      onChange={(date) =>
                        handleCompletedDateChange(date, "cabCardCompleted")
                      }
                    />

                    <div className="formUserCellAlt">Due</div>
                    <DatePicker
                      name="cabCardDue"
                      className="DatePicker"
                      slotProps={{ textField: { size: "small" } }}
                      disabled
                      value={dueDates.cabCardDue}
                      textField={(params) => <TextField {...params} />}
                    />
                  </div>
                  <div className="formRow">
                    <div className="formUserCell">2290</div>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="twentyTwoNinety"
                    />

                    <div className="formUserCellAlt">Completed</div>
                    <DatePicker
                      name="twentyTwoNinetyCompleted"
                      className="formUserCell"
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      textField={(params) => <TextField {...params} />}
                      onChange={(date) =>
                        handleCompletedDateChange(
                          date,
                          "twentyTwoNinetyCompleted"
                        )
                      }
                    />

                    <div className="formUserCellAlt">Due</div>
                    <DatePicker
                      name="twentyTwoNinetyDue"
                      className="DatePicker"
                      slotProps={{ textField: { size: "small" } }}
                      disabled
                      value={dueDates.twentyTwoNinetyDue}
                      textField={(params) => <TextField {...params} />}
                    />
                  </div>

                  <div className="formRow">
                    <div className="formUserCell">HZMT Reg.</div>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="usDotHazmatRegistration"
                    />

                    <div className="formUserCellAlt">Completed</div>
                    <DatePicker
                      name="hazmatRegistrationCompleted"
                      className="formUserCell"
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      textField={(params) => <TextField {...params} />}
                      onChange={(date) =>
                        handleCompletedDateChange(
                          date,
                          "hazmatRegistrationCompleted"
                        )
                      }
                    />

                    <div className="formUserCellAlt">Renewal Interval</div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={hazmatRegistrationInterval}
                      onChange={handleYearChange}
                      size="small"
                      name="hazmatRegistrationInterval"
                    >
                      <MenuItem value={1}>1 Year</MenuItem>
                      <MenuItem value={2}>2 Years</MenuItem>
                      <MenuItem value={3}>3 Years</MenuItem>
                    </Select>
                  </div>
                </>
              )}

              <div className="formRow">
                <div className="formUserCell">Notes</div>
                <TextField
                  fullWidth
                  multiline
                  size="small"
                  variant="outlined"
                  sx={textFieldStyle}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="companyNotes"
                />
              </div>
            </div>

            <div id="saveOrCancel">
              <SaveOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e);
                }}
                name="cancel"
                variant="contained"
              >
                Cancel
              </SaveOrCancel>
              <SaveOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e);
                }}
                name="save"
                variant="contained"
              >
                Save
              </SaveOrCancel>
            </div>
          </LocalizationProvider>
        </Box>
      </Modal>
    </div>
  );
}
