import api from "./BaseUrl";
import { updatedByInitials } from "./UserSession";

// RANDOM DRUG AND ALCOHOL SCREENINGS

// Helper function to fetch screenings and map them with a type
async function fetchAndMapScreenings(endpoint, screeningType) {
  try {
    const response = await api.get(`/${endpoint}`);
    return response.data.map((screening) => ({ ...screening, screeningType }));
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.warn(`No ${screeningType} screenings found for this period.`);
      return [];
    }
    console.error("Error fetching screenings:", error.message);
  }
}

export const getQuarterlyScreenings = async ({ year, quarter, consortium }) => {
  try {
    const drugScreenings = await fetchAndMapScreenings(
      `drug/quarterlySelection/${year}/${quarter}/${consortium}`,
      "Drug"
    );
    const alcoholScreenings = await fetchAndMapScreenings(
      `alcohol/quarterlySelection/${year}/${quarter}/${consortium}`,
      "Alcohol"
    );

    const allScreenings = [...drugScreenings, ...alcoholScreenings];
    const isNewQuarter = allScreenings.length === 0;

    return { allScreenings, isNewQuarter };
  } catch (error) {
    console.error("Error fetching quarterly screenings:", error.message);
    return { allScreenings: [], isNewQuarter: true };
  }
};

export const getYearlyScreenings = async ({ year, consortium, report }) => {
  const drugScreenings = await fetchAndMapScreenings(
    `drug/yearlySelection/${year}/${consortium}`,
    "Drug"
  );
  const alcoholScreenings = await fetchAndMapScreenings(
    `alcohol/yearlySelection/${year}/${consortium}`,
    "Alcohol"
  );

  if (report) {
    return { drugScreenings, alcoholScreenings };
  }

  const allScreenings = [...drugScreenings, ...alcoholScreenings];

  return { allScreenings, isNewQuarter: false };
};

export const getAllScreenings = async () => {
  const drugScreenings = await fetchAndMapScreenings(
    `drug/allSelections`,
    "Drug"
  );
  const alcoholScreenings = await fetchAndMapScreenings(
    `alcohol/allSelections`,
    "Alcohol"
  );

  const allScreenings = [...drugScreenings, ...alcoholScreenings];

  return { allScreenings, isNewQuarter: false };
};

export const saveScreenings = async ({ alcohol, drug }) => {
  try {
    const alcoholResponse = await api.post(`/alcohol/`, alcohol);
    const drugResponse = await api.post(`/drug/`, drug);

    // Helper function to ensure we always return an array
    const ensureArray = (data) => {
      if (Array.isArray(data)) return data;
      if (data && typeof data === "object") return [data];
      return [];
    };

    // Combine the responses and return them
    const alcoholScreenings = ensureArray(alcoholResponse.data);
    const drugScreenings = ensureArray(drugResponse.data);

    return [...alcoholScreenings, ...drugScreenings];
  } catch (error) {
    console.error("Error creating new screenings: ", error);
    throw error; // Re-throw the error so it can be caught in the calling function
  }
};

export const commitScreenings = async ({ year, quarter, consortium }) => {
  try {
    await api.post(`/alcohol/commit/${year}/${quarter}/${consortium}`);
    await api.post(`/drug/commit/${year}/${quarter}/${consortium}`);
  } catch (error) {
    console.error("Error committing screenings:", error.message);
  }
};

export const updateEmployeeScreening = async (screeningData) => {
  const {
    screeningType,
    screeningId,
    employeeId,
    status,
    failureReason,
    screeningReason,
  } = screeningData;

  try {
    let endpoint;
    let updateData;

    if (
      screeningReason !== "" &&
      screeningReason !== null &&
      screeningReason !== undefined
    ) {
      // This is an infrequent screening
      endpoint = `infrequentscreenings/update/${screeningId}`;
      updateData = {
        selectedEmployee: {
          status: status,
        },
      };
      if (status === "failed" && failureReason) {
        updateData.selectedEmployee.failureReason = failureReason;
      }
    } else if (screeningType === "Drug" || screeningType === "Alcohol") {
      endpoint = `${screeningType.toLowerCase()}/updateEmployeeStatus/${screeningId}/${employeeId}`;
      updateData = { status };
      if (screeningType === "Drug" && status === "failed" && failureReason) {
        updateData.failureReason = failureReason;
      }
    } else {
      console.error("Invalid screening type or missing screening reason");
    }

    const response = await api.post(endpoint, updateData);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error updating employee screening status:", error);
  }
};

export const getEmployeeScreenings = async (employeeId) => {
  try {
    const [drugScreenings, alcoholScreenings, infrequentScreenings] =
      await Promise.all([
        api.get(`drug/employee/${employeeId}`).catch((error) => {
          console.error("Error fetching drug screenings:", error);
          return { data: [] };
        }),
        api.get(`alcohol/employee/${employeeId}`).catch((error) => {
          console.error("Error fetching alcohol screenings:", error);
          return { data: [] };
        }),
        api
          .get(`infrequentscreenings/employee/${employeeId}`)
          .catch((error) => {
            console.error("Error fetching infrequent screenings:", error);
            return { data: [] };
          }),
      ]);

    const allScreenings = [
      ...drugScreenings.data.map((screening) => ({
        ...screening,
        screeningType: "Drug",
      })),
      ...alcoholScreenings.data.map((screening) => ({
        ...screening,
        screeningType: "Alcohol",
      })),
      ...infrequentScreenings.data,
    ];

    return allScreenings;
  } catch (error) {
    console.error("Error fetching employee screenings:", error);
  }
};

export const deleteScreenings = async (data) => {
  let selectionId = data._id;
  try {
    if (data.screeningType === "Alcohol") {
      await api.post(`/alcohol/delete/${selectionId}`, selectionId);
    } else if (data.screeningType === "Drug") {
      await api.post(`/drug/delete/${selectionId}`, selectionId);
    }
    window.location.reload();
  } catch (error) {
    console.error("Error deleting current screenings: ", error);
  }
};

// SCREENING PERCENTAGES
export const getScreeningPercentages = async () => {
  try {
    const screeningPercentages = await api.get(`/screening/`);
    return screeningPercentages.data;
  } catch (error) {
    console.error("Error fetching screening percentages: ", error);
  }
};

export const updateScreeningPercentages = async ({
  screeningPercentages,
  onSuccess,
}) => {
  let decimalAlcoholPercentage = screeningPercentages.alcoholPercentage / 100;
  let decimaldrugPercentage = screeningPercentages.drugPercentage / 100;
  try {
    await api
      .post(`/screening/update/${screeningPercentages.id}`, {
        alcoholPercentage: decimalAlcoholPercentage,
        drugPercentage: decimaldrugPercentage,
        updatedBy: updatedByInitials,
      })
      .then(function (response) {
        if (response.status === 200) {
          onSuccess(); // Call the onSuccess callback immediately
        }
      });
  } catch (error) {
    console.error("Error updating trainings:", error);
  }
};

// INFREQUENT SCREENINGS
export const createInfrequentScreening = async (body) => {
  try {
    const newInfrequentScreening = await api.post(
      `/infrequentscreenings/create`,
      body
    );
    if (newInfrequentScreening.status === 200) {
    }
  } catch (error) {
    console.error("Error creating infrequent screening: ", error);
  }
};

export const getInfrequentScreenings = async () => {
  try {
    const allInfrequentScreenings = await api.get(`/infrequentscreenings/`);
    return allInfrequentScreenings.data;
  } catch (error) {
    console.error("Error fetching infrequent screenings: ", error);
  }
};

export const updateInfrequentScreening = async (data) => {
  let screeningId = data._id;
  try {
    const updatedInfrequentScreening = await api.post(
      `/infrequentscreenings/update/${screeningId}`,
      {
        screeningType: data.screeningType,
        screeningReason: data.screeningReason,
        screeningDate: data.screeningDate,
        selectedEmployee: data.selectedEmployee,
      }
    );
    if (updatedInfrequentScreening.status === 200) {
    }
  } catch (error) {
    console.error("Error updating infrequent screening: ", error);
  }
};

export const deleteInfrequentScreening = async (data) => {
  let screeningId = data._id;
  try {
    const deletedInfrequentScreening = await api.post(
      `/infrequentscreenings/delete/${screeningId}`
    );
    if (deletedInfrequentScreening.status === 200) {
      return null;
    }
  } catch (error) {
    console.error("Error deleting infrequent screening: ", error);
  }
};

export const fetchScreeningsFromTimeFrame = async ({ startDate, endDate }) => {
  try {
    const screeningsFromTimeFrame = await api.get(
      `/infrequentscreenings/find/${startDate}/${endDate}`
    );
    return screeningsFromTimeFrame.data;
  } catch (error) {
    console.error("Error fetching infrequent screenings: ", error);
  }
};
